.admin-recentorders {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    position: relative;
}

.admin-recentorder {
    width: 100%;
    background-color: #0d0d0d;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    transition: all 100ms;
    /* position: relative; */
}

.admin-recentorder-image img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
}

.admin-recentorder-image {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px;
    background-color: #000;
    border-radius: 6px;
}

.recentorder-sku {
    position: absolute;
    background-color: #000;
    font-weight: 500;
    font-size: 14px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    padding: 1px 6px;
    border-radius: 4px;
    min-width: 58px;
    text-align: center;
}

.admin-recentorder-orders {
    height: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    box-sizing: border-box;
    margin-left: 16px;
    font-size: 13px;
    border-radius: 10px;
}

.admin-recentorder-value {
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin-top: 2px;
}

.admin-recentorder-orders.out {
    background-color: #861f1f;
}

.admin-recentorder-orders.notout {
    background-color: #095314;
}

img.recent-expanded {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 110%;
    top: 0px;
    outline: 4px solid #000;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
}

img.recent-expanded.recent-material {
    width: 250px;
    height: auto;
    top: -80px;
}

.admin-recentorder-image:hover img.recent-expanded {
    opacity: 1;
}

.admin-recentorder-value.top-sellers {
    font-size: 12.5px;
    font-weight: 400;
}

.top-seller {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    width: 100%;
}

.top-seller-email {
    display: block;
    max-width: 150px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 14px;
    color: #ffffffc6;
    margin-right: auto;
    transition: all 100ms;
}

.top-seller-total {
    display: inline-block;
    font-weight: 500;
    margin-right: 4px;
    min-width: 35px;
    outline: 1px solid rgba(255, 255, 255, 0.253);
    text-align: right;
    border-radius: 5px;
    padding: 0px 4px 0px 0px;
}

.no-sales {
    width: 200px;
    text-align: center;
    height: 35px;
    font-size: 15px;
    color: #ffffffa7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.testetesteteste {
    font-size: 12px;
}

.admin-recentorder:hover {
    background-color: #363636;
}

@keyframes ruim {
    50% {
        background-color: #30046b;
    }
}

.admin-recentorder.blink {
    animation: ruim 1s ease-in-out infinite;
}

.top-sellet-place {
    height: 20px;
    margin-bottom: -1px;
}

.top-seller-percent {
    width: 30px;
    font-size: 11px;
    text-align: right;
    margin-right: 4px;
    opacity: 0.8;
    font-weight: 500;
}

.admin-recentorders .hide-info-btn {
    /* margin-left: auto;
    margin-top: -10px; */
    /* margin-bottom: 10px; */
    position: fixed;
    z-index: 999999;
    right: 2px;
    top: 2px;
    background-color: #1a1a1a;
    padding: 10px 8px 6px 8px;
    border-radius: 50%;
}

.admin-recentorders.hide-info .top-seller-email {
    filter: blur(4px);
    opacity: 0.7;
}

.recent-order-loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: calc(100vw - 180px);
    /* height: calc(100vh - 100px); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    background-color: #1a1a1a;
}

.recent-order-loading.shown {
    opacity: 1;
}

.recent-order-loading .loader {
    position: relative;
}

.recentorder-number {
    /* position: absolute; */
    /* right: calc(100% + 10px); */
    /* top: 50%; */
    /* transform: translateY(-50%); */
    margin-left: -40px;
    margin-right: 20px;
    width: 20px;
    margin-top: 35px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}

.recentorder-pending {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    margin-left: 3px;
}

.admin-recentorder-orders input {
    width: 60px;
    margin-top: 4px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    padding: 2px 0px;
}

.admin-recentorder-orders input::-webkit-outer-spin-button,
.admin-recentorder-orders input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.admin-material-report {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    padding: 20px;
    display: none;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.report-logo {
    filter: invert(1);
    position: absolute;
    top: 20px;
    right: 30px;
    height: 50px;
}

.admin-material-title {
    font-size: 22px;
    text-align: center;
}

.admin-material-title:nth-of-type(2) {
    margin-bottom: 30px;
}

.material-report-photo {
    width: 220px;
    height: 340px;
    object-fit: cover;
    border-radius: 10px;
}

.material-report-item {
    position: relative;
    padding: 8px;
}

.admin-material-report-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.material-report-sku,
.material-report-text,
.material-report-qtty {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 2px 6px;
    min-width: fit-content;
    white-space: nowrap;
    border-radius: 4px;
    outline: 1px solid #ffffff50;
    font-weight: 500;
}

.material-report-text {
    bottom: auto;
    top: 10px;
    background-color: rgb(179, 3, 3);
    /* color: #000; */
    font-weight: 700;
}

.material-report-qtty {
    bottom: 10px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
}

.material-report-qtty span {
    font-size: 16px;
    font-weight: 600;
}

.admin-recentorder-image img.report-img {
    height: 80px;
}

.report-print-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: #1567d2;
    padding: 4px 10px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.report-print-btn:hover {
    background-color: #0e4388;
}

@media print {
    .admin-material-report {
        display: block;
    }
}

@page {
    size: auto; /* auto is the initial value */

    /* this affects the margin in the printer settings */
    margin: 5mm;
}
