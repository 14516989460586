.order-list {
    /* border: 1px solid #c93838; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    margin-bottom: 100px;
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    box-sizing: border-box;
}

.order-card {
    /* border: 1px solid #c93838; */
    width: 100%;
    max-width: 900px;
    margin-bottom: 16px;
    background-color: #262626;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    transition: all 150ms;
    animation: order-card 300ms ease-in-out 0ms 1 normal forwards;
    transform: translateY(100px);
    opacity: 0;
}

@keyframes order-card {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    50% {
        transform: translateY(-10px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.order-card:hover {
    background-color: #2e2e2e;
}

.order-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-number {
    padding: 3px 6px;
    transition: all 200ms;
    text-align: center;
}

.order-number:hover {
    background-color: #2a2a2a;
    border-radius: 6px;
}

.order-number::after {
    font-size: 12px;
}

.order-photo {
    position: relative;
    perspective: 200px;
}

.order-photo img,
.multiple-order-photos {
    width: 60px;
    height: 60px;
    border-radius: 6px;
}

.multiple-order-photos {
    perspective: 200px;
    transition: all 200ms;
}

.multiple-order-photos img {
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 200ms;
    box-shadow: 1px 1px 5px 3px #0000001c;
    outline: 1px solid #ffffffbd;
    backface-visibility: hidden;
}

.multiple-order-photos img:nth-of-type(1) {
    transform: rotateY(-30deg) translateX(-8px);
    z-index: 4;
}
.multiple-order-photos img:nth-of-type(2) {
    transform: rotateY(-20deg) translateX(-8px) translateZ(-16px);
    z-index: 3;
}
.multiple-order-photos img:nth-of-type(3) {
    transform: rotateY(-10deg) translateX(-2px) translateZ(-48px);
    z-index: 2;
}

.order-card:hover .multiple-order-photos img:nth-of-type(1) {
    transform: rotateY(-40deg) translateX(-16px);
}

.order-card:hover .multiple-order-photos img:nth-of-type(2) {
    transform: rotateY(-30deg) translateX(-12px) translateZ(-16px);
}

.order-card:hover .multiple-order-photos img:nth-of-type(3) {
    transform: rotateY(-20deg) translateX(-8px) translateZ(-48px);
}

.order-card:hover .multiple-order-photos {
    transform: rotateY(-20deg);
}

.order-photo-sku {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    font-size: 11px;
    padding: 2px 4px;
    background-color: #212121;
    border-radius: 4px;
    border: 1px solid #ffffff31;
}

.order-date {
    /* width: 100px; */
    text-align: center;
    white-space: pre-wrap;
    /* margin-right: 10px; */
    font-size: 13.5px;
}

.order-status {
    background-color: #212121;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 130px;
    max-width: 130px;
    min-height: 50px;
    font-weight: 500;
}

.order-status:hover .order-status-bubble {
    opacity: 1;
    transform: translateY(-50%) translateX(0px);
}

.order-button-bubble {
    background-color: #212121;
    color: #fff;
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 8px;
    bottom: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    border-radius: 6px;
    box-shadow: 1px 1px 5px 3px #0000001c;
    text-align: justify;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms 100ms;
    border: 1px solid #ffffff31;
    z-index: 99;
}

.order-button-bubble:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    height: 8px;
    width: 8px;
    bottom: -8px;
    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

.order-button:hover .order-button-bubble {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
}

.order-status-bubble {
    background-color: #212121;
    color: #fff;
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    padding: 6px 10px;
    top: 50%;
    transform: translateY(-50%) translateX(20px);
    right: calc(100% + 15px);
    width: 170px;
    border-radius: 6px;
    box-shadow: 2px 2px 1px 1px #00000022;
    text-align: justify;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms 100ms;
    border: 1px solid #ffffff24;
}

.order-status-bubble::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    height: 8px;
    width: 8px;
    right: -8px;
    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

.order-price {
    background-color: #212121;
    padding: 8px 12px;
    border-radius: 6px;
    justify-content: space-between;
    min-width: 140px;
}

.order-price-internal {
    font-size: 12px;
    color: #9a9a9a;
}

.order-price-internal:first-of-type {
    margin-right: 20px;
}

.order-price-value {
    font-size: 16px;
    color: #fff;
}

.order-price-value small {
    font-size: 12px;
}

.order-button {
    margin: 5px;
    width: 30px;
    height: 30px;
    transition: all 200ms;
    cursor: pointer;
    position: relative;
}

.order-button.notfound {
    outline: 1px solid #c9383872;
    /* background-color: rgb(134, 0, 0); */
    border-radius: 8px;
    animation: notfound 2s linear 0ms infinite;
}

@keyframes notfound {
    50% {
        background-color: #c93838;
    }
    0% {
        background-color: transparent;
    }
}

.order-buttons img {
    height: 100%;
    width: 100%;
}

.order-buttons .order-button:hover img {
    opacity: 0.5;
    scale: 1.1;
    transition: all 200ms;
}

.order-message {
    /* margin-right: 10px; */
    display: flex;
    margin-right: 8px;
}

.with-message {
    display: none;
    position: relative;
}

.order-message.new-messages .with-message {
    display: block;
}

.order-message.new-messages .no-message {
    display: none;
}

.order-message-qtty {
    background-color: #c93838;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    left: 16px;
    min-width: 14px;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #262626;
    opacity: 0;
    animation: order-message-qtty 500ms ease-in-out 500ms 1 normal forwards;
}

@keyframes order-message-qtty {
    0% {
        scale: 1;
        opacity: 0;
    }
    50% {
        scale: 1.3;
        opacity: 1;
        transform: rotateZ(30deg);
    }
    100% {
        scale: 1;
        opacity: 1;
        transform: rotateZ(0deg);
    }
}

.order-marketplace img {
    height: 38px;
    width: 38px;
}

.order-search-bar {
    /* width: 100%; */
    height: 139px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 200px);
    box-sizing: border-box;
    z-index: 99999;
    background-color: #1a1a1a;
    padding: 50px 0px 20px 0px;
    margin-left: 200px;
    mask: linear-gradient(transparent, black, black);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.order-search,
.order-search-placeholder {
    box-sizing: border-box;
    /* padding: 10px 20px; */
    border-radius: 8px;
    border: 0px;
    background-color: #292929;
    color: #fff;
    font-size: 18px;
    pointer-events: all;
    transition: all 200ms;
    width: calc(100% - 60px);
    max-width: 900px;
    margin: 40px 30px 5px;
    border: 1px solid #ffffff00;
    position: absolute;
}

.order-search input {
    /* position: absolute; */
    /* width: calc(100% - 60px); */
    /* max-width: 900px; */
    /* margin: 40px 30px 5px; */
    box-sizing: border-box;
    border: none;
    color: #fff;
    background-color: transparent;
    width: 100%;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #ffffff00;
    transition: all 200ms;
}

.order-search-placeholder {
    padding: 10px 20px;
}

.order-search input::placeholder {
    color: #ffffff35;
}

.order-search input:focus {
    border: 1px solid #ffffff40;
    outline: none;
}

.order-search-placeholder {
    background-color: none;
    animation: order-search-placeholder 200ms linear 3500ms 1 normal forwards;
    border: 1px solid #ffffff40;
    pointer-events: none;
}

.order-search-placeholder.hidden {
    transition: all 300ms;
    opacity: 0;
}

@keyframes order-search-placeholder {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.order-search::placeholder {
    opacity: 0.5;
}

.order-search:focus {
    outline: none;
    /* border: 1px solid black; */
    border: 1px solid #ffffff40;
}

.order-modal-bg {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999999999;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms;
}

.order-modal {
    background-color: #0c0c0c;
    width: 95%;
    max-width: 600px;
    max-height: 95dvh;
    height: fit-content;
    /* max-height: 600px; */
    border-radius: 20px;
    scale: 0.1;
    transition: all 600ms, transform 600ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    transform: translateY(-400px);
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.order-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.order-modal-bg.shown .order-modal {
    scale: 1;
    transform: translateY(0px);
}

.order-modal-header {
    /* outline: 1px solid #c93838; */
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

.order-modal-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 8px;
}

.order-modal-number .roulette-container .roulette {
    transition-delay: 1000ms;
}

.order-modal-number small {
    font-size: 13px;
}

.order-modal-date {
    text-align: left;
    white-space: pre-wrap;
    font-size: 14px;
    margin-right: auto;
}

.order-modal-cancel {
    background-color: #c93838;
    font-size: 15px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 200ms;
    width: min-content;
}

.order-modal-cancel:hover {
    opacity: 0.9;
    scale: 1.04;
}

.order-modal-header-section .order-status {
    text-align: center;
    padding: 8px;
    margin: 0px;
}

.order-modal-status {
    display: flex;
    align-items: center;
}

.order-modal-header-section.order-buttons,
.order-modal-header-section.order-marketplace {
    margin-right: 10px;
    display: flex;
}

.order-modal-body {
    /* outline: 1px solid #c93838; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-modal-products {
    display: flex;
    overflow-x: auto;
    height: fit-content;
    position: relative;
    padding: 0px 10px;
}

.order-modal-products-shadow {
    width: calc(94% + 16px);
    margin-left: -10px;
    height: fit-content;
    position: relative;
    /* outline: 1px solid #c93838; */
    padding: 12px 0px;
}

.order-modal-products-shadow::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 10px 10px #0c0c0c;
    z-index: 99;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.order-modal-products::-webkit-scrollbar-button {
    opacity: 0;
}

.order-modal-products::-webkit-scrollbar {
    height: 12px;
}

.order-modal-products::-webkit-scrollbar-track {
    border-radius: 6px;
}

.order-modal-product img {
    height: 100px;
    border-radius: 6px;
}

.order-modal-product {
    padding: 5px 10px 10px;
    background-color: #030303;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    border-radius: 8px;
    height: fit-content;
    margin: 5px 5px 10px 5px;
    /* outline: 1px solid #ffffff15; */
    opacity: 0;
    transform: translateX(50px);
    animation: order-modal-product 300ms ease-in-out 100ms 1 normal forwards;
}

.order-modal-product.missingnames {
    background-color: #000;
    cursor: pointer;
    opacity: 1;
    animation: missingname 1500ms ease-in-out 100ms infinite normal;
    transform: translateX(0px);
}

@keyframes order-modal-product {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    50% {
        opacity: 0.5;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.order-modal-product-price {
    margin-top: 2px;
    width: 100%;
    text-align: right;
    font-weight: 500;
    margin-bottom: -2px;
    opacity: 0.7;
    font-size: 18px;
}

.order-modal-product-price small {
    font-size: 11px;
}

.order-modal-product-image {
    position: relative;
}

.order-modal-product-image img {
    width: 80px;
    height: 80px;
}

.order-modal-product-sku {
    font-size: 12px;
    align-self: flex-start;
    margin-bottom: 5px;
    font-weight: 500;
    opacity: 0.7;
}

.order-modal-product-options img {
    height: 18px;
    margin-right: 2px;
    border-radius: 0px;
    opacity: 0.7;
}

.order-modal-product-options {
    overflow: hidden;
    max-height: 0px;
    transition: all 300ms;
    margin-top: 0px;
    width: 100%;
}

.order-modal-product-options .order-product-option {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 5px;
}

.order-product-option span {
    min-width: 30px;
    text-align: right;
}

/* .order-modal-product-options .order-product-option:last-of-type { */
/* margin: 0px; */
/* } */

.order-modal-product-options small {
    font-size: 10px;
}

.order-modal-product:hover .order-modal-product-options {
    max-height: 100px;
}

.order-timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 100%;
    margin: 40px 0px 15px;
}

.order-timeline-circle {
    background-color: #494949;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: relative;
    z-index: 9;
}

.order-timeline-circle.advanced {
    background-color: #1567d2;
}

.order-timeline-circle.canceled::after {
    background-color: #c93838 !important;
}

.order-timeline-circle.advance::after {
    content: "";
    background-color: #1567d2;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 50%;
    animation: order-timeline-circle 500ms ease-in-out 1000ms 1 normal forwards;
    scale: 0;
}

.order-timeline-circle.advance2::after {
    content: "";
    background-color: #0a770a;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 50%;
    animation: order-timeline-circle 500ms ease-in-out 1000ms 1 normal forwards;
    scale: 0;
}

.order-timeline-circle.advance.pending::after {
    background-color: #c93838;
}

@keyframes order-timeline-circle {
    0% {
        scale: 0;
    }
    50% {
        scale: 1.4;
    }
    100% {
        scale: 1.2;
    }
}

.order-timeline-line {
    background-color: #494949;
    width: 160px;
    height: 3px;
    margin: -2px;
    position: relative;
}

.order-timeline-line.advance.pending::after {
    background: linear-gradient(90deg, #c93838 0%, #494949 100%);
    animation: order-timeline-line 500ms ease-in-out 1200ms 1 normal forwards;
}

.order-timeline-line.advanced {
    background-color: #1567d2;
}

.order-timeline-line.advance::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 100%;
    background-color: #1567d2;
    opacity: 1;
    animation: order-timeline-line 500ms ease-in-out 600ms 1 normal forwards;
}

.order-timeline-line.advance2::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 100%;
    background: linear-gradient(90deg, #1567d2 0%, #0a770a 100%);
    opacity: 1;
    animation: order-timeline-line 500ms ease-in-out 600ms 1 normal forwards;
}

.order-timeline-line.canceled.advance::after {
    background: #1567d2;
    background: linear-gradient(90deg, #1567d2 0%, #c93838 100%);
}

@keyframes order-timeline-line {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.order-timeline-subtitle {
    position: absolute;
    font-size: 12px;
    text-align: center;
    bottom: calc(100% + 13px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    background-color: inherit;
    padding: 3px 6px;
    border-radius: 6px;
    transform-origin: left bottom;
    color: #adadad;
}

.order-timeline-subtitle::after {
    content: "";
    position: absolute;
    bottom: -4px;
    transform: translateX(-50%) rotateZ(45deg);
    height: 8px;
    width: 8px;
    left: 50%;
    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

.order-timeline-circle.advanced .order-timeline-subtitle {
    /* background-color: #1567d2; */
    color: #fff;
}

.order-timeline-circle.advance .order-timeline-subtitle {
    color: #fff;
    animation: order-timeline-subtitle 400ms ease-in-out 1300ms 1 normal forwards;
}

.order-timeline-circle.advance2 .order-timeline-subtitle {
    color: #fff;
    animation: order-timeline-subtitle2 400ms ease-in-out 1300ms 1 normal forwards;
}

.order-timeline-circle.advance .order-timeline-subtitle.canceled,
.order-timeline-circle.pending .order-timeline-subtitle {
    color: #fff;
    animation: order-timeline-subtitle 400ms ease-in-out 1300ms 1 normal forwards,
        order-timeline-subtitle-canceled 400ms ease-in-out 1300ms 1 normal forwards;
}

@keyframes order-timeline-subtitle {
    0% {
        scale: 1;
        background-color: #494949;
    }
    40% {
        scale: 1.5;
    }
    60% {
        scale: 1.3;
    }
    80% {
        scale: 1.45;
    }
    100% {
        scale: 1.35;
        background-color: #1567d2;
        font-weight: 400;
    }
}

@keyframes order-timeline-subtitle2 {
    0% {
        scale: 1;
        background-color: #494949;
    }
    40% {
        scale: 1.5;
    }
    60% {
        scale: 1.3;
    }
    80% {
        scale: 1.45;
    }
    100% {
        scale: 1.35;
        background-color: #0a770a;
        font-weight: 400;
    }
}

@keyframes order-timeline-subtitle-canceled {
    0% {
        background-color: #494949;
    }
    100% {
        background-color: #c93838;
    }
}

.order-status-explanation {
    width: 370px;
    /* background-color: #1A1A1A; */
    font-size: 13px;
    padding: 10px 16px;
    box-sizing: border-box;
    align-self: center;
    text-align: justify;
    border-radius: 8px;
    /* outline: 1px solid #ffffff10; */
    transition: all 200ms;
    color: #ffffff;
    position: relative;
    /* margin-bottom: 0px; */
    /* height: 75px; */
    position: relative;
    overflow: hidden;
    opacity: 0.8;
}

.order-product-qtty {
    position: absolute;
    background-color: #c93838;
    outline: 4px solid #030303;
    top: -4px;
    right: -4px;
    min-width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    border-radius: 9px;
    opacity: 0;
    scale: 0.3;
    opacity: 0;
    font-weight: 500;
    /* outline: 1px solid #ffffff73 */
}

.order-product-qtty.shown {
    animation: order-product-qtty 400ms ease-in-out 600ms 1 normal forwards;
}

@keyframes order-product-qtty {
    0% {
        scale: 0.1;
        opacity: 0;
    }
    40% {
        scale: 1.4;
    }
    60% {
        scale: 1.1;
        opacity: 1;
    }
    80% {
        scale: 1.2;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}

.order-modal-total {
    /* background-color: #030303; */
    /* outline: 1px solid #c93838; */
    width: calc(100% - 5px);
    /* height: 50px; */
    /* margin-bottom: 5px; */
    z-index: 999;
    /* margin-top: 12px; */
    margin: 5px 0px 0px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 8px */
    opacity: 0.8;
}

.order-modal-total-section {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0px 4px 0px 24px;
    text-align: left;
}

.order-modal-total-value {
    font-size: 18px;
    font-weight: 600;
}

.order-status-block {
    display: flex;
    flex-direction: column;
    /* outline: 1px solid rgba(255, 255, 255, 0.129); */
    /* background-color: #0e0e0e; */
    z-index: 999;
    margin-top: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}

.order-modal-options img {
    height: 24px;
    width: 24px;
    margin: 0px 5px;
}

.order-modal-total-section.order-modal-options {
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-right: auto;
}

.order-modal-option {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* outline: 1px solid #c93838; */
    padding: 8px 14px;
    border-radius: 6px;
    background-color: #151515;
}

.order-modal-option small {
    font-size: 12px;
}

.plp-img {
    background-color: #fff;
    height: 500px;
    width: 350px;
}

.order-modal.order-modal-plp {
    height: 95dvh;
    max-height: 95dvh;
    min-width: calc(95dvh * 0.66666);
    width: fit-content;
    padding: 10px;
}

.order-modal.order-modal-nfe {
    width: 95%;
    max-width: 520px;
    max-height: 95dvh;
}

.order-modal.order-cancel {
    width: fit-content;
    text-align: center;
}

.cancel-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cancel-btns div {
    background-color: #c93838;
    padding: 4px 10px;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
}

.cancel-btns .cancel-btn-yes {
    background-color: rgb(6, 137, 6);
}

.order-modal-plp img {
    width: auto;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    /* filter: invert(); */
}

.order-modal-plp iframe {
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.order-modal-details {
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    box-sizing: border-box;
    /* outline: 1px solid #c93838; */
    background-color: #1c1c1c;
    border-radius: 6px;
    padding: 10px 20px;
}

.order-modal-details a {
    color: #fff;
}

img.order-bling-icon,
.order-bling-icon {
    height: 10px;
    width: auto;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #313131;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
    font-weight: 500;
}

.order-marketplace {
    position: relative;
}

.order-modal-buyername {
    /* outline: 1px solid #f00; */
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cancel-reason-container {
    background-color: #000;
    text-align: center;
    padding: 7px;
    border-radius: 6px;
    margin-top: 8px;
    font-weight: 500;
    color: #c93838;
    outline: 1px solid #c93838aa;
}

.cancel-reason {
    font-weight: 400;
    margin-top: 2px;
}

.order-search-qtty {
    position: absolute;
    z-index: 99;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: #ffffffa8;
}

.order-status.order-status-mobile {
    display: none;
    min-width: 30px;
    min-height: 30px;
    border-radius: 6px;
    font-size: 16px;
    /* margin-left: auto; */
}

.order-button.notfound .order-button-bubble {
    /* width: 65px; */
}

.order-button input {
    display: none;
}

.modal-burn-bg {
    z-index: 9999999999 !important;
}

.burn-save {
    background-color: #1567d2;
    margin-top: 16px;
    padding: 0px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.burn-save:hover {
    opacity: 0.8;
}

@keyframes missingname {
    50% {
        background-color: #c93838;
    }
    0% {
        background-color: transparent;
    }
}

img.order-product-edit {
    height: 18px;
    width: 22px;
    position: absolute;
    left: 10px;
    top: 114px;
    /* margin: 6px 2px; */
    cursor: pointer;
    object-fit: contain;
    border-radius: 0px;
}

.order-modal-product-names {
    position: absolute;
    left: calc(100% - 10px);
    bottom: 4px;

    opacity: 0;
    transition: all 300ms;
}

.order-modal-product-name {
    font-size: 12px;
    background-color: #000;
    padding: 1px 6px;
    border-radius: 4px;
    outline: 1px solid #ffffff40;
    white-space: nowrap;
    margin-bottom: 4px;
}

.order-modal-product:hover .order-modal-product-names {
    opacity: 1;
    left: calc(100% + 10px);
}

.order-modal-product.product-order-custom {
    transition: all 300ms;
}

.order-modal-product.product-order-custom:hover {
    z-index: 9999999;
    box-shadow: 0px 0px 20px 2000px #0c0c0cdc !important;
}

.order-modal-products {
    border-radius: 10px;
}

.order-list .infinite-scroll-component__outerdiv {
    width: 100%;
    /* outline: 2px solid #f00; */
}

.order-list-infinite {
    overflow: visible!important;
    margin: 0px auto;
    /* outline: 2px solid #0f0; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-list .loader {
    position: relative;
    scale: 0.7;
    margin-top: 20px;
    z-index: 0;
}

@media only screen and (max-width: 768px) {
    .order-card {
        max-width: calc(100vw - 60px);
        /* overflow: hidden; */
        padding: 6px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
    }

    .order-button-bubble {
        font-size: 10px;
    }

    .order-search-bar {
        /* width: 100%; */
        height: 139px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: calc(100%);
        box-sizing: border-box;
        z-index: 99999;
        background-color: #1a1a1a;
        padding: 50px 0px 20px 0px;
        margin-left: 0px;
        /* mask: linear-gradient(transparent, black, black); */
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: center; */
        /* pointer-events: none; */
    }

    .order-price {
        display: none;
    }

    .order-photo img,
    .multiple-order-photos {
        height: 40px;
        width: 40px;
    }

    .order-photo-sku {
        font-size: 8px;
    }

    .order-number {
        font-size: 10px;
        min-width: 55px;
    }

    .order-modal.order-modal-nfe {
        max-width: 90vw !important;
    }

    .order-modal.order-modal-plp {
        /* min-height: calc(90vw * 0.66666); */
        max-height: 70dvh;
        max-width: 90dvw;
        min-width: 90dvw;
        overflow: hidden;
        width: fit-content;
        padding: 10px;
    }

    .order-status.order-status-mobile {
        display: flex;
    }

    .order-status-pc {
        display: none;
    }

    .order-marketplace img {
        height: 28px;
        width: 28px;
    }

    img.order-bling-icon {
        height: 8px;
        width: auto;
    }

    .order-bling-icon {
        font-size: 7px;
        padding: 0px 1px;
        bottom: -4px;
    }

    .order-date {
        font-size: 8px;
        margin-right: 10px;
    }

    .order-modal-header-section.order-buttons .order-button {
        width: 28px;
        height: 28px;
    }

    .order-modal-header-section.order-marketplace img {
        height: 36px;
        width: 36px;
    }

    .order-button {
        margin: 2px;
        width: 20px;
        height: 20px;
        transition: all 200ms;
        cursor: pointer;
        position: relative;
    }

    .order-buttons {
        margin: 0px 6px;
    }

    .order-timeline-line {
        width: 100px;
    }

    .order-modal-cancel {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    .order-modal-date {
        font-size: 12px;
    }

    .order-status-explanation {
        padding: 0px 10px;
        box-sizing: border-box;
        width: 100%;
        /* padding: 0px 20px; */
    }

    .order-modal-details {
        font-size: 10px;
        padding: 6px 10px;
    }

    .order-search input {
        padding: 8px 12px;
        font-size: 16px;
        border-radius: 6px;
    }

    .order-search-placeholder {
        display: none;
    }
}
