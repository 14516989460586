.aistem-node {
    background-color: #f00;
}

.aistem-flow .react-flow__node {
    background-color: #1567d2;
    padding: 5px 20px;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #000;
}

.aistem-flow .react-flow__node.selected {
    border: 1px solid #fff;
}

.aistem-flow .react-flow__handle {
    background-color: #fff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.aistem-flow .react-flow__handle::after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    /* outline: 1px solid #f00; */
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
}

.aistem-flow .react-flow__panel.react-flow__attribution {
    display: none;
}

.aistem-flow-menu {
    padding: 10px 0px;
    display: flex;
}

.aistem-flow-btn {
    background-color: #1567d2;
    padding: 3px 8px;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 20px;
}

.flow-show-text {
    margin-left: auto;
    background-color: #000;
}

.aistem-flow-btn:hover {
    opacity: 0.7;
}

.aistem-flow-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.aistem-flow-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.aistem-flow-modal {
    background-color: #000;
    width: 95vw;
    max-width: 700px;
    height: fit-content;
    max-height: 95%;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    outline: 1px solid rgba(255, 255, 255, 0.183);
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.aistem-flow-separator {
    margin-bottom: 10px;
}

.aistem-flow-separator input,
.aistem-flow-separator textarea {
    width: 100%;
    background-color: #272727;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    padding: 6px 10px;
    border-radius: 6px;
    color: #fff;
    box-sizing: border-box;
    outline: 1px solid #1567d200;
    transition: all 200ms;
}

.aistem-flow-separator input:focus,
.aistem-flow-separator textarea:focus {
    outline: 1px solid #1567d2;
}

.aistem-flow-separator textarea {
    height: 150px;
    min-width: 100%;
    max-width: 100%;
}

.aistem-flow-modal-bg.aistem-flow-loading {
    width: calc(100% - 200px);
    right: 0px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.aistem-flow-modal-bg.aistem-flow-loading .loader {
    position: relative;
}

.test {
    background-color: #000;
    position: fixed;
    right: 20px;
    top: 60px;
    width: 400px;
    height: calc(100% - 80px);
    white-space: pre-wrap;
    overflow-y: scroll;
    padding: 10px;
    box-sizing: border-box;
    user-select: text;
    /* pointer-events: all; */
    transition: all 200ms;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
}

.test.shown {
    max-height: 100%;
    opacity: 1;
    pointer-events: all;
}

.aistem-flow-remove {
    position: absolute;
    right: 20px;
    top: 16px;
    color: #f00;
    cursor: pointer;
}

.aistem-flow-remove:hover {
    font-weight: 600;
}

.aistem-flow-separator .admin-product-photo {
    display: block;
    margin-top: 6px;
}

.aistem-flow-image {
    display: block;
    width: fit-content;
    padding: 10px;
    background-color: #1567d2;
    max-height: 150px;
    margin-top: 6px;
    cursor: pointer;
    transition: all 200ms;
    border-radius: 10px;
}

.aistem-flow-image:hover {
    opacity: 0.6;
}

.aistem-flow-image img {
    max-height: 150px;
    border-radius: 10px;
}

.aistem-flow-image input {
    display: none;
}

.dialog-user.admin-dialog-row {
    margin-top: 10px;
}

.admin-dialog-row {
    position: relative;
}

.aistem-username {
    background-color: #000;
    width: fit-content;
    margin-top: 14px;
    font-size: 13px;
    padding: 8px;
    margin-left: 10px;
    outline: 1px solid #ffffff41;
    border-radius: 10px;
    margin-right: 1px;
    cursor: pointer;
    position: relative;
}

.aistem-username::after {
    content: "Responder";
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: all 200ms;
}

.aistem-username:hover::after {
    opacity: 1;
}

.aistem-to-username {
    position: absolute;
    top: 1px;
    left: 16px;
    z-index: 1;
    font-size: 12px;
    background-color: #000;
    padding: 2px 4px;
    border-radius: 4px;
    outline: 1px solid #ffffff1e;
    font-weight: 500;
}

.einstein-dialog-admin {
    margin-top: 0px;
    top: 0px;
    max-height: calc(100dvh - 100px);
    width: calc(100vw - 200px);
}

.einstein-dialog-admin .einstein-dialog-row {
    opacity: 1;
    transform: none !important;
    margin-bottom: 10px;
}
