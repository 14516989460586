.navbar-admin {
    width: 200px;
    font-size: 24px;
    font-weight: 600;
    z-index: 99999;
}

.navbar-admin .drop-logo {
    width: 120px;
}

.navbar-account-admin {
    color: #c93838;
}

.navbar-config-admin {
    max-height: 300px;
    opacity: 1;
    pointer-events: all;
    margin-top: 8px;
}

.navbar-admin-newuser {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.newuser-content {
    background-color: #000;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    outline: 1px solid #fff;
}
