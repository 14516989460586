.maintenance-page {
    color: #fff;
    font-size: 20px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
}

.maintenance-page .einstein {
    scale: 0.5;
    margin-bottom: -10px;
}

.mainteinance-page-explanation {
    max-width: 450px;
}

.maintenance-counter {
    background-color: #2f2f2f;
    width: fit-content;
    display: flex;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    padding: 0px;
    max-height: 0px;
    opacity: 0;
    transition: all 300ms;
}

.maintenance-counter.shown {
    padding: 10px;
    opacity: 1;
    max-height: 100px;
}

.maintenance-counter .separator {
    padding: 0px 8px;
    font-size: 30px;
    line-height: 25px;
}
