.swal2-container {
    background: rgba(0, 0, 0, 0.8) !important; /* Fundo mais escuro */
    z-index: 10000000000;
}

.swal2-popup {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5) !important;
    outline: 1px #ffffff50 solid !important;
    width: 400px !important;
    height: auto !important;
    padding: 20px !important;
    border-radius: 16px !important;
}

.swal2-confirm {
    border-radius: 8px !important;
}

.swal2-confirm,
.swal2-cancel {
    outline: none !important; /* Remove a borda de foco */
    box-shadow: none !important; /* Remove a sombra de foco */
}

.swal2-confirm:focus,
.swal2-cancel:focus {
    outline: none !important; /* Remove o outline ao focar */
    box-shadow: none !important; /* Remove qualquer sombra ao focar */
}

.aviso-popup .swal2-html-container {
    text-align: justify !important;
}

.aviso-popup.swal2-popup {
    width: 600px !important;
    max-width: 95vw;
    padding-top: 0px !important;
}
