.admin-material {
    background-color: #101010;
    padding: 10px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.admin-material-sku {
    background-color: #000;
    outline: 1px solid #fff;
    width: fit-content;
    /* margin: 0px auto; */
    /* margin-bottom: -5px; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 8px;
    font-weight: 500;
    border-radius: 4px;
    bottom: 2px;
}

.admin-material img {
    height: 120px;
    margin: 0px;
    padding: 0px;
    border-radius: 8px;
}

.new-group-modal-bg {
    z-index: 99999 !important;
}

.add-material-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.add-material-btns div {
    background-color: #1567d2;
    padding: 2px 8px;
    border-radius: 4px;
    cursor: pointer;
}
.add-material-btns div:hover {
    opacity: 0.7;
}

div.material-remove {
    background-color: #c93838;
}
