.sheet-products {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0px 120px;
    box-sizing: border-box;
    padding: 0px 30px;
    position: relative;
    min-height: calc(100% - 20px);
}

.sheet-products.syncing {
    pointer-events: none;
    opacity: 0;
    display: none;
}

.sheet-product img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    /* background-color: #fff; */
}

.sheet-product {
    box-sizing: border-box;
    background-color: #262626;
    /* outline: 1px solid #f00; */
    width: 98%;
    max-width: 900px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: sheet-product 300ms ease-in-out 0ms 1 normal forwards;
    transform: translateY(100px);
    opacity: 0;
    z-index: 1;
    transition: all 200ms, z-index 0ms 100ms;
}

/* .sheet-products .renderIfVisible {
    width: 100%;
    max-width: 920px;
    margin-left: 16px;
} */

.sheet-product:hover {
    z-index: 999;
    transition: all 200ms;
}

.sheet-product.sold-out {
    filter: brightness(0.85);
}

.sheet-product.sold-out:hover {
    filter: none;
}

@keyframes sheet-product {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    50% {
        transform: translateY(-10px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.sheet-product-sku {
    /* outline: 1px solid #f00 */
    padding: 2px 4px;
    background-color: #1a1a1a;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #ffffff51;
    min-width: 35px;
}

.sheet-product-img {
    position: relative;
}

.sheet-product-sku::after {
    border-radius: 0px;
    font-size: 9px;
}

.sheet-product-name-container {
    position: relative;
    display: flex;
    margin: 4px;
}

.sheet-product-name {
    padding: 6px 10px;
    line-height: 20px;
    border-radius: 6px;
    font-size: 13px;
    background-color: #1a1a1a;
    color: #fff;
    border: none;
    width: 500px;
    transition: all 200ms, border 0ms;
    outline: 2px solid #1567d200;
    height: 200px;
    max-height: 32px;
    overflow: hidden;
    resize: none;
    white-space: nowrap;
    /* border: 2px solid #f00; */
    box-sizing: border-box;
    border-right: 10px solid #1a1a1a;
    position: relative;
}

.sheet-product-name-length {
    position: absolute;
    bottom: 6px;
    right: 0px;
    font-size: 12px;
    color: #1567d2;
    opacity: 0;
    transition: all 200ms;
    font-weight: 500;
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    box-sizing: border-box;
}

.sheet-product-name:focus {
    outline: 2px solid #1567d2;
    max-height: 72px;
    white-space: normal;
    border-right: 0px solid #1a1a1a;
}

.sheet-product-name:focus + .sheet-product-name-length {
    opacity: 1;
}

.sheet-product-name-container.toosmall .sheet-product-name:focus {
    outline: 2px solid #f00;
}

.sheet-product-name-container.toosmall .sheet-product-name-length {
    color: #f00;
}

.sheet-product-toosmall {
    opacity: 0;
    transition: all 200ms;
    color: #f00;
    opacity: 0;
}

@keyframes name-toosmall {
    0% {
        transform: rotateZ(-3deg);
        opacity: 0;
    }
    25% {
        transform: rotateZ(3deg);
    }
    50% {
        transform: rotateZ(-3deg);
    }
    75% {
        transform: rotateZ(3deg);
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.sheet-product-name-container.toosmall .sheet-product-toosmall {
    animation: name-toosmall 250ms ease-in-out 0ms 1 normal forwards;
}

.sheet-product-name-container.tootoosmall .sheet-product-name {
    outline: 2px solid #f00;
}

.name-notsaved {
    position: absolute;
    color: #f00;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 12px;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
}

.sheet-product-name-container.tootoosmall .name-notsaved {
    opacity: 1;
}

.sheet-product-name-container .sheet-product-name:focus ~ .name-notsaved {
    opacity: 0;
}

.sheet-product-check {
    min-width: 24px;
    min-height: 24px;
    border-radius: 4px;
    margin-right: 14px;
    background-color: #363636;
    position: relative;
    cursor: pointer;
}

.sheet-product-checked {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #1567d2;
    border-radius: 4px;
    opacity: 0;
    scale: 0.1;
    transition: all 200ms;
}

.sheet-product-checked.checked {
    opacity: 1;
    scale: 1;
}

.sheet-product-checked::after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.sheet-product-ean {
    padding: 4px 8px;
    font-size: 14px;
}

.sheet-buttons-container {
    position: fixed;
    bottom: 21px;
    left: 0px;
    z-index: 9999899;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 200px);
    margin-left: 200px;
    pointer-events: none;
    /* outline: 1px solid #f00; */
}

.sheet-buttons {
    width: 900px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 30px;
    padding-right: 8px;
    /* outline: 1px solid #f00; */
}

.sheet-btn {
    pointer-events: all;
}

.sheet-buttons:not(.loading) .marketplace-select-window:hover,
.sheet-buttons:not(.loading) .sheet-marketplace:hover + .marketplace-select-window {
    max-height: 1000px;
    opacity: 1;
    pointer-events: all;
    transition: all 600ms, opacity 100ms;
}

.sheet-buttons .mktselected {
    background-color: #1a1a1a;
}

.sheet-buttons .mktselect {
    position: relative;
    z-index: 99;
    margin-right: 10px;
}

.sheet-buttons.loading {
    pointer-events: none;
}

.sheet-buttons .marketplace-select-window {
    position: absolute;
    background-color: #1a1a1a;
    border: 1px solid rgba(211, 211, 211, 0.529);
    width: 125px;
    /* border-radius: 10px 10px 0px 0px; */
    bottom: -6px;
    z-index: -1;
    left: 50%;
    transform: translate(-50%);
    border-radius: 12px;
    opacity: 0;
    pointer-events: none;
    height: fit-content;
    max-height: 0px;
    transition: all 300ms, opacity 200ms 200ms;
    padding: 6px;
    padding-bottom: 45px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65);
}

.sheet-marketplace img {
    height: 32px;
}

.sheet-marketplace {
    background-color: #1a1a1a;
    /* width: 130px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    cursor: pointer;
    padding: 0px 6px 0px 2px;
    box-sizing: border-box;
}

img.sheet-config-icon {
    height: 14px;
    background-color: #363636;
    padding: 4px;
    border-radius: 50%;
    /* outline: 1px solid #ffffff5a; */
    transition: all 400ms ease-in-out;
    scale: 1;
}

img.sheet-config-icon:hover {
    transform: rotateZ(-180deg);
    scale: 1.2;
}

.sheet-export-btn {
    background-color: #1567d2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
    margin-right: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 15px;
    transition: all 200ms;
    color: #fff;
    position: relative;
    opacity: 0;
    max-width: 0px;
    margin: 0px;
}

.sheet-export-btn.shown {
    margin-right: 6px;
    opacity: 1;
    max-width: 200px;
}

.sheet-export-btn .sheet-integration {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000d7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    flex-direction: column;
    font-size: 12px;
    border-radius: 6px;
    pointer-events: none;
    opacity: 0;
    transition: 200ms;
}

.sheet-export-btn .sheet-integration div {
    font-size: 10px;
}

.sheet-export-btn.disabled:hover .sheet-integration {
    opacity: 1;
    pointer-events: all;
}

.sheet-export-btns.exporting .sheet-export-btn {
    background-color: #363636;
    color: #929292;
    pointer-events: none;
}

.bling-exporting .loader {
    scale: 0.5;
    top: 40%;
    left: -2px;
    transform: translateY(-50%);
}

.bling-exporting {
    max-height: 0px;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    transition: all 400ms;
    position: relative;
    font-size: 12px;
    margin-top: 0px;
    padding-left: 40px;
    text-align: justify;
}

.bling-exporting.exported {
    padding: 0px;
    transition: all 400ms 300ms;
}

.bling-exporting.exporting {
    max-height: 500px;
    opacity: 1;
    margin-top: 10px;
}

.sheet-export-btn.disabled {
    background-color: #363636;
    color: #929292;
    cursor: default;
}

.sheet-export-btn:not(.disabled):hover {
    background-color: #0c3c7b;
}

.sheet-product-price {
    min-width: 70px;
    text-align: center;
    margin-right: 12px;
}

.sheet-product-price small {
    font-size: 12px;
}

.sheet-product-price::after {
    font-size: 11px;
}

.sheet-config-modal-bg,
.sheet-export-modal-bg {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 9999999;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 300ms;
}

.sheet-config-modal,
.sheet-export-modal {
    background-color: #0c0c0c;
    width: 95%;
    max-width: 420px;
    height: fit-content;
    max-height: 530px;
    border-radius: 20px;
    scale: 0.1;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    /* transform: translateY(1000px); */
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    overflow: hidden;
}

.sheet-config-modal-bg.shown,
.sheet-export-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.sheet-config-modal-bg.shown .sheet-config-modal,
.sheet-export-modal-bg.shown .sheet-export-modal {
    scale: 1;
    /* transform: translateY(0px); */
}

.sheet-config-modal-title img {
    height: 50px;
    margin: 0px;
}

.sheet-config-modal-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: -18px;
}

.sheet-config-modal-section {
    background-color: #030303;
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 8px;
    font-size: 16px;
    /* font-weight: 600; */
}

.sheet-config-prices {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}

.sheet-config-percent {
    margin-top: 12px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sheet-config-prices input {
    width: 80px;
    border-radius: 6px;
    border: none;
    padding: 2px 4px;
    /* margin-left: 8px; */
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
}

.sheet-config-prices input:focus {
    outline: 2px solid #1567d2;
}

.sheet-percent-info {
    display: inline-block;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    margin-left: 4px;
    transform: translateY(-1px);
    position: relative;
    cursor: pointer;
    transition: all 200ms;
}

.sheet-percent-info-text {
    position: absolute;
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    width: 220px;
    right: 24px;
    top: 5px;
    text-align: justify;
    background-color: #262626;
    padding: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 2px 2px 1px 1px #00000022;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%) translateX(20px);
    transition: all 150ms 100ms;
    border: 1px solid #ffffff24;
}

.sheet-percent-info:hover {
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.738);
}

.sheet-percent-info:hover .sheet-percent-info-text {
    opacity: 1;
    transform: translateY(-50%) translateX(0px);
}

.sheet-percent-info-text::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    height: 12px;
    width: 12px;
    right: -12px;
    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}
/* 
.sheet-product-ean .sheet-sold-out {
    width: 85px;
    height: auto;
}

@keyframes sheet-sold-out {
    0% {transform: rotateZ(-6deg) translateX(-50%);}
    25% {transform: rotateZ(6deg) translateX(-50%);}
    50% {transform: rotateZ(-6deg) translateX(-50%);}
    75% {transform: rotateZ(6deg) translateX(-50%);}
    100% {transform: rotateZ(-6deg) translateX(-50%);}
}

.sheet-sold-out-container {
    position: absolute;
    top: -20px;
    left: 50%;
    animation: sheet-sold-out 3000ms ease-in-out 0ms infinite normal forwards;
    transform-origin: left top;
    transition: all 200ms 1000ms;
    pointer-events: none;
}

.sheet-sold-out-text {
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    animation: sheet-sold-out-text 3000ms ease-in-out 0ms infinite normal forwards;
    font-size: 13px;
}

@keyframes sheet-sold-out-text {
    0% {transform: translateX(-46%);}
    25% {transform: translateX(-54%);}
    50% {transform: translateX(-46%);}
    75% {transform: translateX(-54%);}
    100% {transform: translateX(-46%);}
}

.sheet-product-ean:hover .sheet-sold-out-container {
    opacity: 0;
    top: -30px;
    transition: all 200ms;
} */

.sheet-export-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sheet-export-btns .sheet-export-btn {
    margin: 0px;
}

.sheet-export-modal {
    width: 350px;
}

.sheet-product-img .sheet-product-img-large {
    position: absolute;
    height: 200px;
    width: 200px;
    z-index: 99999;
    left: calc(100% + 5px);
    top: -20px;
    border: 4px solid #262626;
    box-sizing: border-box;
    border-radius: 14px;
    transform: translateX(-40px);
    opacity: 0;
    transition: all 300ms;
    pointer-events: none;
    box-shadow: 2px 2px 1px 1px #00000022;
}

.sheet-product-img:hover .sheet-product-img-large {
    transform: translateX(0px);
    opacity: 1;
}

.sheet-sold-out-text {
    color: #f00;
    font-weight: 600;
    position: absolute;
    padding: 6px;
    /* border: 2px solid #f00; */
    /* border-radius: 4px; */
    background-color: #252525;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    font-size: 14px;
    transition: all 200ms;
}

.sheet-sold-out-text:hover {
    opacity: 0;
}

.sheet-product-name-container .sheet-name-reload {
    position: absolute;
    height: 16px;
    width: 16px;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    padding: 3px;
    border-radius: 50%;
    background-color: #252525;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.sheet-product-name-container .sheet-name-reload img {
    filter: grayscale(1);
    width: 100%;
    height: 100%;
    transition: all 200ms ease-in-out;
    transform-origin: 50% 50%;
}

.sheet-product-name-container .sheet-name-reload:hover img {
    transform: rotateZ(-90deg);
    filter: grayscale(0);
}

.sheet-product-name-container.reloading .sheet-name-reload {
    animation: onerotation 400ms ease-in-out 0ms 1;
    /* transform: rotateZ(180deg); */
}

@keyframes onerotation {
    0% {
        transform: translateY(-50%) rotateZ(-90deg);
    }
    100% {
        transform: translateY(-50%) rotateZ(270deg);
    }
}

.loader.sheet-loading {
    left: 50%;
    top: 45%;
    transform: translateX(-50%) translateY(-50%);
    scale: 1;
}

.loader.sheet-loading.hidden {
    opacity: 0;
}

.order-search-bar.hidden,
.sheet-buttons-container.hidden {
    transform: translateY(100px);
    opacity: 0;
}

.order-search-bar,
.sheet-buttons-container {
    transition: all 400ms;
}

.bling-icon {
    z-index: 99999;
    position: absolute;
    left: 10px;
    top: -7px;
}

.bling-icon img {
    height: min-content;
    background-color: #1a1a1a;
    border-radius: 4px;
    padding: 4px 5px 2px;
    width: 26px;
    outline: 1px solid #ffffff53;
}

.bling-information {
    background-color: #212121;
    color: #fff;
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    padding: 3px 6px;
    bottom: calc(100% + 4px);
    transform: translateX(-50%) translateY(10px);
    left: 50%;
    white-space: nowrap;
    border-radius: 6px;
    box-shadow: 2px 2px 1px 1px #00000022;
    text-align: justify;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms 100ms;
    border: 1px solid #ffffff24;
}

.bling-information:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    height: 8px;
    width: 8px;
    bottom: -8px;
    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

.bling-icon:hover .bling-information {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
}

.sheet-export-modal-explain {
    font-size: 14px;
}

.sheet-product-name-overlay {
    position: absolute;
    outline: 1px solid #1567d200;
    width: 100%;
    height: 100%;
    transition: all 200ms;
    opacity: 0;
    /* outline: 1px solid #1567d200; */
    pointer-events: none;
    padding: 6px 10px;
    line-height: 20px;
    border-radius: 6px;
    font-size: 13px;
    background-color: #1a1a1a;
    color: #fff;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
}

.sheet-product-name-container.reloading .sheet-product-name-overlay {
    outline: 1px solid #1567d2;
    opacity: 1;
    transition: outline 200ms;
    pointer-events: all;
}

.sheet-export-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
}

.sheet-export-button {
    background-color: #1567d2;
    padding: 2px 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 200ms;
}

.sheet-export-button.cancel {
    background-color: rgb(144, 8, 8);
}

.sheet-export-button:hover {
    opacity: 0.7;
}

.sheet-products-syncing {
    pointer-events: none;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    /* outline: 1px solid #f00; */
    width: calc(100% - 200px);
    background-color: #1a1a1a;
    z-index: 9999999;
}

.sheet-products-syncing.shown {
    opacity: 1;
    pointer-events: all;
    height: 100vh;
}

.sheet-products-syncing .loader {
    position: relative;
}

.syncing-title {
    margin-top: 20px;
    font-size: 18px;
}

.syncing-bar {
    width: 280px;
    height: 20px;
    outline: 1px solid #fff;
    margin-top: 10px;
    border-radius: 8px;
    display: flex;
}

.syncing-bar-inner {
    border-radius: 6px;
    display: inline-block;
    min-width: 20px;
    height: 100%;
    background-color: #fff;
    transition: all 3000ms;
}

.syncing-qtty {
    margin-top: 10px;
}

.syncing-say {
    font-size: 20px;
}

.sheet-products-syncing .einstein {
    scale: 0.4;
}

.sheet-product-new {
    background-color: #1567d2;
    font-size: 10px;
    padding: 0px 4px;
    border-radius: 2px;
    position: absolute;
    top: -2px;
    right: -2px;
    opacity: 0;
    z-index: 999;
    animation: new-product 1s ease-in-out infinite;
}

@keyframes new-product {
    0% {
        scale: 1;
    }
    50% {
        scale: 0.95;
    }
}

.sheet-product-new.shown {
    opacity: 1;
}

.marketplace-btn.selected22 img {
    filter: none!important
}

@media only screen and (max-width: 768px) {
    img.sheet-menu {
        display: block;
    }

    .sheet-product-img img {
        width: 40px;
        height: 40px;
    }

    .sheet-product-name {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        font-size: 11px;
        padding: 3px 6px;
        line-height: 17px;
        max-height: 22px;
    }

    .sheet-product-name:focus {
        max-height: 54px;
        white-space: normal;
    }

    .sheet-product-name-container {
        width: 100%;
        margin: 0px 8px;
    }

    .meli-product-number {
        display: none;
    }

    .sheet-product-price {
        min-width: 50px;
        margin-right: 0px;
        font-size: 14px;
    }
    .sheet-product-price small {
        font-size: 10px;
    }
    .sheet-product-ean {
        display: none;
    }

    .sheet-product-name-length {
        height: 10px;
        font-size: 9px;
    }
}
