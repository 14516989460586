.search-bar {
    height: 139px;
    position: fixed;
    bottom: -1px;
    left: 0px;
    width: calc(100% - 200px);
    box-sizing: border-box;
    z-index: 99999;
    background-color: #1a1a1a;
    padding: 50px 0px 20px 0px;
    margin-left: 200px;
    mask: linear-gradient(transparent, transparent, black, black, black, black);
    pointer-events: none;
    transition: all 300ms;
}

.search-bar input,
.search-bar-placeholder {
    box-sizing: border-box;
    transform: translate(-50%);
    left: 50%;
    top: 40px;
    position: absolute;
    padding: 10px 20px;
    border-radius: 8px;
    border: 0px;
    background-color: #292929;
    color: #fff;
    font-size: 18px;
    pointer-events: all;
    transition: all 200ms;
    border: 1px solid #ffffff00;
    margin-top: 40px;
}

.search-bar-placeholder {
    border: 1px solid #ffffff40;
    pointer-events: none;
}

.search-bar .hidden {
    transition: all 300ms;
    opacity: 0;
}

.search-bar input::placeholder {
    opacity: 0.5;
}

.search-bar input:focus {
    outline: none;
    border: 1px solid #ffffff40;
}

.btn-row {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    color: #fff;
    align-items: center;
    min-height: 10px;
    /* overflow: hidden; */
    margin-top: -20px;
    position: absolute;
    bottom: 14px;
    z-index: 10000;
    right: 115px;
    pointer-events: all;
}

.btn-row .btn {
    display: block;
    opacity: 0;
    background-color: #292929;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 80px;
    cursor: pointer;
    font-weight: 500;
    transform: translateX(100px);
    transition: all 200ms;
    z-index: 100000;
}

.btn-row .selection {
    opacity: 0;
    transform: translateX(100px);
    transition: all 200ms;
    cursor: pointer;
}

.btn.btn-show-selected {
    padding: 5px 9px;
    font-size: 14px;
}

.selection.visible,
.btn.visible {
    opacity: 1;
    transform: translateX(0px);
}

.btn-row.visible .btn {
    opacity: 1;
    transform: translateX(0px);
}

.btn-row .btn:hover {
    background-color: #1a1a1a;
}

.btn-row .btn.buy {
    background-color: #1567d2;
}

.order-overlay {
    /* background-color: #fff; */
    height: 75px;
    position: fixed;
    bottom: 0px;
    left: calc(50% + 100px);
    overflow: hidden;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: right;
    pointer-events: none;
}

.order-successfull {
    background-color: #37941e;
    padding: 4px 10px;
    border-radius: 6px;
    margin-right: 8px;
    pointer-events: none;
    transition: all 300ms, transform 300ms 500ms;
    transform: translateX(400px);
    opacity: 0;
}

.order-successfull.show {
    transition: all 300ms;
    transform: translateX(0px);
    opacity: 1;
}

.search-children {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 57px;
    pointer-events: all;
    transition: all 200ms;
    padding: 0px 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
    .search-bar {
        width: 100%;
        margin-left: 0px;
    }

    .search-bar.hidden {
        bottom: -100px;
    }

    input.align-center {
        padding: 8px 12px;
        font-size: 16px;
        border-radius: 6px;
        /* top: 46px; */
    }

    .search-bar-placeholder {
        display: none;
    }

    .search-bar {
        height: 130px;
    }
}
