:root {
    --box-light: #1567d2;
    --box-mid: #1258b3;
    --box-inner-mid: #1258b3;
    --box-dark: #093c80;
    --box-inner-dark: #093c80;
    --box-inner-darkest: #03295c;
}

body {
    display: grid;
    place-items: center;
    height: 100vh;
}

.box-container {
    position: fixed;
    transition: all 300ms;
    /* opacity: 0; */
    transform: translateX(-50%) translateY(80px);
    left: calc(50% + 100px);
    bottom: 0px;
    z-index: 100000;
    opacity: 0;
    pointer-events: none;
    /* transform: translateY(100px); */
}

.box-container.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateX(-50%) translateY(0px);
}

#logo {
    --size: 155px;
    width: 150px;
    height: 90px;
    position: fixed;
    right: -25px;
    bottom: 5px;
    overflow: visible;
    /* padding: 1rem; */
    /* background: #eeeeee61; */
    /* border-radius: 0.5rem; */
    perspective: 30rem;
    color: #fff;
    pointer-events: none;
    /* transform: translateX(150px); */
    transition: all 300ms;
    z-index: 9999;
}

.checkout-bar.visible #logo {
    transform: translateX(0px);
}

#logo .box {
    position: relative;
    width: 100px;
    height: 40px;
    transform-style: preserve-3d;
    transform: translateZ(-20rem) rotatex(-15deg) rotatey(45deg);
    font-size: 28px;
    text-align: center;
    transition: 0.75s;
    cursor: pointer;
}

.box-container.visible #logo .box {
    pointer-events: all;
}

#logo .box > * {
    position: absolute;
    background: #0008;
    transition: 0.4s;
}

#logo .box .side.front,
#logo .box .side.left,
#logo .box .side.back,
#logo .box .side.right {
    width: var(--size);
    height: calc(var(--size) * 0.35);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(0deg)
        translateZ(calc(var(--size) * 0.499));
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-rendering: geometricPrecision;
}

#logo .box .side.front {
    background: var(--box-mid);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    border: 9px solid #292929;
    transition: 0.4s, border 500ms 500ms, margin 500ms 500ms;
    margin: -9px;
    /* transform: translateY(calc(var(--size) * 0.25)) rotateY(0deg) translateZ(0); */
    /*   width: 250px */
}

#logo .box .side.right {
    background: var(--box-inner-mid);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(90deg)
        translateZ(calc(var(--size) * 0.499));
}

#logo .box .side.back {
    background: var(--box-inner-dark);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(180deg)
        translateZ(calc(var(--size) * 0.499));
}

#logo .box .side.left {
    background: var(--box-dark);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(-90deg)
        translateZ(calc(var(--size) * 0.499));
}

#logo .box .side.bottom {
    width: var(--size);
    height: var(--size);
    transform: translateY(-15px) rotateX(90deg)
        translateZ(calc(var(--size) * -0.195));
    background: var(--box-inner-darkest);
}

#logo .box .flap {
    background: var(--box-light);
}

#logo .box .flap.front,
#logo .box .flap.back {
    width: var(--size);
    height: calc(var(--size) * 0.5);
}

#logo .box .flap.left,
#logo .box .flap.right {
    width: calc(var(--size) * 0.5);
    height: var(--size);
}

#logo .box .flap.front {
    transform-origin: 50% 100% calc(var(--size) * 0.5);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        translateZ(calc(var(--size) * 0.499));
}

#logo .box .flap.back {
    transform-origin: 50% 100% calc(var(--size) * -0.5);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(-90deg)
        translateZ(calc(var(--size) * -0.499));
}

#logo .box .flap.left {
    transform-origin: 0 50% 0;
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        rotateY(0deg);
}

#logo .box .flap.right {
    transform-origin: 0 50% 0;
    transform: translateX(var(--size)) translateY(calc(var(--size) * -0.25))
        rotateX(90deg) rotateY(180deg);
}

#logo:not(.open) .box .flap.left,
#logo:not(.open) .box .flap.right {
    transition-delay: 0.2s;
}

#logo.open .box {
    pointer-events: none;
    transform: translateZ(-30rem) rotatex(-30deg) rotatey(45deg);
}

#logo.openfast .box .flap {
    transition-duration: 100ms !important;
    transition-delay: 0ms !important;
}

#logo.openfast {
    /* transition: all 0.75s, opacity 1000ms 1500ms; */
    /* opacity: 0; */
    animation: fadeOut 500ms 500ms linear 1;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#logo .box {
    transform: translateZ(-30rem) rotatex(-1deg) rotatey(0deg);
}

#logo.shaking {
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.85);
    background-color: rgba(0, 0, 0, 0.85);
    pointer-events: all;
}

#logo.shaking .box {
    pointer-events: none;
    transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg);
    /*   transition: 20s; */
    animation: tilt-shaking 150ms linear infinite;
    animation-delay: 300ms;
}

#logo.closed .box {
    pointer-events: none;
    transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg);
}

@keyframes tilt-shaking {
    0% {
        transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg)
            rotatez(0deg);
    }

    25% {
        transform: translateZ(-30rem) rotatex(-30deg) rotatey(45deg)
            rotatez(5deg);
    }

    50% {
        transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg)
            rotatez(0deg);
    }

    75% {
        transform: translateZ(-30rem) rotatex(-20deg) rotatey(45deg)
            rotatez(-5deg);
    }

    100% {
        transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg)
            rotatez(0deg);
    }
}

@keyframes rotateAnimation {
    from {
        transform: translateZ(-30rem) rotatex(-25deg) rotatey(45deg);
    }

    to {
        transform: translateZ(-30rem) rotatex(-25deg) rotatey(405deg);
    }
}

#logo.open .box .flap.front,
#logo.open .box .flap.back {
    transition-delay: 0.2s;
}

#logo.open .box .flap.front {
    background: var(--box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(-120deg)
        translateZ(calc(var(--size) * 0.499));
}

#logo.open .box .flap.back {
    background: var(--box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(120deg)
        translateZ(calc(var(--size) * -0.499));
}

#logo.open .box .flap.left {
    background: var(--box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        rotateY(-210deg);
}

#logo.open .box .flap.right {
    background: var(--box-inner-mid);
    transform: translateX(var(--size)) translateY(calc(var(--size) * -0.25))
        rotateX(90deg) rotateY(390deg);
}

#logo.open .box .side.front,
#logo.closed .box .side.front,
#logo.shaking .box .side.front {
    border: 0px;
    border-radius: 0px;
    margin: 0px;
    transition: border 0s;
}

.buy-popup-modal {
    opacity: 0;
    top: 0px;
    left: 0px;
    z-index: -100;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-popup-modal.open {
    z-index: 999999;
    opacity: 1;
}

.buy-popup-modal .buy-popup {
    scale: 0.1;
    transition: all 300ms;
    background-color: #121212;
    width: 50vw;
    max-height: 90vh;
    height: fit-content;
    border-radius: 25px;
    padding: 20px;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 10px 5px #1567d23c; */
    border: 2px solid #1d1d1d;
}

.buy-popup-modal.open .buy-popup {
    scale: 1;
}

.select-marketplace-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.select-marketplace-container img {
    width: 50px;
}

.marketplace-select {
    background-color: #1c1c1c;
    margin: 6px;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;
}

.marketplace-select:hover {
    background-color: rgb(70, 84, 97);
}

.marketplace-select.selected {
    background-color: #1567d2;
}

.select-file input {
    display: none;
}

.select-file {
    text-align: center;
    display: block;
    width: fit-content;
    cursor: pointer;
    position: relative;
}

.select-file .button-select-file {
    display: block;
    background-color: #1c1c1c;
    width: 200px;
    height: 20px; /*Gab*/
    text-align: center;
    padding: 10px 0px;
    border-radius: 8px;
    margin-top: 10px;
    transition: all 200ms;
    font-weight: 500;
}

.select-file .button-select-file:hover {
    background-color: #121a2c;
}

.select-files-container {
    display: flex;
    justify-content: space-evenly;
}

.select-file .loading {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #000000d6;
    font-weight: 700;
    border-radius: 10px;
    opacity: 0;
    transition: all 200ms;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-file .loading .loader {
    scale: 0.5;
    position: relative;
    margin-top: 6px;
}

.select-file.loading .loading {
    opacity: 1;
    z-index: 10;
}

.select-file.loading {
    pointer-events: none;
}

.select-file.selected .button-select-file {
    background-color: #1567d2;
}

.see-file {
    opacity: 0;
    display: block;
    text-align: center;
    font-size: 13px;
    margin: 4px;
    cursor: pointer;
    transition: all 400ms;
}

.see-file.visible {
    opacity: 1;
}

.see-file:hover {
    color: #1567d2;
}

.selected-products-list {
    border: 1px solid rgba(255, 255, 255, 0.069);
    /* background-color: #090909; */
    width: 100%;
    min-height: 100px;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    flex-wrap: nowrap;
    overflow-x: auto;
    font-size: 14px;
}

.selected-product-item img {
    height: 80px;
    border-radius: 8px;
}

.selected-product-item span {
    display: block;
    /* padding-bottom: 20px; */
}

.selected-product-item {
    padding: 8px;
    text-align: center;
    background-color: #1c1c1c;
    border-radius: 10px;
    margin: 5px;
    /* border: 1px solid rgba(255, 255, 255, 0.162); */
}

.order-value-container {
    padding: 16px;
    margin-top: 15px;
    background-color: #1c1c1c;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}

.order-value {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
}

.order-value small {
    font-size: 16px;
    margin-left: 5px;
}

.order-value strong {
    font-size: 24px;
}

.end-order {
    padding: 8px 16px;
    background-color: #1567d2;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    transition: all 200ms;
}

.end-order:hover {
    opacity: 0.7;
}

.order-loading,
.order-succesfull {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 300ms;
}

.order-succesfull {
    background-color: #1567d2;
    font-size: 26px;
    font-weight: 600;
}

.btn-view-orders {
    font-size: 20px;
    background-color: #000;
    margin: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    transition: all 200ms;
}

.btn-view-orders:hover {
    opacity: 0.8;
}

.order-loading.visible,
.order-succesfull.visible {
    opacity: 1;
    z-index: 99;
}

.checkout-bar {
    position: fixed;
    bottom: 65px;
    right: 0px;
    width: calc(100% - 200px);
    height: 0px;
    background-color: #1a1a1a;
    z-index: 99999;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.checkout-bar.visible {
    height: 70px;
    pointer-events: all;
}

.checkout-bar-options {
    background-color: #292929;
    height: 55px;
    /* margin: 10px; */
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 5px;
    transition: all 300ms;
    opacity: 0;
    width: 90%;
    /* transform: translateX(-50%) translateY(50px); */
    /* left: 18px; */
    /* top: 0px; */
    /* position: absolute; */
    position: relative;
}

.checkout-bar.visible .checkout-bar-options.hide {
    opacity: 0;
}

.checkout-bar.visible .checkout-bar-options {
    transform: translateY(0px);
    opacity: 1;
}

.checkout-bar-products {
    position: fixed;
    transition: all 300ms;
    /* opacity: 0; */
    transform: translate(-50%);
    left: calc(50% + 100px);
    bottom: 0px;
    z-index: 100000;
    /* overflow-y: auto; */
    /* height: 128px; */
    pointer-events: none;
    /* margin-bottom: 10px */
    /* margin-left: -200px; */
    box-sizing: border-box;
    /* padding-left: -10px */
    /* clip-path: inset( -100vw -100vw -100vw 0 ); */
    clip-path: inset(0px 0px 0px 8px);
}

.checkout-products-container {
    position: relative;
    /* width: calc(100% - 300px); */
    height: 192px;
    /* margin-left: -20px; */
    /* width: 100px; */
    padding-left: 5px;
    transition: all 200ms;
    /* margin-top: 64px; */
    /* padding-left: 50px; */
    /* overflow-x: auto; */
}

.checkout-bar-products::-webkit-scrollbar-thumb {
    pointer-events: all;
}

.checkout-bar-product img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    /* border: 2px solid #000; */
}

.checkout-bar-product {
    position: relative;
    margin-right: 5px;
    /* animation: show 100ms linear 0ms 1; */
    /* animation-fill-mode: forwards; */
    /* opacity: 0; */
    transition: all 200ms;
    /* margin-left: -50px */
    pointer-events: all;
    margin-top: 64px;
    cursor: pointer;
}

.checkout-bar-product.closedbox {
    /* animation: show 100ms linear 100ms 1 forwards!important; */
}

.checkout-bar-product span {
    display: none;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    font-weight: 600;
    color: #ebeef0;
    /* text-shadow: 0px 2px 1px #000; */
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.checkout-bar-product .qtty {
    background-color: #c93838;
    padding: 0px;
    position: absolute;
    min-width: 16px;
    box-sizing: border-box;
    padding: 0px 4px;
    height: 16px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -4px;
    right: -4px;
    font-weight: 600;
    animation: qttypop 200ms 1;
}

@keyframes qttypop {
    0% {
        scale: 0;
    }

    70% {
        scale: 1.2;
    }

    100% {
        scale: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
        margin-left: -50px;
    }

    50% {
        margin-left: 0px;
    }

    100% {
        opacity: 1;
    }
}

.checkout-bar-btns {
    position: absolute;
    display: flex;
    opacity: 1;
    transition: all 200ms;
    /* background-color: #292929; */
    position: fixed;
    transition: all 300ms, z-index 0ms;
    opacity: 0;
    transform: translateX(-50%) translateY(140px);
    left: calc(50% + 100px);
    bottom: 82px;
    z-index: 9999;
    justify-content: flex-end;
    /* opacity: 0; */
    pointer-events: none;
}

.checkout-bar-btns.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
    z-index: 100000;
    transition: all 300ms, z-index 100ms;
}

.checkout-bar-btns.hidden {
    opacity: 0;
}

.checkout-bar-btns > div {
    position: relative;
    pointer-events: all;
}

.select-file.checkout {
    margin: 0px;
}

.mktselect .select-file.checkout {
    margin-right: 0px;
}

.mktselect {
    margin-right: 8px;
}

.mktselect .button-select-file {
    min-width: 55px;
}

.select-file.checkout .loading {
    padding: 8px 27px;
}

.select-file.checkout > span {
    margin: 0px;
    width: auto;
    padding: 8px 16px;
}

.see-file.checkout {
    display: none;
    position: absolute;
    bottom: -16px;
    left: 40%;
    transform: translate(-50%);
    font-size: 10px;
}

.checkout-bar-product {
    position: absolute;
    top: 8px;
    /* left:auto */
}

.checkout-bar-product.openbox {
    opacity: 1;
    position: absolute;
    /* top: 70px; */
    /* right: -500px !important; */
    /* z-index: 9999!important; */
    /* animation: godown 500ms linear 100ms 1; */
    transition-delay: top 2s;
    animation: godown 1s;
    animation-fill-mode: forwards;
}

@keyframes godown {
    0% {
        top: 16px;
        opacity: 1;
    }

    20% {
        top: -15px;
    }

    80% {
        opacity: 0.7;
    }

    100% {
        top: 60px;
        opacity: 0;
    }
}

.fullscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999;
    pointer-events: none;
}

.btns-buy {
    position: absolute;
    right: 96px;
    top: 74px;
    display: flex;
    align-items: center;
}

.total-price {
    padding: 4px 8px 3px 8px;
    border-radius: 8px;
    margin-right: 2px;
    background-color: #0000002c;
    font-weight: 600;
    font-size: 20px;
    color: #c93838;
}

.total-price small {
    font-size: 12px;
    margin-right: -1px;
    color: #c93838;
}

.total-price strong {
    color: #c93838;
    font-size: 14px;
    font-weight: 800;
}

.btn.btn-show-selected {
    cursor: pointer;
    border-radius: 7px;
    transition: all 200ms;
    padding: 7px 8px;
    margin-right: 5px;
}

.btn.btn-show-selected:hover {
    background-color: #0000001b;
    font-weight: 500;
}

.checkout-bar-btns .marketplace-select-window {
    position: absolute;
    background-color: #1a1a1a;
    border: 1px solid rgba(211, 211, 211, 0.529);
    width: calc(100% + 13px);
    /* border-radius: 10px 10px 0px 0px; */
    bottom: -6px;
    z-index: -1;
    left: 50%;
    transform: translate(-50%);
    border-radius: 12px;
    opacity: 0;
    pointer-events: none;
    height: fit-content;
    max-height: 0px;
    transition: all 300ms, opacity 200ms 200ms;
    padding: 6px;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /* overflow: hidden; */
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65);
}

.marketplace-btn img {
    height: 38px;
    filter: grayscale(1);
    transition: all 200ms;
    background-color: #1a1a1a;
}

.marketplace-btn:hover img,
.marketplace-btn.selected img {
    filter: grayscale(0);
}

.marketplace-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.marketplace-btn.selected {
    background-color: #343434;
}

.select-file .button-select-file.mktselected {
    background-color: #1567d2;
}

.checkout-bar-btns .marketplace-select-window:hover,
.select-file.checkout:hover + .marketplace-select-window {
    max-height: 1000px;
    opacity: 1;
    pointer-events: all;
    transition: all 600ms, opacity 100ms;
}

.checkout-bar-btns .modal,
.marketplace-modal,
.checkout-bar .modal,
.search-bar .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    left: 0px;
    z-index: 9999;
    opacity: 0;
    display: none;
}

.marketplace-modal {
    position: fixed;
    z-index: 10000;
}

.checkout-bar-options > .modal {
    z-index: 999;
}

.checkout-bar > .modal {
    z-index: 0;
}

.mktselect {
    transition: all 300ms;
    /* box-shadow:0 0 0 2000px rgba(0,0,0,0.65); */
    max-width: 200px;
}

.mktselect:hover {
    z-index: 99999;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65);
}

.checkout-bar-btns:hover {
    z-index: 9999999;
}

.mini-options {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    /* width: calc(100% + 10px); */
    width: 100%;
    justify-content: center;
    transition: all 1000ms;
}

.mini-option {
    background-color: #1567d2;
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    /* margin-right: 4px; */
    border: 1px solid #292929;
    animation: mini-option 200ms 1;
}

.mini-option:not(:only-child) {
    animation: mini-option2 200ms 1;
}

@keyframes mini-option {
    0% {
        scale: 0;
    }

    80% {
        scale: 1.3;
    }

    100% {
        scale: 1;
    }
}

@keyframes mini-option2 {
    0% {
        width: 5px;
    }

    100% {
        width: 18px;
    }
}

.mini-option img {
    height: 14px;
    width: auto;
    border-radius: 0px;
}

.checkout-bar-product .mini-sku {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: 0px;
    /* transform: translate(-50%); */
    top: -20px;
    background-color: #151515;
    padding: 2px 4px;
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 3px #0000007a;
    transition: all 300ms;
}

.checkout-bar-product .mini-sku:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #151515;
    border-bottom: 0;
    margin-left: -6px;
    margin-bottom: -5px;
}

.checkout-bar-product:hover .mini-sku {
    opacity: 1;
}

.modalbg {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 999999999999;
    width: calc(100% - 200px);
    height: 135px;
    background-color: rgba(0, 0, 0, 0.85);
    transition: all 500ms 200ms;

    opacity: 0;
    pointer-events: none;
}

.modalbg.visible {
    opacity: 1;
    transition: all 500ms 200ms;
}

.modal-prices-bg,
.modal-product-bg {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none; */
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.modal-product-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.modal-prices-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.modal-product {
    background-color: #0c0c0c;
    width: 95%;
    max-width: 850px;
    height: 80%;
    max-height: 530px;
    min-height: 530px;
    border-radius: 20px;
    scale: 0;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
}

.modal-product-box {
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
}

.modal-product-box.description-box {
    flex: auto;
    white-space: pre-wrap;
    overflow-y: hidden;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 8px 0px 5px;
    font-size: 16px;
}

.modal-product-bg.shown .modal-product,
.modal-product-bg.shown .modal-options {
    scale: 1;
}

.modal-product-img {
    width: 92%;
    min-height: 350px;
    height: auto;
    border-radius: 20px;
    position: relative;
    /* outline: 1px solid #c93838; */
    overflow: hidden;
}

.modal-product-img img {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    /* outline: 1px solid #c93838; */
}

.modal-product-img .dummy {
    margin-top: 100%;
}

.download-imgs-btn {
    font-size: 16px;
    background-color: #1567d2;
    padding: 4px 8px;
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: -5px;
    transition: all 200ms;
    cursor: pointer;
}

.download-imgs-btn:hover {
    background-color: #1e1e1e;
}

.modal-product-section.img-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 0px;
}

.modal-product-carrousel {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.modal-product-carrousel img {
    width: 55px;
    height: 55px;
    margin: 8px 8px 0px;
    border-radius: 8px;
    filter: brightness(0.3);
    transition: all 200ms;
    cursor: pointer;
}

.modal-product-title-marketplace {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    background-color: #000;
    padding: 2px;
    box-sizing: border-box;
    filter: saturate(0);
    cursor: pointer;
    transition: all 200ms;
}

.modal-product-title-marketplace:hover {
    background-color: #3b3b3b;
    filter: none !important;
}

.modal-product-title-marketplace.selected {
    background-color: #1567d2;
    filter: none !important;
}

.modal-product-section.description-section {
    padding: 20px;
    height: 100%;
    flex-flow: column;
    display: flex;
}

.modal-product-title-marketplaces {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.modal-product-title-marketplace:first-of-type {
    margin-left: auto;
}

.modal-product-title-marketplace {
    margin-left: 5px;
}

.modal-title-box {
    width: 100%;
    background-color: #181818;
    padding: 6px 8px;
    box-sizing: border-box;
    margin: 8px 0px 0px;
    border-radius: 6px;
    font-size: 12px;
    position: relative;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    cursor: pointer;
}

.modal-description-boxs {
    width: 100%;
    background-color: #181818;
    color: #fff;
    flex: auto;
    overflow-y: auto;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    margin: 8px 0px 0px;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: justify;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    cursor: pointer;
    position: relative;
}

.modal-description-box {
    flex: auto;
    display: flex;
    /* height: 100%; */
    border-radius: 8px;
    width: 100%;
    margin: 8px 0px 0px;
    overflow: hidden;
    cursor: pointer;
}

.modal-description-box-inner {
    /* height: 100%; */
    overflow-y: auto;
    flex: auto;
    font-size: 12px;
    background-color: #181818;
    padding: 8px;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    border-radius: 8px;
}

.modal-description-box:hover,
.modal-title-box:hover {
    outline: 1px solid #1567d2;
}

.modal-description-box::-webkit-scrollbar-track {
    background-color: #0a0a0a;
    border-radius: 8px;
}

.modal-description-box::-webkit-scrollbar-button {
    height: 4px;
}

.modal-title-box-reload {
    width: 15px;
    height: 15px;
    /* background-color: #c93838; */
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 1;
    transition: all 200ms;
    background-color: #000000;
    padding: 2px;
    /* outline: 1px solid #c93838; */
    filter: grayscale(1);
}

.modal-title-box-reload:hover {
    transform: translateY(-50%) rotateZ(-90deg);
    scale: 1.1;
    filter: grayscale(0);
}

.modal-title-box-reload.onerotation {
    animation: onerotation 400ms ease-in-out 0ms 1;
    /* transform: rotateZ(180deg); */
}

@keyframes onerotation {
    0% {
        transform: translateY(-50%) rotateZ(-90deg);
    }

    100% {
        transform: translateY(-50%) rotateZ(270deg);
    }
}

.product-ean-ncm {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    /* flex-wrap: wrap; */
}

.product-ean-container,
.product-ncm-container {
    display: flex;
    align-items: center;
    background-color: #000;
    padding: 8px 12px;
    border-radius: 8px;
    width: 50%;
    justify-content: space-between;
    font-size: 13px;
    /* margin-top: 5px; */
}

.product-ean-container {
    margin-right: 8px;
}

.product-ncm-container {
    /* margin-left: 5px; */
}

.ean-box,
.ncm-box {
    background-color: #181818;
    padding: 2px 8px;
    border-radius: 6px;
    margin-left: 6px;
    /* font-weight: 500; */
    font-size: 15px;
    width: 100%;
    text-align: center;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    cursor: pointer;
}

.ean-box:hover,
.ncm-box:hover {
    outline: 1px solid #1567d2;
}

.dimension-box {
    display: flex;
    align-items: center;
    background-color: #000;
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 5px;
    flex-wrap: wrap;
}

.dimensions:first-of-type {
    margin-left: 8px;
}

.dimensions {
    background-color: #181818;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 200ms;
    cursor: pointer;
    outline: 1px solid #1567d200;
}

.dimension-box .dimensions::after {
    font-size: 11px;
}

.dimensions:hover {
    outline: 1px solid #1567d2;
}

.dimensions strong {
    margin: 0px 3px;
}

.modal-prices {
    background-color: #0c0c0c;
    width: 90%;
    max-width: 650px;
    height: 80%;
    max-height: 276px;
    border-radius: 20px;
    scale: 0;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.443);
}

.modal-prices-bg.shown .modal-prices {
    scale: 1;
}

.modal-prices-title {
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 17px 10px 12px;
    font-size: 20px;
}

.modal-prices-section,
.modal-product-section {
    /* outline: 2px solid rgb(255, 0, 0); */
    width: 50%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.modal-prices-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0px 10px;
    box-sizing: border-box;
}

.modal-price-select {
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 8px 6px 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modal-price-select input {
    display: none;
}

.modal-price-select input:checked + span {
    background-color: #1567d2;
}

.modal-price-select span {
    display: inline-block;
    min-width: 16px;
    min-height: 16px;
    background-color: #838383;
    margin-right: 10px;
    border-radius: 50%;
}

.orizom-price-info,
.percentual-config {
    background-color: #c33c3c;
    font-size: 12px;
    padding: 0px 8px;
    border-radius: 8px;
    text-align: justify;
    box-sizing: border-box;
    /* margin-bottom: 10px; */
    /* maxheight: 0px; */
    max-height: 0px;
    transition: all 200ms;
    overflow: hidden;
    margin: 0px 6px 0px 6px;
    /* text-align: left; */
    word-wrap: break-word;
    position: relative;
}

.orizom-price-info.shown,
.percentual-config.shown {
    max-height: 500px;
    padding: 8px;
    transition: all 200ms 200ms;
}

.percent-config {
    display: flex;
    align-items: center;
}

.percentual-config {
    background-color: #050505;
    font-weight: 600;
    font-size: 14px;
}

.percentual-config span {
    color: #1567d2;
    font-weight: 700;
}

.percentual-config .percent-msg {
    color: #c93838;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    margin-left: 6px;
}

.percentual-config .percent-msg.animate {
    animation: percentmsg 100ms 2;
}

@keyframes percentmsg {
    0% {
        transform: rotateZ(-3deg);
    }

    33% {
        transform: rotateZ(0deg);
    }

    66% {
        transform: rotateZ(3deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

.percentual-config input {
    /* display: block; */
    width: 70px;
    margin: 5px 0px;
    font-size: 15px;
    border-radius: 6px;
    border: 1px solid gray;
    padding: 4px 8px;
    color: #000;
    text-align: center;
    font-weight: 500;
}

.percentual-config input:focus {
    outline: 2px solid #1567d2;
}

.percentual-config input.red:focus {
    outline: 2px solid #c93838;
}

.modal-prices-marketplaces {
    width: 100%;
    display: flex;
    /* flex-direction: row-reverse; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: -2px;
}

.modal-price-marketplace {
    background-color: #050505;
    margin: 4px;
    padding: 4px 6px;
    border-radius: 8px;
    transition: all 200ms;
    cursor: pointer;
    position: relative;
    /* overflow: hidden; */
    outline: 1px solid #1567d200;
    border-radius: 6px;
}

.modal-price-marketplace::after {
    font-size: 14px;
}

.modal-price-marketplace:hover {
    outline: 1px solid #1567d2;
}

.modal-price-marketplace img {
    height: 38px;
    margin: 0px -10px;
}

.modal-price-marketplace-price {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.modal-price-marketplace-price .roulette {
    transition-delay: 200ms;
}

.modal-price-marketplace-price .roulette-container {
    margin-left: 5px;
}

.modal-price-marketplace-price small {
    font-size: 13px;
}

.percent-save {
    color: rgb(0, 162, 0);
    position: absolute;
    right: 15px;
    bottom: 15px;
    opacity: 0;
    scale: 0.7;
    font-size: 11px;
    transition: all 200ms;
}

.percent-save.shown {
    opacity: 1;
    scale: 1;
}

.modal-title-box .loader {
    scale: 0.4;
    top: 4px;
    left: 0px;
    transition: opacity 200ms 600ms;
    opacity: 1;
    background-color: #181818;
    outline: 10px solid #181818;
}

.modal-title-box .loader.hidden {
    opacity: 0;
    transition: opacity 200ms 0ms;
}

.modal-description-box .loader {
    scale: 0.4;
    top: 6px;
    background-color: #181818;
    outline: 20px solid #181818;
}

.marketplace-explanation {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    color: #000;
    height: fit-content;
    font-size: 11px;
    font-weight: 500;
    border-radius: 6px;
    top: 100%;
    left: 40px;
    width: 120px;
    box-shadow: 1px 1px 5px 3px #00000031;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
    z-index: 999;
}

.marketplace-explanation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 15%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -7px;
    margin-top: -7px;
}

.modal-price-marketplace:hover .marketplace-explanation {
    opacity: 1;
}

.modal-options {
    background-color: #0c0c0c;
    width: 95%;
    max-width: 435px;
    height: fit-content;
    max-height: 540px;
    border-radius: 20px;
    scale: 0;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    padding: 20px;
}

.modal-options-gift {
    /* outline: 1px solid #c93838; */
    width: 100%;
    display: flex;
}

.modal-options-select {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    /* margin: 0px 10px; */
    align-items: center;
    cursor: pointer;
    position: relative;
    /* outline: 1px solid #0f0; */
    white-space: nowrap;
}

.modal-options-select::after,
.modal-options-select::before {
    content: "";
    display: inline-block;
    min-width: 16px;
    min-height: 16px;
    background-color: #838383;
    margin-right: 10px;
    border-radius: 50%;
    /* position: relative; */
}

.modal-options-select::after {
    background-color: #1567d2;
    position: absolute;
    scale: 0.1;
    opacity: 0;
}

.modal-options-select.selected::after {
    opacity: 1;
    animation: modal-options-select 300ms ease-in-out 0ms 1 normal forwards;
}

@keyframes modal-options-select {
    0% {
        scale: 0.1;
    }

    70% {
        scale: 1.3;
    }

    100% {
        scale: 1;
    }
}

.modal-options-gift-animation {
    /* outline: 1px solid #00f; */
    width: 185px;
    position: relative;
    pointer-events: none;
    margin-left: auto;
    /* scale: 0.8 */
}

.modal-options-gift-animation img {
    image-rendering: optimizeQuality;
}

.gift-box-body {
    width: 40px;
    /* background-color: #212121; */
    z-index: 99;
    /* outline: 2px solid #fff; */
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.gift-box-lid {
    width: 45px;
    /* background-color: #212121; */
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    z-index: 99;
}

.modal-options-gift-animation.animation-open .gift-box-lid {
    animation: gift-lid-open 300ms ease-in-out 800ms 1 normal forwards;
}

.modal-options-gift-product {
    height: 32px;
    /* background-color: #c93838; */
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    /* outline: 1px solid #fff; */
    filter: drop-shadow(3px 3px 2px rgb(2, 2, 2));
    transition: all 0ms 650ms;
    opacity: 0;
}

.modal-options-gift-animation.animation-open .modal-options-gift-product {
    animation: gift-product-open 400ms ease-in-out 800ms 1 normal forwards;
}

@keyframes gift-product-open {
    0% {
        bottom: 0px;
        opacity: 0;
    }

    50% {
        bottom: 20px;
        opacity: 1;
    }

    100% {
        bottom: 16px;
        opacity: 1;
    }
}

@keyframes gift-lid-open {
    0% {
        bottom: 24px;
        transform: translateX(-50%) rotateZ(0deg);
    }

    70% {
        bottom: 52px;
    }

    100% {
        bottom: 50px;
        transform: translateX(-45%) rotateZ(10deg);
    }
}

.modal-options-gift-box {
    height: 100%;
    position: absolute;
    left: 50%;
    bottom: 0px;
    margin-left: -22px;
    width: 44px;
    z-index: 99;
    transition: all 300ms 500ms ease-in-out;
}

.modal-options-gift-animation.geralgift .gift-box-lid {
    /* animation-direction: reverse; */
    animation: gift-lid-close 400ms ease-in-out 0ms 1 normal forwards;
}

.modal-options-gift-animation.geralgift .modal-options-gift-product {
    /* animation-direction: reverse; */
    animation: gift-product-close 400ms ease-in-out 0ms 1 normal forwards;
}

@keyframes gift-product-close {
    0% {
        bottom: 18px;
        opacity: 1;
    }

    50% {
        bottom: 22px;
        opacity: 1;
    }

    100% {
        bottom: 0px;
        opacity: 0;
    }
}

@keyframes gift-lid-close {
    0% {
        bottom: 50px;
        transform: translateX(-45%) rotateZ(10deg);
    }

    30% {
        bottom: 52px;
    }

    100% {
        bottom: 24px;
        transform: translateX(-50%) rotateZ(0deg);
    }
}

.gift-modal-option img {
    height: 30px;
    margin: 0px 12px;
}

.gift-modal-row {
    width: 100%;
    display: flex;
    /* align-items: center; */
    margin-top: 32px;
}

.gift-modal-option {
    font-size: 18px;
    display: flex;
    align-items: center;
    /* outline: 1px solid #c93838; */
    padding: 18px 24px;
    border-radius: 8px;
    background-color: #151515;
    width: fit-content;
}

.gift-modal-price {
    display: flex;
    align-items: flex-end;
}

.gift-modal-price small {
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.modal-options-selection {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.modal-box {
    width: fit-content;
    text-align: center;
}

.modal-box-qtty-container img {
    height: 40px;
    margin: 0px 10px;
}

.modal-box-qtty-container {
    margin: 16px auto 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* outline: 1px solid #c93838; */
    padding: 14px;
    border-radius: 8px;
    width: fit-content;
    background-color: #151515;
}

.modal-box-slot {
    background-color: #fff;
    padding: 3px;
    border-radius: 5px;
    color: #000;
}

.modal-box-slot .number-roulette {
    color: #000;
    /* outline:1px solid gray; */
}

.modal-box-plus,
.modal-box-minus {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #1567d2;
    cursor: pointer;
    transform-origin: left;
    transition: all 200ms;
    border-radius: 4px;
}

.modal-box-minus {
    top: calc(100% + 3px);
    border-bottom: none;
    border-top: 10px solid #1567d2;
}

.modal-box-qtty {
    height: 30px;
    position: relative;
}

.modal-box-plus:hover {
    scale: 1.1;
    border-bottom-color: #07408a;
}

.modal-box-minus:hover {
    scale: 1.1;
    border-top-color: #07408a;
}

.modal-box-plus.hidden {
    border-bottom-color: #323232;
}

.modal-box-minus.hidden {
    border-top-color: #323232;
}

.modal-box-plus.hidden,
.modal-box-minus.hidden {
    pointer-events: none;
}

.modal-box-x {
    margin-left: 10px;
    font-size: 22px;
}

.modal-box-value {
    display: flex;
    align-items: flex-end;
}

.modal-box-value small {
    margin-right: 5px;
    margin-bottom: 1px;
}

.select-plp-nfe .select-file-view {
    padding: 2px 16px;
    background-color: #000;
    position: absolute;
    left: 50%;
    border-radius: 6px;
    bottom: calc(100% + 4px);
    font-size: 14px;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
    outline: 1px solid #ffffff33;
    transform: translateX(-50%) translateY(20px);
    cursor: pointer;
}

.select-file-view::after {
    content: "";
    /* outline: 1px solid #f00; */
    width: 100%;
    height: calc(100% + 5px);
    position: absolute;
    top: 0px;
    left: 0px;
}

.select-plp-nfe:hover .select-file-view.shown {
    pointer-events: all;
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
}

.select-file-view:hover {
    background-color: #202020;
}

.select-plp-nfe {
    position: relative;
    margin-right: 8px;
    display: flex;
    /* Gab */
    gap: 10px;
    /* Gab */
    text-align: center;
    /* Gab */
    align-items: center;
    /* Gab */
    justify-content: space-between;
    /* Gab */
}

.select-file.checkout::after {
    content: "Alterar";
    position: absolute;
    width: 100%;
    height: 100%;
    /* outline: 1px solid #f00; */
    top: 0px;
    left: 0px;
    border-radius: 6px;
    background-color: #063777;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    opacity: 0;
    transition: all 300ms;
    pointer-events: none;
}

.select-file.checkout.selected:hover::after {
    opacity: 1;
}

.show-sold-out {
    font-size: 14px;
    float: right;
    cursor: pointer;
    color: #1567d2;
    transition: all 200ms;
}

.show-sold-out:hover {
    font-weight: 600;
}

.modal-soldout {
    max-width: 603px;
    min-height: 200px;
    max-height: 95%;
    height: fit-content;
    text-align: justify;
    padding-top: 14px;
}

.soldout-saying {
    text-align: left;
    white-space: pre-wrap;
}

.soldout-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    /* outline: 1px solid #f00; */
    top: 0px;
    left: 0px;
    background-color: #000000cd;
    border-radius: 20px;
    pointer-events: none;
    opacity: 0;
    transition: all 200ms;
}

.soldout-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.soldout-loading .loader {
    position: relative;
}

.soldout-product img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
}

.soldout-product {
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.soldout-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 20px;
}

.soldout-sku {
    /* position: absolute; */
    max-width: 100%;
    width: fit-content;
    margin: 0px auto;
    outline: 1px solid rgba(255, 255, 255, 0.265);
    margin-top: -16px;
    background-color: #000;
    padding: 2px 4px;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
}

.soldout-sku::after {
    font-size: 10px;
    line-height: 10px;
}

.modal-custom input {
    display: block;
    background-color: #181818;
    border: none;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    /* margin-top: 2px; */
    font-size: 14px;
    margin-left: 10px;
}

.modal-custom input:focus {
    outline: 1px solid #1567d2;
}

.modal-custom-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.modal-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    /* padding: 10px 20px; */
    overflow-y: auto;
}

.modal-options.modal-custom {
    padding-top: 16px;
}

.button-select-file img {
    height: 35px;
    margin: -12px -30px;
    /* filter: grayscale(1) brightness(3); */
}

span.button-select-file.full-button {
    line-height: 11px;
    white-space: pre-line;
    padding: 8px 8px;
    font-size: 12px;
}

.mktselect .button-select-file.mktselected {
    outline: 2px solid #1567d2;
    background-color: #1c1c1c !important;
}

.mktselect .button-select-file {
    min-width: 55px;
    outline: 2px solid #1567d200;
}

.marketplace-btn .marketplace-child {
    position: absolute;
    background-color: #1a1a1a;
    bottom: 0px;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #ffffff37;
    min-width: 70px;
    text-align: center;
    /* box-shadow: 4px 4px 10px #0000008f; */
    pointer-events: none;
    transition: all 400ms 0ms, left 400ms 100ms, bottom 400ms,
        opacity 400ms 200ms;
    left: 0px;

    opacity: 0;
    font-size: 14px;
}

.marketplace-btn .marketplace-child::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    min-width: 100%;
    height: 35px;
    /* outline: 1px solid #f00; */
    /* pointer-events: none; */
}

/* .marketplace-btn .marketplace-child::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1a1a1a;
    width: 65px;
    height: 5px;
    top: calc(100% - 1px);
    border: 1px solid #ffffff37;
    border-top: none;
    border-bottom: none;
}

.marketplace-btn .marketplace-child:first-of-type:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #1a1a1a;
    width: 20px;
    height: 16px;
    left: -18px;
    border: 1px solid #ffffff37;
    border-left: none;
    border-right: none;
} */

.marketplace-btn:not(:hover) .marketplace-child {
    bottom: 5px !important;
}

.marketplace-btn:hover .marketplace-child {
    transition: all 400ms, left 400ms 0ms, bottom 400ms 100ms, opacity 400ms 0ms;
    left: calc(100% + 20px);
    opacity: 1;
    pointer-events: all;
}

.marketplace-btn:hover .marketplace-child:hover {
    background-color: #3f3f3f;
}

.marketplace-btn.parent:hover::after {
    content: "";
    position: absolute;
    left: 100%;
    bottom: -5px;
    min-width: 20px;
    height: 50px;
    /* outline: 1px solid #f00; */
}

.button-select-file.mktselected img.selected-parent {
    margin-bottom: -9px;
}

.mktselect .marketplace-child-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    bottom: -7px;
    font-size: 9.5px;
    background-color: #1a1a1a;
    padding: 1px 4px !important;
    outline: 1px solid #1567d2;
    border-radius: 4px;
}

/* SOMENTE TESTE REMOVER DEPOIS */
/* .mktselect {
    z-index: 99999;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65);
}

.select-file.checkout + .marketplace-select-window {
    max-height: 1000px;
    opacity: 1;
    pointer-events: all;
    transition: all 600ms, opacity 100ms;
}

.checkout-bar-btns {
    z-index: 99999999 !important;
} */

.mktselect.hidden {
    max-width: 0px;
    overflow: hidden;
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .checkout-bar-products {
        display: none;
    }

    .checkout-bar {
        width: 100%;
        bottom: 55px;
    }

    .checkout-bar.hidden {
        bottom: -60px;
    }

    .box-container.hidden {
        bottom: -60px;
    }

    .checkout-bar-btns.hidden2 {
        bottom: -60px;
    }

    .checkout-bar.visible {
        height: 55px;
    }

    .checkout-bar .checkout-bar-options {
        padding: 0px 5px;
        transition: all 300ms;
        opacity: 0;
        width: 10%;
        /* transform: translateX(-50%) translateY(50px); */
        /* left: 18px; */
        /* top: 0px; */
        /* position: absolute; */
        position: relative;
        height: 40px;
    }

    .box-container,
    .checkout-bar-products,
    .checkout-bar-btns {
        left: 50%;
    }

    .modal-prices {
        max-height: 95vh;
    }

    .checkout-bar-btns {
        font-size: 12px;
        padding: 4px 0px;
        bottom: 65px;
        padding-right: 12px;
    }

    .checkout-bar-btns span.button-select-file {
        padding: 6px 8px;
        border-radius: 4px;
    }

    .show-sold-out {
        font-size: 15px;
        padding-bottom: 4px;
    }

    .btns-buy {
        right: auto;
        left: 6px;
        top: 5px;
    }

    .btn.btn-show-selected {
        font-size: 13px;
    }

    .total-price {
        font-size: 14px;
        border-radius: 4px;
    }

    .total-price small {
        font-size: 10px;
    }

    #logo {
        right: -24px;
        bottom: -4px;
        scale: 0.85;
    }

    .select-file.checkout::after {
        font-size: 11px;
    }

    .select-file-view {
        font-size: 12px !important;
        padding: 2px 10px !important;
        border-radius: 4px !important;
    }

    .modal-product {
        /* background-color: #0c0c0c; */
        /* width: 95%; */
        /* max-width: 850px; */
        /* height: 80%; */
        /* max-height: 530px; */
        /* min-height: 530px; */
        /* border-radius: 20px; */
        /* scale: 0; */
        /* transition: all 300ms; */
        /* box-sizing: border-box; */
        display: flex;
        /* border: 1px solid rgba(128, 128, 128, 0.443); */
        /* font-size: 18px; */
        flex-direction: column;
        max-height: 90dvh;
        height: fit-content;
        overflow: scroll;
    }

    .modal-product::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .modal-product-img {
        /* width: 92%; */
        width: 240px;
        min-height: 240px;
        height: auto;
        border-radius: 20px;
        position: relative;
        /* outline: 1px solid #c93838; */
        overflow: hidden;
    }

    .modal-product-section {
        /* outline: 2px solid rgb(255, 0, 0); */
        width: 100%;
        height: 50%;
        padding: 8px;
        box-sizing: border-box;
    }

    .modal-product-section.img-section {
        flex-direction: row;
        position: relative;
        margin-top: 10px;
        margin-bottom: 14px;
    }

    .modal-product-carrousel {
        flex-direction: column;
    }

    .modal-product-carrousel img {
        width: 30px;
        height: 30px;
    }

    .download-imgs-btn {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        padding: 2px 6px;
    }

    .modal-product-box.description-box {
        max-height: none;
    }

    .modal-product-title-marketplaces {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .modal-product-title-marketplace:first-of-type {
        margin-left: 0px;
    }

    .modal-product-title-marketplaces span {
        width: 100%;
        margin-bottom: 3px;
    }

    .ean-box,
    .ncm-box,
    .product-ean-ncm .product-ean-container {
        font-size: 12px;
    }

    .product-ean-ncm .product-ean-container,
    .product-ean-ncm .product-ncm-container {
        padding: 4px 8px;
        border-radius: 4px;
    }

    /* .modal-product-title-marketplaces {
        font-size: 14px;
    } */

    .dimensions {
        font-size: 11px;
    }

    .marketplace-btn img {
        height: 32px;
    }

    .select-file .loading {
        width: 100% !important;
        padding: 8px 2px !important;
    }

    .select-file .loading .loader {
        scale: 0.35;
    }
}

/* Gab - começo*/

/* .full-select-product-label .select-file-view,
.full-select-volume-label .select-file-view,
.full-select-entrance-auth .select-file-view {
    display: none;
    padding: 2px 16px;
    background-color: #000;
    position: absolute;
    left: 50%;
    border-radius: 6px;
    bottom: calc(100% + 4px);
    font-size: 14px;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
    outline: 1px solid #ffffff33;
    transform: translateX(-50%) translateY(20px);
    cursor: pointer;

} */

.full-select-product-label:hover .select-file-view,
.full-select-volume-label:hover .select-file-view,
.full-select-entrance-auth:hover .select-file-view {
    display: block;
}

.full-select-product-label {
    position: relative;
}

.full-select-product-label .button-select-file {
    display: block;
    text-align: center;
    font-size: 12px;
}

.full-select-volume-label {
    position: relative;
}

.full-select-volume-label .button-select-file {
    display: block;
    text-align: center;
    font-size: 12px;
}

.full-select-entrance-auth {
    position: relative;
}

.full-select-entrance-auth .button-select-file {
    display: block;
    text-align: center;
    font-size: 12px;
}

/* Gab - fim */

/* Gab - Calendário */
.react-datepicker.full-calendar {
    background-color: #1a1a1a;
    border-color: #0f0f0f;
    border-width: 3px;
    border-radius: 8px;
    outline: 1px #ffffff50 solid;
}

.react-datepicker.full-calendar .react-datepicker__day {
    color: #fff;
    transition: all 200ms;
}

.react-datepicker.full-calendar .react-datepicker__day--selected {
    background-color: #1567d2;
    font-weight: 700;
}

.react-datepicker.full-calendar .react-datepicker__day--in-range {
    background-color: #1567d2;
}

.react-datepicker.full-calendar .react-datepicker__day--range-start {
    background-color: #073c81;
}

.react-datepicker.full-calendar .react-datepicker__header {
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    border-radius: 8px 8px 0px 0px;
}

.react-datepicker.full-calendar .react-datepicker__current-month,
.react-datepicker.full-calendar .react-datepicker__day-name {
    color: #fff;
}

.react-datepicker.full-calendar .react-datepicker__day:hover {
    color: #fff;
    background-color: #424242;
    font-weight: 700;
}

.react-datepicker__day--disabled-custom {
    color: #5e5e5e !important; /* Cor cinza com !important para garantir que sobrescreva outros estilos */
    pointer-events: none; /* Previne a seleção dos dias desabilitados */
}

.react-datepicker.full-calendar .react-datepicker__day--keyboard-selected {
    background-color: #1567d2 !important;
    font-weight: 700;
}
.react-datepicker__triangle {
    fill: #0f0f0f !important;
    color: #0f0f0f !important;
}
/* .admin-dashboard-day-full {
    padding: 2px 1px;
    background-color: #1c1c1c;
    border-radius: 6px;
    cursor: pointer;
    transition: all 200ms;
    font-size: 12px;
    line-height: 11px;
    padding: 8px 20px;
    white-space: pre-line;
    font-weight: 500;
    height: 20px;
} */

/* .admin-dashboard-day-full:hover {
    background-color: #121a2c;
} */

.select-file.checkout.select-plp-nfe.selected {
    background-color: #1567d2;
    font-weight: 500;
    cursor: default;
    padding: 8px;
    border-radius: 8px;
}

/* Gab teste botao alerta */
/* .custom-swal-popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
} */

/* Personaliza o botão */
/* .custom-swal-button {
    background-color: #1567d2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.custom-swal-button:hover {
    background-color: #0056b3;
} */

/*Gab - Fim */
