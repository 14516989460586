.admin-groups-container {
    display: flex;
    margin: 40px;
    flex-wrap: wrap;
    width: calc(100% - 100px);
}

.admin-group,
.admin-group-add {
    background-color: #000;
    margin: 0px 10px 10px 0px;
    padding: 16px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    width: 100px;
    text-align: center;
}

.admin-group-edit,
.admin-group-remove {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.admin-group-edit {
    margin-top: 20px;
}

.add-group-plus {
    font-size: 50px;
    line-height: 30px;
}

.admin-group-remove {
    color: #f00;
}

.admin-group-add {
    background-color: #0c0c0c;
    cursor: pointer;
    transition: all 200ms;
}

.admin-group-add:hover {
    background-color: #222222;
}

.new-group-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000000c1;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.new-group-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.new-group-modal {
    width: 800px;
    height: 90%;
    background-color: #171717;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
}

.new-group-name {
    width: 100%;
    margin-bottom: 20px;
}

.new-group-name input {
    width: 100%;
    background-color: #272727;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    padding: 4px 10px;
    border-radius: 6px;
    color: #fff;
    box-sizing: border-box;
}

.group-products {
    height: calc(100% - 160px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px;
    /* justify-content: center; */
}

.group-product {
    background-color: #0c0c0c;
    width: 70px;
    height: 70px;
    font-size: 12px;
    margin: 0px 8px 8px 0px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.group-product.selected {
    background-color: rgb(1, 87, 1);
}

.group-product.selected.noorders {
    background-color: rgb(117, 10, 10);
}

.group-orders-number {
    position: absolute;
    right: -2px;
    top: 1px;
    background-color: inherit;
    font-weight: 600;
    padding: 1px 3px;
    border-radius: 4px;
    outline: 1px solid #ffffff67;
}

.group-sku {
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    background-color: inherit;
    padding: 2px 4px;
    border-radius: 4px;
    outline: 1px solid rgba(255, 255, 255, 0.166);
    font-weight: 500;
    font-size: 11px;
}

.group-img {
    width: 100%;
    height: 100%;
    padding: 6px;
    border-radius: 8px;
    box-sizing: border-box;
}

.save-group {
    background-color: #154385;
    float: right;
    padding: 4px 10px;
    margin: 0px 10px;
    border-radius: 6px;
    cursor: pointer;
}

.save-group:hover {
    opacity: 0.8;
}
