.admin-login-page {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    overflow-x: hidden;
}

.admin-login-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.admin-login-container input {
    color: #fff;
    font-size: 16px;
    width: 250px;
    margin-bottom: 12px;
    padding: 8px 10px;
    border-radius: 8px;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    background-color: #272727 !important;
    border: none;
    position: relative;
}

.admin-login-container input:-webkit-autofill,
.admin-login-container input:-webkit-autofill,
.admin-login-container input:-webkit-autofill:hover,
.admin-login-container input:-webkit-autofill:focus {
    border: 1px inlin #1a1a1a;
    -webkit-text-fill-color: #fff !important;
    box-shadow: 0 0 0px 1000px #272727 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.admin-login-container input:-webkit-autofill::first-line {
    /* font-family: Montserrat;
    font-size: 22px; */
}

.admin-login-container input:focus {
    outline: 1px solid #1567d2;
}

.admin-login-container label {
    margin-left: 4px;
}

.admin-login-container button,
.admin-login-container .cadastrar,
.voltar {
    text-align: center;
    font-size: 18px;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: #1567d2;
    font-weight: 600;
    border-style: none;
    margin-top: 10px;
    cursor: pointer;
    color: #fff;
    transition: all 200ms;
}

.drop-logo-login {
    width: 180px;
    margin-bottom: 20px;
}

.admin-login-container .cadastrar {
    background-color: #000;
    font-size: 16px;
}

.admin-login-container button:hover,
.admin-login-container .cadastrar:hover,
.voltar:hover {
    opacity: 0.8;
}

.forgot-password {
    text-align: center;
    font-size: 13px;
    color: #6a6a6a;
    margin-top: 8px;
    cursor: pointer;
}

.forgot-password:hover {
    font-weight: 500;
}

/* .login-einstein {
    scale: 2.2;
    margin-bottom: 200px;
    /* margin-bottom: -20px;
} */

.login-einstein {
    scale: 0.34;
    margin-bottom: -20px;
}

.admin-login-page .sheet-export-modal {
    font-size: 15px;
    width: 300px;
    position: relative;
}

.code-input {
    color: #fff;
    font-size: 16px;
    width: 150px;
    /* margin-bottom: 12px; */
    padding: 8px 10px;
    border-radius: 8px;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    background-color: #272727 !important;
    border: none;
    position: relative;
    margin-top: 12px;
    font-family: monospace;
    font-size: 20px;
    text-align: center;
    box-sizing: border-box;
}

.code-verify-btn {
    text-align: center;
    font-size: 15px;
    padding: 6px 20px;
    width: 150px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #1567d2;
    font-weight: 600;
    border-style: none;
    margin-top: 10px;
    cursor: pointer;
    color: #fff;
    transition: all 200ms;
    margin-top: 15px;
}

.code-verify-btn:hover {
    opacity: 0.8;
}

.modal-code-loading .loader {
    position: relative;
}

.modal-code-loading {
    width: 100%;
    outline: 1px solid #f00;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 200ms;
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
}

.modal-code-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.login-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    /* flex-wrap: wrap; */
}

.terms {
    color: #ffffff79;
    font-size: 13px;
    display: flex;
    width: 100vw;
    justify-content: center;
    position: fixed;
    bottom: 16px;
    padding: 0px 50px;
    box-sizing: border-box;
}

.terms-of-use {
    margin-right: 16px;
}

.terms-of-use,
.privacy-policy {
    cursor: pointer;
}

.terms-of-use:hover,
.privacy-policy:hover {
    font-weight: 600;
}

.terms-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.terms-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.terms-modal {
    max-width: 600px;
    width: 90%;
    max-height: 90%;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
    padding-right: 14px;
}

.terms-modal-container {
    width: 100%;
    height: 100%;
    color: #fff;
    white-space: pre-wrap;
    text-align: justify;
    overflow-y: auto;
    padding-right: 10px;
    box-sizing: border-box;
}

.terms-modal-bg.shown .terms-modal {
    scale: 1;
    opacity: 1;
}

.terms-modal-title {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
}

.terms-accept-read {
    color: #3b7fd9;
    cursor: pointer;
    margin-left: 6px;
}

.terms-accept-read:hover {
    font-weight: 500;
}

.terms-accept {
    font-size: 12px;
    margin: 4px 0px;
    display: flex;
    align-items: center;
}

.terms-accept-button {
    background-color: #363636;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 6px;
    height: 16px;
    width: 16px;
    position: relative;
}

.terms-accept-button-checked {
    background-color: #1567d2;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    left: 0;
    opacity: 0;
    position: absolute;
    scale: 0.1;
    top: 0;
    transition: all 0.2s;
}

.terms-accept-button-checked::after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    height: 9px;
    left: 5px;
    position: absolute;
    top: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 4px;
}

.terms-accept-button-checked.checked {
    opacity: 1;
    scale: 1;
}

button.disabled {
    background-color: #6a6a6a;
}

.login-setbling {
    max-width: 250px;
    background-color: #111111;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
    outline: 1px solid #ffffff17;
}

.login-setbling img {
    height: 20px;
    display: block;
    margin: 0px auto 2px;
}

.admin-login-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 22px;
    color: rgb(201, 11, 11);
    letter-spacing: 2px;
}
