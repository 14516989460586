.copydiv {
    position: relative;
    cursor: pointer;
  }

  .copydiv::after {
    content: 'Copiado!';
    background-color: #1567D2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    border-radius: 6px;
    pointer-events: none;
    box-sizing: border-box;
  }

  .copydiv.copied::after{
    animation: copied 1s ease-in-out 0ms 1 forwards;
    z-index: 100
  }

@keyframes copied {
  0% {width: 0px; opacity: 0}
  29% {width: 100%; opacity: 1; right: 0px; transform: translateX(0px)}
  30% {width: 100%; opacity: 1; right: 100%; transform: translateX(100%)}
  60% {width: 100%; opacity: 1; right: 100%; transform: translateX(100%)}
  100% {width: 0px; opacity: 0; right: 100%; transform: translateX(100%)}
}