.loader {
    position: absolute;
    z-index: 999999;
    scale: 1;
    left: 0px;
    top: 0px;
    margin: 2px -5px;
    pointer-events: none;
    transition: all 200ms;
}

.loader .mouth {
    display: block;
    min-width: 28px;
    min-height: 16px;
    border-radius: 50%;
    border: 10px solid white;
}

.loader .mouth-outer {
    max-width: 44px;
    max-height: 24px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: orizom-loading-mouth 900ms linear infinite;
}

.loader .eye1, .loader .eye2 {
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: 7px;
    animation: orizom-loading-eye 900ms ease-in-out infinite;
}

.loader .eye2 {
    left: auto;
    right: 7px;
    animation: orizom-loading-eye2 900ms ease-in-out infinite;
}

@keyframes orizom-loading-mouth {
    0% {transform: rotateZ(180deg)}
    100% {transform: rotateZ(0deg)}
}

@keyframes orizom-loading-eye {
    0%, 100% {transform: translateY(-10px)}
    50% {transform: translateY(0)}
}

@keyframes orizom-loading-eye2 {
    0%, 100% {transform: translateY(4px)}
    50% {transform: translateY(-10px)}
}

.loader.hidden {
    opacity: 0;
}