.shelf-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    background-color: #1a1a1a;
    /* padding: 20px; */
    padding-right: 220px;
}

.status-bar {
    position: fixed;
    right: 0;
    top: 0;
    width: 200px;
    /* Defina a largura da barra */
    height: 100vh;
    padding: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.336);
    overflow-y: auto;
    z-index: 1000;
    /* Garante que ela fique acima de outros elementos */
}

.status-bar h3 {
    margin-bottom: 10px;
    text-align: center;
}

.shelf-column {
    padding: 20px;
}

/* Estilização das seções dentro da barra lateral */
.status-section {
    background-color: #171717; /* Fundo mais escuro */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline: solid 1px rgba(255, 255, 255, 0.336);
}

.status-section h4 {
    margin-bottom: 10px;
}

.status-section p,
.status-section ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.task-list-container {
    border-radius: 5px;
    /* padding: 10px; */
    max-height: 150px; /* Limite de altura */
    overflow-y: auto; /* Adiciona rolagem quando excede o limite de altura */
}

.task-list-item {
    font-size: 14px;
    color: #fff;
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
    border-left: 4px solid;
    background-color: #2c2c2c;
    position: relative;
    cursor: pointer;
    overflow: visible;
}

.task-list-item::after {
    content: attr(data-status);
    position: absolute;
    top: 50%;
    left: 0%; /* Posição do balão */
    transform: translateY(-50%);
    background-color: #7c7b7b;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
}

.task-list-item:hover::after {
    opacity: 1;
    visibility: visible;
}

/* Estilize o texto do balão com base no status */
.task-list-item[data-status="0"]::after {
    content: "Agendado";
}
.task-list-item[data-status="1"]::after {
    content: "Em Andamento";
}
.task-list-item[data-status="2"]::after {
    content: "Concluído";
}
.task-list-item[data-status="3"]::after {
    content: "Cancelado";
}

.task-list-item:hover .cancel-icon {
    opacity: 1;
    visibility: visible;
}

.cancel-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    color: #ff5c5c; /* Cor do ícone */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    cursor: pointer;
}

.robot-status {
    padding: 8px 16px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    color: white;
}

.status-offline {
    background-color: gray;
}

.status-online {
    background-color: green;
}

.status-operating {
    background-color: blue;
}

.status-error {
    background-color: red;
}

@media (max-width: 1080px) {
    .status-bar {
        position: static;
        width: 100%;
        height: auto;
        border-left: none;
        border-top: 1px solid #ddd;
    }

    .shelf-page-container {
        padding-right: 0;
    }
}

.grid-container {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    /* Ajuste conforme necessário */
    grid-template-columns: repeat(10, 1fr);
    /* Ajuste conforme necessário */
    gap: 0;
    /* Removendo o gap entre os slots */
    border: 2px solid;
    border-color: #fff #797979 #505050 #a8a8a8;
}

.shelf-slot {
    width: 80px;
    height: 80px;
    border: 4px solid;
    border-color: #fff #797979 #505050 #a8a8a8;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 0.8em;
    color: #fff;
}

.product-box {
    width: 100%;
    height: 100%;
    background-color: #3498db;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.slot-label {
    position: absolute;
    top: 0px;
    right: 2px;
    font-size: 12px;
    color: #ffffffb3;
    /* Cor ligeiramente opaca para não distrair do conteúdo principal */
}

/* popup */

.extract-modal.arrow-controls-container {
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    height: fit-content;
    min-height: fit-content;
}

.arrows {
    position: relative;
    /* outline: 1px solid #f00; */
    height: 180px;
    width: 180px;
    /* margin-bottom: 180px; */
    /* margin-top: -160px; */
    margin-bottom: 20px;
}

.arrow {
    position: absolute;
    font-size: 40px;
    color: white;
    cursor: pointer;
    background-color: #090909;
    padding: 6px;
}

.arrow:hover {
    color: #b4b4b4;
}

.arrow-up {
    top: calc(50% - 24mm);
    left: 50%;
    transform: translateX(-50%);
}

.arrow-down {
    bottom: calc(50% - 24mm);
    left: 50%;
    transform: translateX(-50%);
}

.arrow-left {
    left: calc(50% - 24mm);
    top: 50%;
    transform: translateY(-50%);
}

.arrow-right {
    right: calc(50% - 24mm);
    top: 50%;
    transform: translateY(-50%);
}

.arrow-small {
    position: absolute;
    font-size: 20px;
    color: white;
    cursor: pointer;
    background-color: #090909;
    padding: 4px;
}

.arrow-small:hover {
    color: #b4b4b4;
}

.arrow-up-small {
    top: calc(50% - 10mm);
    left: 50%;
    transform: translateX(-50%);
}

.arrow-down-small {
    bottom: calc(50% - 10mm);
    left: 50%;
    transform: translateX(-50%);
}

.arrow-left-small {
    left: calc(50% - 10mm);
    top: 50%;
    transform: translateY(-50%);
}

.arrow-right-small {
    right: calc(50% - 10mm);
    top: 50%;
    transform: translateY(-50%);
}

/*config modal*/
.config-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.config-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.config-modal-bg.shown .config-modal {
    scale: 1;
    opacity: 1;
}

.config-modal-bg.shown .extract-modal.arrow-controls-container {
    scale: 1;
    opacity: 1;
}

.extract-modal.arrow-controls-container {
    /* width: 400px; */
    width: fit-content;
    max-width: 95%;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
    max-height: 90%;
    /* min-height: 300px; */
    /* height: 400px; */
    overflow-y: hidden;
    /* height: 500px; */
}

/* robo */

.robot-coordinates-container {
    display: flex;
    gap: 10px;
}

.robot-coordinates {
    font-size: 1em;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    /* bottom: 30px; */
    background-color: #000;
    padding: 6px 10px;
    border-radius: 5px;
}

.robot-coordinates.saved {
    background-color: #0d458d;
}

.claw-controls {
    display: flex;
    gap: 30px;
    /* margin-top: 10px; */
}

.claw-button {
    background-color: #ffffff;
    color: #000;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.claw-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* vai e vem das prateleiras */
.shelf-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.shelf-navigation .button-navigation {
    padding: 5px 10px;
    font-size: 1em;
    background-color: #555;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 0 10px;
}

.shelf-navigation .button-navigation:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.robot-positions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 4px;
}

.robot-position {
    background-color: #202020;
    padding: 4px 8px;
    min-width: 60px;
    border-radius: 4px;
    width: 60px;
    color: #fff;
    text-align: center;
    font-size: 15px;
}

.robot-position-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.edit-slot-title {
    font-size: 22px;
    margin-bottom: 20px;
}

.arrows-subtitle {
    position: absolute;
    left: 50%;
    top: 50%;
    outline: 1px solid #fff;
    width: 140px;
    height: 140px;
    transform: translateX(-50%) translateY(-50%);
}

.arrows-subtitle.mini {
    width: 50px;
    height: 50px;
}

.arrows-subtitle::after {
    content: "+1000";
    position: absolute;
    top: -18px;
    right: -5px;
}

.arrows-subtitle.mini::after {
    content: "+100";
    font-size: 12px;
    right: -20px;
    top: -16px;
}

.arrows-subtitle::before {
    content: "-1000";
    position: absolute;
    bottom: -18px;
    left: -5px;
}

.arrows-subtitle.mini::before {
    content: "-100";
    font-size: 12px;
    bottom: -16px;
    left: -20px;
}

.admin-product-tags-input-starbox {
    width: calc(100% + 10px);
    background-color: #222222;
    border: none;
    padding: 4px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;
}

.starbox-product-img {
    width: 160px;
    height: 160px;
    border-radius: 8px;
}

.starbox-product-sku {
    background-color: #000;
    padding: 4px 10px;
    font-weight: 600;
    border-radius: 6px;
    margin-top: -8px;
    outline: 1px solid #ffffff41;
}

.starbox-save-btn {
    background-color: #0d458d;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    text-align: center;
    padding: 4px 10px;
    border-radius: 6px;
    cursor: pointer;
}

.starbox-save-btn.save-modified {
    background-color: rgb(32, 155, 32);
}

.starbox-suggestions {
    position: absolute;
    overflow: auto;
    max-height: 150px;
    background-color: #000;
    padding: 4px;
    border-radius: 4px;
}

.extract-modal.arrow-controls-container {
    overflow: visible;
}

.config-loading-container {
    background-color: #000000ec;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.config-loading-container .loader {
    position: relative;
    margin: 0px;
}
