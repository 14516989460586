.dashboard-lateral-menu {
    position: fixed;
    
    right: 0px;
    top: 0px;
    width: 280px;
    height: 100%;
    box-sizing: border-box;
    margin: 0px 30px;
    overflow: hidden;
    /* outline: 1px solid #f00; */
    overflow-y: auto;
}

.dashboard-lateral-container {
    width: 100%;
    margin: 30px 0px;
    /* height: 100%; */
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #201F20;
    height: fit-content;
    /* height: 100%; */
    border-radius: 12px;
    overflow: hidden;
    /* outline: 1px solid #f00; */
}
 
.dashboard-center {
    width: calc(100% - 310px);
    padding: 30px;
    min-height: 100%;
    /* background-color: #201F20; */
    box-sizing: border-box;
    /* outline: 1px solid #f00; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-center-content {
    background-color: #201F20;
    box-sizing: border-box;
    height: 100%;
    width: 95vw;
    max-width: 900px;
    border-radius: 12px;
}

.dashboard-period-select {
    /* outline: 1px solid #f00; */
    background-color: #1A1A1A;
    display: flex;
    width: fit-content;
    margin: 16px;
    margin-left: auto;
    padding: 4px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}

.dashboard-period-option {
    margin-left: 5px;
    padding: 2px 10px;
    border-radius: 14px;
    box-sizing: border-box;
    min-width: 60px;
    text-align: center;
    transition: all 100ms;
    cursor: pointer;
    z-index: 1;
}

.dashboard-period-option:first-of-type {
    margin-left: 0px;
}

.dashboard-period-option.selected {
    background-color: #1567D2;
    transition: all 200ms 100ms;
}

.dashboard-period-ball {
    background-color: #1567D2;
    z-index: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 20%;
    width: 40px;
    height: 20px;
    transition: all 220ms ease-in-out;
    border-radius: 10px;
}

.dashboard-metrics {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px
}

.dashboard-metric {
    width: 100%;
    background-color: #262626;
    border-radius: 10px;
    margin-left: 20px;
    box-sizing: border-box;
    padding: 8px;
    cursor: pointer;
    transition: all 200ms;
}

.dashboard-metric:first-of-type {
    margin-left: 0px;
}

.dashboard-metric.selected {
    background-color: #1567D2;
    pointer-events: none    ;
}

.dashboard-metric.selected:hover {
    opacity: 1;
}

.dashboard-metric:hover {
    opacity: 0.7;
}

.dashboard-metric .metric-row {
    display: flex;
    align-items: center;
}

.dashboard-metric .metric-name {
    margin-right: auto
}

.metric-percentage svg {
    margin-right: 4px
}

.dashboard-metric .metric-value {
    float: right;
    margin-top: 14px;
    font-size: 20px;
    font-weight: 500;
}

.dashboard-metric .metric-value small {
    font-size: 14px
}

.chart {
    width: 100%;
    height: 240px;
    /* outline: 1px solid #f00; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    padding: 30px 20px 30px;
    margin: 10px 0px 10px;
    overflow: hidden;
}

.chart-column {
    background-color: #1567D2;
    /* background: linear-gradient(to bottom, #1567D2 0%,#ff000000 calc(100%)); */
    width: 1.8%;
    border-radius: 4px;
    position: relative;
    transition: all 400ms ease-in-out, background-color 200ms, outline 200ms;
    outline: 1px solid #ffffff00;
    z-index: 1;
    /* outline: 1px solid #ffffff97; */
}

.chart-column.old {
    background-color: #4e4e4e;
}

.chart-column::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 180px;
    background: linear-gradient(to bottom, #00000000 70%,#201F20 100%);
    bottom: 0px;
    left: 0px;
    border-radius: 3px;
}

.chart-column::before {
    content: '';
    position: absolute;
    width: 0px;
    height: 1px;
    background-color: #ffffff11;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 200ms, width 200ms;
    pointer-events: none;
}

.chart-column:hover::before {
    width: 2000px;
    opacity: 1;
    transition: all 200ms, width 1000ms;
}

.chart-column:hover {
    z-index: 999;
    outline: 1px solid #ffffff97;
}

.chart-scale-y {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    font-size: 12px;
    position: relative;
}

.chart-column-value {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    top: -18px;
    font-size: 12px;
    font-weight: 500;
    opacity: 0;
    pointer-events: 0;
    transition: all 100ms ease-in-out;
    z-index: 99;
}

.chart-column:hover .chart-column-value {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
}

.chart-column-date {
    position: absolute;
    bottom: -28px;
    font-size: 12px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    padding: 0px 5px;
    transition: all 200ms;
    background-color: #201F20;
    box-shadow: 0px 0px 2px 2px#201F20;
    border-radius: 6px;
    /* outline: 1px solid #f00 */
}

.chart-column-date.visible {
    opacity: 1;
}

.chart-column:hover .chart-column-date {
    opacity: 1;
}

.chart-column-date::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 8px;
    background-color: #ffffff3d;
    top: -12px;
}

.chart-column:not(.old):last-of-type {
    background-color: #94E858;
}

.chart-column:first-of-type .chart-column-date::after{
    border-left: 30px solid #201F20;
    margin-left: -15px
}

.chart-column:last-of-type .chart-column-date::after{
    border-right: 100px solid #201F20;
    margin-left: 50px
}

.chart-scale-x {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #ffffff3d;
    left: 0px;
    bottom: 24px;
    z-index: 0;
    /* opacity: 0; */
}

.chart-scale-unit {
    background-color: #fff;
    color: #000;
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 50%;
}

.chart-month-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.chart-month {
    text-align: center;
    margin-top: 20px;
    margin-bottom: -30px;
    background-color: #272727;
    padding: 2px 8px;
    border-radius: 6px;
    transition: all 200ms;
    font-size: 13px;
    color: #ffffff80
}

.dashboard-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* outline: 1px solid #f00; */
}

.dashboard-details-container {
    /* outline: 1px solid #f00; */
    display: flex;
    position: relative;;
}

.dashboard-details-title {
    font-weight: 500;
    margin: 30px 0px 20px;
    width: 100%;
    text-align: center;
}

.dashboard-details-item {
    display: flex;
    justify-content: space-between;
    /* outline: 1px solid #f00; */
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 8px;
    padding-right: 20px;
    border-bottom: 1px solid #ffffff17;
    align-items: center;
    margin-left: 20px;
    transition: all 200ms;
    background: linear-gradient(to right, #00000000 80%, #00000000 100%);
}

.dashboard-details-item:hover {
    background: linear-gradient(to right, #272727 80%, #00000000 100%);
}

.dashboard-details-item img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
}

.dashboard-details-photo {
    position: relative;
}

.dashboard-details-sku {
    position: absolute;
    background-color: #212121;
    font-size: 10px;
    font-weight: 500;
    width: fit-content;
    padding: 1px 3px;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    outline: 1px solid #ffffff2c;
}

.dashboard-details-prices {
    display: flex;
    font-size: 11px;
    background-color: #1c1b1b;
    padding: 4px 8px;
    border-radius: 8px;
    color: #ffffff7a
}

.dashboard-details-value {
    font-size: 16px;
    color: #fff
}

.dashboard-details-price:first-of-type {
    margin-right: 20px
}

.dashboard-details-price.dashboard-details-total {
    margin: 0px;
}

.dashboard-orders {
    width: 100%;
    position: relative;
    z-index: 999;
    margin-top: auto;
    margin-bottom: 8px;
    /* outline: 1px solid #f00 */
}

.dashboard-order {
    background: linear-gradient(#262626 0%, #262626 90%);
    margin: 0px 10px 6px;
    padding: 6px;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 200ms;
    color: #fff;
    z-index: 16;
}

.dashboard-order:hover {
    background: linear-gradient(to bottom, #262626 0%, #2e2e2e 90%);
}

.dashboard-order-img {
    display: flex;
}

.dashboard-order-img img {
    height: 38px;
    width: 38px;
    border-radius: 6px;
}

.dashboard-order-img {
    position: relative;
}

.dashboard-order-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
}

.dashboard-order-marketplace {
    display: flex;
    align-items: center;
}

.dashboard-order-marketplace img {
    width: 22px;
}

.dashboard-order-message {
    margin-bottom: -2px;
    margin-left: 2px;
    position: relative;
}

.dashboard-order-message img {
    height: 20px;
}

.dashboard-with-message {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
}

.dashboard-order-message.new-messages .dashboard-no-message {
    opacity: 0;
}

.dashboard-order-message.new-messages .dashboard-with-message {
    opacity: 1;
}

.dashboard-message-qtty {
    position: absolute;
    text-align: center;
    border-radius: 6px;
    background-color: #f00;
    min-height: 12px;
    min-width: 12px;
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    bottom: 3px;
    left: calc(100% - 12px);
}

.dashboard-order-price {
    font-size: 10px;
    text-align: center;
}

.dashboard-order-value {
    font-size: 12px;
}

.dashboard-order-price small {
    font-size: 9px;
}

.dashboard-order-status {
    font-size: 10px;
    padding: 8px 6px;
    border-radius: 6px;
}

.dashboard-order-sku {
    position: absolute;
    background-color: #151515;
    font-size: 8px;
    padding: 2px;
    border-radius: 2px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -3px;
    outline: 1px solid #ffffff39;
}

.dashboard-order-line {
    width: 0;
    /* outline: 1px solid #f00; */
    height: 2px;
    background: linear-gradient(to right, #00000000 5%,#1567D2 50%,#00000000 95%);
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    transition: all 300ms;
    opacity: 0;
}

.dashboard-order-more {
    outline: 1px solid #1567D2;
    /* background: linear-gradient(to right, #00000000 0%,#1567D2 50%,#00000000 100%); */
    position: absolute;
    top: 0px;
    z-index: -1;
    font-size: 10px;
    background-color: #262626;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 8px;
    border-radius: 4px;
    color: #1567D2;
    font-weight: 500;
    transition: all 200ms;
    opacity: 0;
    cursor: pointer;
}

.dashboard-orders:hover .dashboard-order-line {
    width: 100%;
    opacity: 1;
}
.dashboard-orders:hover .dashboard-order-more {
    transition: all 200ms 200ms;
    top: -12px;
    opacity: 1;
}

.pizza-container {
    min-width: 300px;
    height: 250px;
    /* outline: 1px solid #f00; */
    box-sizing: border-box;
    position: sticky;
    top: 30px;
    margin: 10px 10px 60px 0px;
    /* background-color: #232323; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.pizza {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    /* aspect-ratio: 1; */
    /* background: conic-gradient(#1567D2 0deg 172deg, red 172deg 249deg, #94E858 249deg 313deg, #4e4e4e 313deg 333deg, purple 333deg 347deg, gold 347deg 360deg, pink 360deg 360deg); */
    position: relative;
    /* overflow: hidden; */
}

.slice {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #f00;
    width: 100%;
    height: 100%;
    transition: all 400ms;
    scale: 1;
    border-radius: 50%;
}

.slice:hover {
    scale: 1.1;
}

.pizza-value {
    width: 160px;
    height: 160px;
    background-color: #151515;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 600;
}

.pizza-subtitle {
    position: absolute;
    top: calc(100% + 10px);
    font-size: 11px;
    text-align: center;
    width: 160px;
    left: 50%;
    transform: translateX(-50%);
}

.dashboard-stories-container {
    /* outline: 1px solid #f00; */
    /* height: 150px; */
    width: 100%;
    justify-content: flex-start;
    position: relative;
    pointer-events: none;
    overflow: hidden;
    min-height: 170px;
}

.dashboard-stories {
    display: flex;
    overflow-x: scroll;
    align-items: center;
    pointer-events: all;
    margin: 0px 2px;
    padding: 0px 20px;
    box-sizing: border-box;
}

.dashboard-stories::-webkit-scrollbar,
.dashboard-sold-out::-webkit-scrollbar,
.dashboard-lateral-menu::-webkit-scrollbar {
    display: none;
}

.dashboard-stories-container::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #201F20 2%, #00000000 10%, #00000000 90%, #201F20 98%);
    z-index: 10
    /* opacity: 0; */
}

.dashboard-stories img {
    height: 130px;
    width: 100px;
    object-fit: cover;
    border-radius: 8px;
    outline: 2px solid #201F20;
    z-index: 1
}

.dashboard-story {
    display: flex;
    margin: 18px 8px;
    cursor: pointer;
    position: relative;
    /* outline: 1px solid #f00; */
    /* padding: 10px; */
    scroll-margin: 80px;
    transition: all 200ms;
}

.story-background {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 138px;
    width: 108px;
    border-radius: 10px;
    background-size: 200% 200%;
    background-position: 0% 0%;
    transition: all 500ms;
    overflow: hidden;
}

.story-background::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 200%;
    height: 200%;
    background: linear-gradient(-45deg, #01193a 20%, #01193a 30%, #2f82ef 80%);
    animation: story-background 2000ms linear 0ms infinite normal;
}

@keyframes story-background {
    0% {transform: translateX(-50%) translateY(-50%) rotateZ(0deg)}
    100% {transform: translateX(-50%) translateY(-50%) rotateZ(360deg)}
}


.dashboard-story:first-of-type {
    margin-left: 0px
}

.dashboard-story:last-of-type {
    margin-right: 0px;
}

.dashboard-story:hover {
    scale: 1.05
}

.dashboard-stories-new {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    pointer-events: all;
    cursor: pointer;
}

.dashboard-stories-new::before {
    content: '';
    background-color: #201F20;
    min-width: 30px;
    min-height: 30px;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    transition: all 200ms;
}

.dashboard-stories-plus {
    background-color: #1567D2;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
    transition: all 400ms;
    /* outline: 4px solid #201F20; */
}

.dashboard-stories-new-text {
    position: absolute;
    font-size: 11px;
    width: max-content;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #201F20;
    padding: 7px 30px 7px 10px;
    line-height: normal;
    z-index: -1;
    border-radius: 14px;
    outline: 1px solid #1567D2;
    transition: all 500ms, opacity 200ms 200ms;
    max-width: 0px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0;
}

.dashboard-stories-new:hover .dashboard-stories-new-text {
    transition: all 500ms, opacity 200ms;
    max-width: 200px;
    opacity: 1;
}

.dashboard-stories-new:hover::before {
    min-width: 28px;
    min-height: 28px;
}

.dashboard-stories-new:hover .dashboard-stories-plus {
    transform: rotateZ(-180deg);
    scale: 0.95
}

.dashboard-sold-out-container {
    width: 100%;
    margin: 15px 0px 25px;
    height: fit-content;
    position: relative;
}

.dashboard-sold-out {
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow-x: scroll;
}

.dashboard-sold-out-container::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #201F20 2%, #00000000 10%, #00000000 90%, #201F20 98%);
    pointer-events: none;
}

.dashboard-sold-out-product img {
    height: 85px;
    width: 85px;
    border-radius: 8px;
    filter: grayscale(1) brightness(0.8);
    transition: all 200ms;
    opacity: 0.8;
}

.dashboard-sold-out-product:hover img {
    filter: none;
    opacity: 1;
}

.dashboard-sold-out-product {
    pointer-events: all;
    scroll-margin: 100px;
    margin: 0px 5px;
    cursor: pointer;
    transition: all 200ms;
    position: relative;
    display: flex;
    scale: 1.001;
}

.dashboard-sold-out-product:first-of-type {
    margin-left: 0px;
}

.dashboard-sold-out-product:last-of-type {
    margin-right: 0px
}

.dashboard-sold-out-product:hover {
    scale: 1.03
}

.dashboard-sold-out-sku {
    position: absolute;
    background-color: #151515;
    color: #fff;
    z-index: 99;
    font-weight: 500;
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 3px;
    top: 3px;
    left: 3px;
    outline: 1px solid #ffffff2f;

}

.dashboard-sold-out-title {
    z-index: 99;
    padding: 0px 20px;
    font-size: 13px;
    font-weight: 500;
}

.dashboard-modal-story-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.dashboard-modal-story {
    height: 90vh;
    width: calc(90vh / (16/9));
    /* outline: 1px solid #f00; */
    pointer-events: all;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    transform: translateX(2000px) translateY(-500px);
}

.dashboard-modal-story-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.dashboard-modal-story-bg.shown .dashboard-modal-story {
    scale: 1;
    opacity: 1;
    transform: translateX(0px) translateY(0px);
}

.dashboard-modal-story video {
    height: 100%;
    width: 100%;
    outline: 1px solid #f00;
    clip-path: inset(5px 5px);
    scale: 1.04;
    background-color: #383838;
}

.dashboard-modal-question {
    background-color: #0c0c0c;
    width: fit-content;
    max-width: 500px;
    height: fit-content;
    /* max-height: 300px; */
    border-radius: 18px;
    scale: 0;
    transition: all 300ms;
    box-sizing: border-box;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    padding: 20px;
}

.dashboard-modal-story-bg.shown .dashboard-modal-question {
    scale: 1;
}

.dashboard-modal-question .question {
    background-color: #262626;
    border: none;
    padding: 6px 8px;
    min-width: 400px;
    resize: none;
    height: fit-content;
    font-size: 13px;
    min-height: 62px;
    border-radius: 6px;
    outline: 1px solid #1567D200;
    transition: all 200ms;
    color: #fff;
    box-sizing: border-box;
}

.dashboard-modal-question .question::-webkit-scrollbar {
    display: none;
}

.dashboard-modal-question .question:focus {
    outline: 1px solid #1567D2;
}

.dashboard-question-buttons {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.question-container {
    position: relative;
}

.dashboard-question-send {
    background-color: #1567D2;
    width: fit-content;
    padding: 4px 8px;
    font-weight: 500;
    border-radius: 6px;
    margin-left: 12px;
    cursor: pointer;
    transition: all 200ms;
    position: relative;
    overflow: hidden;
}

.dashboard-question-send:hover {
    scale: 1.03;
    opacity: 0.8;
}

.dashboard-question-buttons .question-attach {
    height: 18px;
    transition: all 200ms;
    cursor: pointer;
}

.dashboard-question-buttons .question-attach:hover {
    animation: question-attach 300ms ease-in-out 0ms 1 normal;
    scale: 1.05;
}

@keyframes question-attach {
    0% {transform: rotateZ(0deg)}
    20% {transform: rotateZ(10deg)}
    40% {transform: rotateZ(-10deg)}
    60% {transform: rotateZ(10deg)}
    80% {transform: rotateZ(-10deg)}
    100% {transform: rotateZ(0deg)}
}



.dashboard-question-length {
    position: absolute;
    top: 100%;
    left: 0px;
    opacity: 0;
    transition: all 300ms;
    color: #1567D2;
    font-size: 13px;
}

.question-yellow {
    color: #e2b70d
}

.question-red {
    color: #f00
}

.question:focus + .dashboard-question-length {
    opacity: 1;
}

.question.question-placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    color: #ffffff62;
    white-space: pre-wrap;
    transition: all 200ms;
}

.question-placeholder.hidden {
    opacity: 0;
}

.dashboard-modal-sold-out {
    background-color: #0c0c0c;
    width: fit-content;
    min-width: 590px;
    max-width: 590px;
    height: fit-content;
    max-height: 95%;
    /* max-height: 300px; */
    border-radius: 18px;
    scale: 0;
    transition: all 300ms;
    box-sizing: border-box;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    padding: 16px 20px 20px;
}

.dashboard-modal-story-bg.shown .dashboard-modal-sold-out {
    scale: 1;
}

.dashboard-modal-sold-out-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
    overflow-y: auto;
    max-height: 350px;
    border-radius: 10px;
    /* outline: 1px solid #f00; */
}

.dashboard-modal-sold-out-product img {
    height: 120px;
    width: 120px;
    border-radius: 10px;
}

.dashboard-modal-sold-out-product {
    margin-bottom: 10px;
    margin-right: 14px;
    position: relative;
    cursor: pointer;
    display: flex;
}

.dashboard-modal-sold-out-sku {
    position: absolute;
    background-color: #151515;
    top: 4px;
    left: 4px;
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 4px;
}

.dashboard-modal-sold-out-product:hover {
    /* scale: 1.05 */
}


.dashboard-modal-sold-out-explanation {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    margin-right: 22px;
    /* outline: 1px solid #f00; */
    text-align: justify;
    white-space: pre-wrap;
}

.dashboard-modal-sold-out-explanation img {
    height: 30px;
    margin: 0px 20px 0px 6px;
    /* margin-top: 4px; */
}