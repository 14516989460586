.admin-user-type {
    padding: 8px 10px;
    background-color: #000;
    border-radius: 4px;
    min-width: 65px;
    text-align: center;
    margin-right: 10px;
}

.admin-payment {
    padding: 4px;
}

.admin-user-signed.total-value {
    background-color: #0b3368;
}

.admin-payment-selected {
    margin-left: 10px;
    font-size: 16px;
}

.admin-payment-top .admin-user-signed {
    font-size: 12px;
}

.admin-payment-top .admin-signed-date {
    font-size: 14px;
}

.admin-user-type.cad,
.admin-user-type.ren {
    font-size: 12px;
    font-weight: 500;
    background-color: #363636;
}

.admin-user-type.cad {
    background-color: rgb(20, 99, 13);
}
