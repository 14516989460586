* {
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    user-select: none;
    text-decoration: none;
}

#root {
    width: 100%;
}

.App {
    display: flex;
    background-color: #1a1a1a;
    width: 100%;
    /* overflow-x: hidden; */
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1a1a1a;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
    margin-left: 200px;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-top: 60px;
    transition: all 300ms;
}

.auth-loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-loader .loader {
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #262626;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1567d29e;
}

@media screen and (max-width: 505px) {
    .align-center {
        width: 338px !important;
    }

    .checkout-bar-options.align-center,
    input.align-center {
        width: 320px !important;
    }
}
@media screen and (min-width: 505px) and (max-width: 706px) {
    .align-center {
        width: 456px !important;
    }
}
@media screen and (min-width: 706px) and (max-width: 934px) {
    .align-center {
        width: 456px !important;
    }
}
@media screen and (min-width: 934px) and (max-width: 1162px) {
    .align-center {
        width: 684px !important;
    }
}
@media screen and (min-width: 1162px) and (max-width: 1390px) {
    .align-center {
        width: 912px !important;
    }
}
@media screen and (min-width: 1390px) {
    .align-center {
        width: 1140px !important;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        margin-left: 0px;
        min-height: 100dvh;
        padding-top: 40px;
        /* padding-top: 90px; */
    }

    .content .product-center {
        left: 0px;
        width: 100%;
        overflow: hidden;
    }
}
