.asked-products-container {
    padding: 30px;
    font-size: 20px;
    font-weight: 500;
}

.asked-products {
    margin-top: 30px;
    background-color: #111111;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 120px);
    align-items: flex-start;
    justify-content: center;
}

.asked-product {
    position: relative;
    border-radius: 8px;
    background-color: #6200ff;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 26px;
    animation: asked-products 500ms ease-in-out 0ms 1 normal forwards;
    height: fit-content;
}

.asked-product-order.asked {
    background-color: #6200ff;
}

@keyframes asked-products {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

.asked-product img {
    height: 150px;
    width: 150px;
    border-radius: 8px;
    cursor: pointer;
}

.asked-product-sku {
    position: absolute;
    background-color: inherit;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    padding: 4px 10px;
    border-radius: 8px;
    font-size: 18px;
}

.asked-product-confirm {
    /* position: absolute; */
    background-color: #1451a1;
    padding: 4px 0px;
    border-radius: 8px;
    /* left: 50%; */
    /* bottom: 8px; */
    /* margin-top: -5px; */
    /* transform: translateX(-50%); */
    /* box-shadow: 0 0 10px 5px #000000cf; */
    cursor: pointer;
    opacity: 1;
    transition: all 200ms;
    font-size: 14px;
    text-align: center;
    margin-top: 6px;
}

.asked-product-confirm:hover {
    background-color: gray;
}

.asked-product-confirm.burn {
    background-color: #d6781a;
}
