.asked-product-burn {
    position: relative;
    border-radius: 8px;
    background-color: #d6781a;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 26px;
    animation: asked-products 500ms ease-in-out 0ms 1 normal forwards;
    height: fit-content;
}

@keyframes asked-products {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

.asked-product-order {
    position: absolute;
    background-color: #d6781a;
    top: 2px;
    right: 2px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 0px 0px 0px 6px;
}

.asked-product-burn img {
    min-height: 150px;
    min-width: 150px;
    width: 150px;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
}

.asked-product-photos-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.asked-product-photos-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.asked-product-photos {
    background-color: #0c0c0c;
    color: #fff;
    border-radius: 20px;
    scale: 0.1;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 16px;
    /* transform: translateY(1000px); */
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.asked-product-photos-bg.shown .asked-product-photos {
    scale: 1;
}

.asked-product-modal-photo {
    height: 500px;
    width: 500px;
    border-radius: 10px;
}

.asked-photo-next,
.asked-photo-previous {
    position: absolute;
    border: solid #1567d2;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 15px;
    transform: rotate(-45deg);
    top: 50%;
    right: 35px;
    box-shadow: 4px 4px 5px #000000ca;
    cursor: pointer;
}

.asked-photo-previous {
    width: fit-content;
    transform: rotate(135deg);
    left: 35px;
}

.asked-product-name {
    position: absolute;
    background-color: #f00;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 600;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 4px;
}

.asked-product-name span {
    font-weight: 400;
    font-size: 12px;
}
