:root {
    --admin-box-light: #f2c991;
    --admin-box-mid: #e6b072;
    --admin-box-inner-mid: #d4a168;
    --admin-box-dark: #d4a168;
    --admin-box-inner-dark: #ba8b5f;
    --admin-box-inner-darkest: #a57345;
}

.admin-box {
    border: 5px solid #fff;
    border-color: #fff #797979 #505050 #a8a8a8;
    --size: 100px;
    width: 70px;
    height: 70px;

    overflow: visible;
    /* padding: 1rem; */
    /* background: #eeeeee61; */
    /* border-radius: 0.5rem; */
    perspective: 30rem;
    color: #fff;
    /* pointer-events: none; */
    /* transform: translateX(150px); */
    transition: all 100ms, box-shadow 300ms;
    z-index: 9999;
}

.admin-box:hover {
    z-index: 999999;
}

.checkout-bar.visible .admin-box {
    transform: translateX(0px);
}

.admin-box .box {
    position: relative;
    width: 100px;
    height: 40px;
    transform-style: preserve-3d;
    transform: translateZ(-20rem);
    font-size: 28px;
    text-align: center;
    transition: 200ms;
    cursor: pointer;
    margin-left: -15px;
    margin-top: -10px;
}

.admin-box:hover .box {
    transform: translateZ(-10rem) rotatex(-25deg) rotatey(45deg);
}

.admin-box:hover {
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.75);
}

.box-container.visible .admin-box .box {
    pointer-events: all;
}

.admin-box .box > * {
    position: absolute;
    background: #0008;
    transition: 0.4s;
}

.admin-box .box .side.front,
.admin-box .box .side.left,
.admin-box .box .side.back,
.admin-box .box .side.right {
    width: var(--size);
    height: calc(var(--size) * 0.7);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(0.0000001deg)
        translateZ(calc(var(--size) * 0.499));
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-rendering: geometricPrecision;
    outline: 1px solid #00000038;
}

.admin-box .box .side.front {
    background: var(--admin-box-mid);
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 22px;
    border: 9px solid #292929; */
    /* transition: 0.4s, border 500ms 500ms, margin 500ms 500ms; */
    /* margin: -9px; */
    /* transform: translateY(calc(var(--size) * 0.25)) rotateY(0deg) translateZ(0); */
    /*   width: 250px */
}

.admin-box .box .side.right {
    background: var(--admin-box-inner-mid);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(90deg)
        translateZ(calc(var(--size) * 0.499));
}

.admin-box .box .side.back {
    background: var(--admin-box-inner-dark);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(180deg)
        translateZ(calc(var(--size) * 0.499));
}

.admin-box .box .side.left {
    background: var(--admin-box-dark);
    transform: translateY(calc(var(--size) * 0.25)) rotateY(-90deg)
        translateZ(calc(var(--size) * 0.499));
}

.admin-box .box .side.bottom {
    width: var(--size);
    height: var(--size);
    transform: translateY(10px) rotateX(90deg)
        translateZ(calc(var(--size) * -0.195));
    background: var(--admin-box-inner-darkest);
}

.admin-box .box .flap {
    background: var(--admin-box-light);
}

.admin-box .box .flap.front,
.admin-box .box .flap.back {
    width: var(--size);
    height: calc(var(--size) * 0.5);
}

.admin-box .box .flap.left,
.admin-box .box .flap.right {
    width: calc(var(--size) * 0.5);
    height: var(--size);
}

.admin-box .box .flap.front {
    transform-origin: 50% 100% calc(var(--size) * 0.5);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        translateZ(calc(var(--size) * 0.499));
}

.admin-box .box .flap.back {
    transform-origin: 50% 100% calc(var(--size) * -0.5);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(-90deg)
        translateZ(calc(var(--size) * -0.499));
}

.admin-box .box .flap.left {
    transform-origin: 0 50% 0;
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        rotateY(0deg);
}

.admin-box .box .flap.right {
    transform-origin: 0 50% 0;
    transform: translateX(var(--size)) translateY(calc(var(--size) * -0.25))
        rotateX(90deg) rotateY(180deg);
}

.admin-box:not(:hover) .box .flap.left,
.admin-box:not(:hover) .box .flap.right {
    transition-delay: 0.2s;
}

.admin-box:hover .box .flap.front,
.admin-box:hover .box .flap.back {
    transition-delay: 0.2s;
}

.admin-box:hover .box .flap.front {
    background: var(--admin-box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(-120deg)
        translateZ(calc(var(--size) * 0.499));
}

.admin-box:hover .box .flap.back {
    background: var(--admin-box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(120deg)
        translateZ(calc(var(--size) * -0.499));
}

.admin-box:hover .box .flap.left {
    background: var(--admin-box-inner-mid);
    transform: translateY(calc(var(--size) * -0.25)) rotateX(90deg)
        rotateY(-210deg);
}

.admin-box:hover .box .flap.right {
    background: var(--admin-box-inner-mid);
    transform: translateX(var(--size)) translateY(calc(var(--size) * -0.25))
        rotateX(90deg) rotateY(390deg);
}

.admin-box .side-label {
    font-size: 16px;
    color: #000;
    background-color: #fff;
    font-weight: 800;
    padding: 4px;
    border-radius: 4px;
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: column;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-font-smoothing: subpixel-antialiased;
}

.admin-box .side-label img {
    height: 8px;
}

.admin-box-product {
    position: absolute;
    left: 50%;
    transform: translateZ(40px) rotatey(-45deg) rotateX(25deg) translateX(-55px);
    top: calc(var(--size) / 4);
    width: calc(var(--size) / 1.2);
    opacity: 0;
    /* transition: all 200ms, opacity 100ms !important; */
    outline: 4px solid #00000033;
    border-radius: 6px;
}

.admin-box:hover .admin-box-product {
    top: -60px;
    opacity: 1;
    /* transition: all 200ms 100ms; */
}

.slot-buttons {
    position: absolute;
    left: 40%;
    width: max-content;
    /* background-color: #000; */
    opacity: 0;
    pointer-events: none;
    top: -4px;
    min-height: 70px;
    transition: all 200ms;
}

.slot-buttons.hasproduct {
    top: -34px;
    min-height: 100px;
}

/* .admin-box:first-of-type {
    z-index: 999999;
} */

/* .admin-box:first-of-type .slot-buttons, */
.admin-box:hover .slot-buttons {
    opacity: 1;
    pointer-events: all;
    left: 100%;
}

.slot-btn {
    background-color: #000;
    width: fit-content;
    padding: 3px 8px;
    border-radius: 4px;
    outline: 1px solid #ffffff57;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    cursor: pointer;
}

.slot-btn:hover {
    background-color: #272727;
}

.box-add-product {
    align-self: center;
    justify-self: center;
    height: 100%;
    line-height: 70px;
    font-size: 50px;
    opacity: 0;
    transition: all 200ms;
    cursor: pointer;
}

.admin-box:hover .box-add-product {
    opacity: 0.8;
}

.box-add-product:hover {
    font-size: 60px;
}

.mini-box-qtty {
    background-color: #1567d2;
    position: absolute;
    top: 1px;
    left: 1px;
    font-size: 12px;
    min-width: 16px;
    line-height: 16px;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    padding: 0px 4px;
}

.mini-box-qtty.empty {
    background-color: #f00;
}

.slot-btn.qtty-slot {
    /* background-color: transparent;
    border: none; */
    /* outline: none; */
    pointer-events: none;
}

.slot-btn.qtty-slot:hover {
    background-color: #000;
}

.qtty-slot-btn {
    background-color: #f00;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
    pointer-events: all;
}

.qtty-slot-btn.plus {
    background-color: #1567d2;
}

.qtty-slot-btn:hover {
    opacity: 0.8;
}

.qtty-slot-numer {
    margin: 0px 10px;
    padding: 0px;
}
