.welcome {
    /* min-height: 100vh; */
    /* width: 100%; */
    /* display: flex; */
    /* justify-content: center; */
    /* position: relative; */
    /* position: fixed; */
    /* width: 100%; */
    /* max-width: 1000px; */
    /* max-height: 90vh; */
    /* left: 50%; */
    /* top: 50%; */
    /* transform: translateX(-50%) translateY(-50%); */
    /* z-index: 999999; */
    /* overflow-y: scroll; */
    position: relative;
    color: #fff;

    /* width: 200px; */
    max-height: 0px;
    transition: all 1000ms;
    overflow: hidden;
    margin: 0px;
    width: 95vw;
    max-width: 0px;
    opacity: 0;
}

.aistem-mini.transformed2 .welcome {
    max-height: 95dvh;
    max-width: 95vw;
    opacity: 1;
    margin-top: 10px;
}

.einstein-dialog {
    width: 100%;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    /* max-height: 85vh; */
    /* overflow-y: auto; */
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    position: relative;
    /* box-sizing: border-box; */
    overflow-y: auto;
    background-color: #000;
    border-radius: 20px;
    /* box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.85); */
    /* height: 5000px; */
    /* outline: 1px solid #ffffff47; */
    max-height: calc(90dvh - 130px);
}

.einstein-dialog-row {
    display: flex;
    align-items: flex-start;
    padding-top: 0px;
    transition: all 400ms;
    overflow: hidden;
    /* max-height: 0px; */
    /* opacity: 0; */
    width: 95%;
    max-width: 900px;
    flex-shrink: 0;
    /* transform: translateY(20px); */
    opacity: 0;
    /* transition-delay: 0ms !important; */
}

.aistem-mini:not(.transformed2) .einstein-dialog-row {
    transition-delay: 0ms !important;
}

/* .aistem-mini.transformed2 .einstein-dialog-row {
    transform: translateY(0px);
    opacity: 1;
} */

/* .einstein-dialog-row:first-of-type {
    margin-bottom: 70px;
} */

.aistem-mini.transformed2 .einstein-dialog-row.shown {
    /* max-height: 1000px; */
    /* transform: translateY(0px); */
    /* padding-bottom: 12px; */
    opacity: 1;
}

.welcome-einsten {
    scale: 0.35;
    width: 120px;
    margin: -30px;
}

.einstein-dialog-row-text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
    margin-left: 10px;
    background-color: #121212;
    border-radius: 12px;
    padding: 10px 16px;
    min-width: 10px;
    min-height: 24px;
    position: relative;
    padding-right: 50px;
}

.einstein-dialog-row-text span {
    white-space: pre-wrap;
}

.einstein-button {
    background-color: #303030;
    color: #7b7b7b;
    padding: 2px 10px;
    border-radius: 6px;
    width: fit-content;
    transition: all 200ms;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    max-height: 0px;
    margin: 0px;
    white-space: nowrap;
}

.einstein-button.shown.clickable {
    background-color: #1567d2;
    color: #fff;
    pointer-events: all;
}

.einstein-button.shown {
    opacity: 1;
    max-height: 100px;
    margin: 12px 10px 0px 0px;
}

.einstein-button:hover {
    opacity: 0.8;
}

.einstein-dialog-row-text span::after {
    display: none;
}

.einstein-dialog-row.dialog-user {
    justify-content: flex-start;
    flex-direction: row-reverse;
    text-align: left;
}

.einstein-dialog-row img {
    height: 48px;
    width: 48px;
    min-width: 48px;
    /* outline: 1px solid #fff; */
    border-radius: 50%;
    /* margin-top: 8px; */
    margin: 8px 2px 2px 20px;
    opacity: 1;
    object-fit: cover;
}

.einstein-message-input {
    position: relative;
    width: calc(100% - 20px);
}

.einstein-chat-input {
    /* position: absolute; */
    width: 100%;
    z-index: 99999;
    /* left: 0px; */
    /* bottom: 0px; */
    padding: 26px 0px 16px;
    display: flex;
    justify-content: center;
    background-color: #000;
    border-radius: 20px;
    border-radius: 30px;
}

.einstein-chat-input input {
    /* width: calc(95vw - 200px); */
    width: calc(100% - 20px);
    /* max-width: 880px; */
    margin-left: 10px;
    background-color: #171717;
    border: none;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    font-size: 18px;
    color: #fff;
    padding: 8px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    opacity: 0.3;
    pointer-events: none;
    padding-right: 46px;
}

.einstein-chat-input.enabled input {
    opacity: 1;
    pointer-events: all;
}

.einstein-chat-input input:focus {
    outline: 1px solid #1567d2;
}

.einstein-dialog-buttons,
.einstein-dialog-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
}

.message-product-photo img {
    border-radius: 6px;
    width: 80px;
    height: 80px;
    margin: 0;
}

.message-product-photo {
    position: relative;
    margin-right: 10px;
    scale: 0.1;
    transition: all 200ms 200ms;
    opacity: 0;
    margin-bottom: 4px;
}

.message-product-photo .message-product-sku {
    outline: 1px solid #ffffff3e;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-size: 12px;
    background-color: #000;
    padding: 1px 6px;
    z-index: 99;
    border-radius: 4px;
    line-height: 16px;
}

.message-product-photos {
    display: flex;
    max-height: 0px;
    overflow: hidden;
    flex-wrap: wrap;
    opacity: 0;
    transition: all 200ms;
}

.message-product-photos.shown {
    margin-top: 10px;
    max-height: 200px;
    opacity: 1;
}

.message-product-photos.shown .message-product-photo {
    scale: 1;
    opacity: 1;
}

.message-time {
    position: absolute;
    right: 8px;
    bottom: 0px;
    font-size: 12px;
    opacity: 0.5;
}

.message-date-separator {
    margin: 10px 0px;
    opacity: 0.5;
    font-size: 14px;
    border-bottom: 1px solid #ffffff19;
    width: calc(100% - 300px);
    max-width: 800px;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 2px;
}

.einstein-dialog-row.shown.dialog-user .einstein-dialog-row-text {
    background-color: #072549;
}

.einstein-dialog-images {
    margin-top: 0px;
    overflow: hidden;
    opacity: 0;
    max-height: 0px;
    transition: all 200ms;
    display: flex;
    flex-wrap: wrap;
}

.einstein-dialog-images.shown {
    margin-top: 16px;
    max-height: 300px;
    opacity: 1;
}

.einstein-dialog-images img {
    max-height: 200px;
    height: auto;
    width: auto;
    border-radius: 8px;
    margin: 0px;
    padding: 0px;
}

.einstein-image-overlay {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    font-size: 18px;
    transition: all 200ms;
    opacity: 0;
    cursor: pointer;
}

.einstein-image:hover .einstein-image-overlay {
    opacity: 1;
}

.einstein-image {
    position: relative;
    width: fit-content;
    margin-right: 10px;
    display: flex;
    margin-bottom: 10px;
    height: fit-content;
}

.aistem-send {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #424242;
    border-radius: 5px;
    padding: 4px;
    opacity: 0.7;
    transition: all 200ms;
}

.aistem-send.active {
    opacity: 1;
    background-color: #1567d2;
}

@media only screen and (max-width: 768px) {
    .einstein-dialog {
        max-height: calc(85dvh - 120px);
    }

    .einstein-dialog-row-text {
        font-size: 13px;
        padding: 6px 12px 16px 12px;
        border-radius: 8px;
        word-break: break-word;
    }
    .message-time {
        font-size: 9px;
        bottom: -2px;
    }
    .einstein-message-input input {
        font-size: 14px;
    }

    .einstein-dialog-row .einstein {
        scale: 0.3;
        margin: -30px -50px -30px -40px;
    }

    .einstein-dialog-row img {
        /* outline: 1px solid #f00; */
        min-width: 38px;
        width: 38px;
        height: 38px;
        margin-left: 10px;
        margin-top: 10px;
    }
}
