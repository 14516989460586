.admin-product-stock {
    background-color: #000;
    padding: 4px 8px;
    text-align: center;
    font-weight: 500;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-left: 20px;
}

.admin-product-stock div {
    font-size: 10px;
    font-weight: 400;
}

.admin-product-stock:hover::after {
    opacity: 1;
}

/* .admin-product-stock::after {
    content: "Alterar";
    background-color: rgba(80, 25, 25, 0.831);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    opacity: 0;
    transition: all 200ms;
} */

.admin-product-img img {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: #fff;
}

.admin-product-list .renderIfVisible {
    width: 98%;
    max-width: 900px;
    margin-bottom: 10px;
    z-index: 1;
}

.admin-product {
    box-sizing: border-box;
    background-color: #262626;
    /* outline: 1px solid #f00; */
    padding: 5px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 600px;
    text-align: center;
    transition: all 200ms, z-index 0ms 100ms;
    animation: admin-product 300ms ease-in-out 200ms 1 normal forwards;
    transform: translateY(100px);
    opacity: 0;
}

.admin-product.errored {
    animation: admin-product 300ms ease-in-out 200ms 1 normal forwards, admin-product-error 1s infinite;
}

.admin-product.errored2 {
    animation: admin-product 300ms ease-in-out 200ms 1 normal forwards, admin-product-error2 1s infinite;
}

@keyframes admin-product-error {
    0%,
    100% {
        background-color: #262626;
    }
    50% {
        background-color: rgb(116, 6, 6);
    }
}

@keyframes admin-product-error2 {
    0%,
    100% {
        background-color: #262626;
    }
    50% {
        background-color: rgb(53, 0, 176);
    }
}

.admin-product:hover {
    z-index: 999;
    transition: all 200ms;
}

.admin-product.sold-out {
    background-color: #361e1e;
}

.admin-product.sold-out:hover {
    filter: none;
}

@keyframes admin-product {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    50% {
        transform: translateY(-10px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.admin-product-sku {
    /* outline: 1px solid #f00 */
    padding: 2px 4px;
    background-color: #1a1a1a;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #ffffff51;
    min-width: 35px;
}

.admin-product-img {
    position: relative;
}

.admin-product-sku::after {
    border-radius: 0px;
    font-size: 9px;
}

.admin-product-img .admin-product-img-large {
    position: absolute;
    height: 200px;
    width: 200px;
    z-index: 99999;
    left: calc(100% + 5px);
    top: -20px;
    border: 4px solid #262626;
    box-sizing: border-box;
    border-radius: 14px;
    transform: translateX(-40px);
    opacity: 0;
    transition: all 300ms;
    pointer-events: none;
    box-shadow: 2px 2px 1px 1px #00000022;
}

.admin-product-img:hover .admin-product-img-large {
    transform: translateX(0px);
    opacity: 1;
}

.admin-product-check {
    min-width: 24px;
    min-height: 24px;
    border-radius: 4px;
    margin-right: 14px;
    background-color: #363636;
    position: relative;
    cursor: pointer;
}

.admin-product-checked {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #1567d2;
    border-radius: 4px;
    opacity: 0;
    scale: 0.1;
    transition: all 200ms;
}

.admin-product-checked.checked {
    opacity: 1;
    scale: 1;
}

.admin-product-checked::after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.admin-product-list {
    width: calc(100% - 40px);
    min-height: calc(100vh + 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    background-color: none;
}

.admin-product-btns {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: auto;
    padding: 0px 20px;
}

.admin-product-btn {
    background-color: #000;
    padding: 2px 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms;
}

.admin-product-btn:hover {
    background-color: #2c2c2c;
}

.admin-product-btn:last-of-type {
    margin: 0px;
}

.admin-product-list .trash {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
    /* filter:invert() */
}

.admin-product-list .trash img {
    width: 20px;
    height: min-content;
    transition: all 200ms;
    border-radius: 0px;
}

.admin-product-list .trash:hover img:first-of-type {
    transform: rotateZ(30deg) translateY(-5px);
}

.admin-modal-confirm-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.admin-modal-confirm-btn {
    background-color: rgb(162, 0, 0);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 6px;
    transition: all 200ms;
    cursor: pointer;
}

.admin-modal-confirm-btn:hover {
    opacity: 0.8;
}

.admin-modal-confirm-btn.btn-yes {
    background-color: rgb(0, 143, 0);
}

.admin-confirm-modal {
    width: 320px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    transform: translateX(-1000px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
}

.admin-modal-bg.shown > div {
    scale: 1;
    opacity: 1;
    transform: translateX(0px) translateY(0px);
}

.admin-add-modal {
    width: 700px;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
    max-height: 90vh;
    overflow-y: hidden;
}

.admin-modal-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.admin-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.admin-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-product-names {
    width: 400px;
    font-size: 12px;
    /* padding: 0px 20px; */
    margin-left: 20px;
}

.admin-product-name {
    white-space: nowrap;
    overflow-x: auto;
}

.admin-product-tags-input {
    background-color: #161616;
    border: none;
    color: #fff;
    transition: all 200ms;
    outline: 1px solid #1567d200;
    padding: 2px 10px;
    /* width: 100%; */
    border-radius: 4px;
    width: fit-content;
    width: 60px;
    margin-bottom: 4px;
    margin-left: 2px;
}

.admin-product-tags-input:focus {
    outline: 1px solid #1567d2;
}

.admin-tag-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
    padding: 4px;
    padding-bottom: 0px;
}

.admin-product-tags {
    background-color: #161616;
    border-radius: 6px;
    margin: 2px;
}

.admin-product-tag {
    margin-right: 3px;
    background-color: #000;
    padding: 2px 4px;
    border-radius: 4px;
    margin-bottom: 4px;
}

.admin-tag-remove {
    background-color: #00000000;
    color: rgb(175, 0, 0);
    scale: 1.6;
    border: 0px;
    padding: 0px 2px 0px 4px;
    cursor: pointer;
}

.admin-product-form input,
.admin-product-form textarea {
    background-color: #161616;
    border: none;
    color: #fff;
    transition: all 200ms;
    outline: 1px solid #1567d200;
    padding: 4px 10px;
    /* width: 100%; */
    border-radius: 4px;
    width: fit-content;
    margin-top: 4px;
}

.admin-product-form textarea {
    width: 500px;
    height: 100px;
}

.admin-product-form input:focus {
    outline: 1px solid #1567d2;
}

.admin-product-form {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    overflow-y: auto;
    padding: 2px;
    margin-bottom: 40px;
}

.admin-product-form .product-data-section {
    padding: 4px;
    position: relative;
}

.admin-product-photo img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    /* pointer-events: none; */
    /* -webkit-user-drag: none; */
}

.admin-photos-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    position: relative;
    transition: all 200ms;
    /* outline: 1px solid #0f0; */
}

.photos-loading {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    /* outline: 1px solid #f00; */
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: -2px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
}

.photos-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.photos-loading .loader {
    position: relative;
}

.admin-photos-container .grabbable {
    max-width: 100px;
}

.admin-product-photo {
    display: inline-block;
    padding: 5px;
    border-radius: 8px;
    background-color: #1e1e1e;
    text-align: center;
    margin: 10px;
    margin-left: 0px;
    width: fit-content;
    transition: all 200ms;
    margin-bottom: 0px;
}

.admin-product-photo .grabbable {
    width: fit-content;
}

.product-photos-container {
    margin-bottom: 10px;
}

.product-photo-remove-btn {
    color: #f00;
    margin-top: 10px;
    cursor: pointer;
}

input.product-save-btn {
    font-size: 16px;
    margin: 10px;
    padding: 4px 20px;
    background-color: #1567d2;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    transition: all 200ms;
}

input.product-save-btn:hover {
    opacity: 0.7;
}

.customfield {
    display: block;
    margin: 10px;
}

.customfield input {
    display: block;
}

.customfield-container {
    display: flex;
    flex-wrap: wrap;
}

.admin-modal-loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-modal-loading .loader {
    position: relative;
    scale: 1.6;
}

label.admin-product-photo input {
    display: none;
}

label.admin-product-photo {
    height: 110px;
    width: 80px;
    cursor: pointer;
    transition: all 200ms;
}

label.admin-product-photo:hover {
    background-color: #082f61;
}

label.admin-product-photo span {
    font-size: 80px;
    display: block;
    line-height: 65px;
}

label.admin-product-photo.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.product-add-btn-container {
    position: absolute;
    left: 50%;
    top: 56px;
    transform: translateX(-50%);
    width: calc(100% - 60px);
    max-width: 900px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    pointer-events: none;
}

.product-add-btn {
    background-color: #1567d2;
    padding: 4px 10px;
    border-radius: 8px;
    margin-right: 10px;
    pointer-events: all;
    cursor: pointer;
}

.product-add-btn:hover {
    opacity: 0.8;
}

.product-box-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
}

.product-box-option {
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0px 10px 10px 0px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
}

.product-box-option img {
    width: 50px;
    border-radius: 8px;
    margin-bottom: 4px;
}

.product-box-option.selected {
    background-color: rgb(2, 75, 2);
}

.renderteste:hover {
    z-index: 99999999;
}

.admin-product-list .renderIfVisible:hover {
    z-index: 999;
}

.admin-title-preview {
    background-color: #161616;
    width: 600px;
    min-height: 30px;
    margin: 10px 0px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
}

.admin-gen-titles {
    background-color: #1567d2;
    padding: 2px 8px;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
}

.admin-gen-titles:hover {
    opacity: 0.8;
}

.admin-titles-loading .loader {
    position: relative !important;
}

.admin-titles-loading {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #00000099;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.admin-titles-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-title-preview .admin-title-option span {
    display: inline-block;
    background-color: #000;
    padding: 4px 8px;
    margin-bottom: 4px;
    border-radius: 6px;
}

.admin-title-preview .admin-title-option span:first-of-type {
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    min-width: 18px;
    line-height: 18px;
    text-align: center;
    padding: 0;
    font-weight: 600;
    margin-right: 4px;
}

.admin-title-preview .admin-title-option span::after {
    display: none;
}

.product-data-section .admin-product-tags,
.base-title {
    box-sizing: border-box;
    width: 600px !important;
}

.admin-title-preview .admin-title-option .admin-title-length {
    display: inline-block;
    border-radius: 50%;
    margin-left: 4px;
    font-size: 11px;
    min-width: 20px;
    line-height: 22px;
    text-align: center;
    padding: 0px 2px;
}

.admin-title-preview .admin-title-option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.base-title-length {
    position: absolute;
    right: 4px;
    bottom: 2px;
    font-size: 11px;
    color: #1567d2;
    font-weight: 500;
    opacity: 0;
    transition: all 200ms;
}

input:focus + .base-title-length {
    opacity: 1;
}

.base-title-container {
    width: fit-content;
    position: relative;
}

.base-title-container .base-title {
    padding: 10px 10px;
}

.description-section textarea {
    max-width: 600px;
    min-width: 600px;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 150px;
    transition: all 0ms, outline 200ms;
    outline: 1px solid #1567d200;
}

.description-section textarea:focus {
    outline: 1px solid #1567d2;
}

.description-section textarea::-webkit-resizer {
    /* border: 2px solid yellow; */
    /* background: #1567d2; */
    background-color: #000;
    border: 4px solid #1567d2;
    border-top: none;
    border-left: none;
}

.customfield .slider {
    background-color: #1d1d1d;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.customfield .slider::before {
    background-color: #b1b1b1;
    border-radius: 50%;
    bottom: 4px;
    content: "";
    height: 16px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 16px;
}

.customfield .switch.checked .slider::before {
    background-color: #fff;
    -webkit-transform: translateX(22px);
    transform: translateX(22px);
}

.customfield .switch.checked .slider {
    background-color: #1567d2;
}

.customfield .switch {
    display: block;
    height: 24px;
    margin-top: 4px;
    position: relative;
    width: 46px;
    /* margin-left: 130px; */
    margin-right: 130px;
}

.customfield select {
    display: block;
    background-color: #161616;
    color: #fff;
    padding: 3px 10px;
    width: 180px;
    border-radius: 4px;
    border: none;
    margin-top: 4px;
    outline: 1px solid #1567d200;
}

.customfield select:focus {
    outline: 1px solid #1567d2;
}

.product-data-groups {
    width: 100%;
    /* outline: 1px solid #f00; */
    margin-bottom: 8px;
}

.product-data-groups {
    display: flex;
}

.product-data-group {
    padding: 4px 10px;
    background-color: #232323;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.product-data-group.selected {
    background-color: #024b02;
}

.admin-product-stock.stock-ready {
    margin-right: 20px;
}

.stock-ready-btns {
    position: absolute;
    /* outline: 1px solid #f00; */
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.stock-ready:hover .stock-ready-btns {
    opacity: 1;
    pointer-events: all;
}

.stock-ready-btns div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px !important;
    opacity: 0.4;
    transition: all 100ms;
}

.stock-ready-btns div:hover {
    opacity: 0.9;
}

.stock-ready-minus {
    background-color: rgb(127, 1, 1);
}

.stock-ready-plus {
    background-color: rgb(0, 131, 0);
}

.product-data-section.materials .admin-user-groups {
    /* outline: 1px solid #f00; */
}

.product-data-section.materials {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.product-data-section.materials .admin-product-tag {
    display: none;
}
