.admin-users-container {
    margin: 30px;
    margin-bottom: 100px;
}

.admin-users-filter {
    background-color: #242424;
    padding: 10px 16px;
    box-sizing: border-box;
    display: flex;
    width: 95%;
    max-width: 900px;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 200ms;
    position: relative;
    align-items: center;
    margin-bottom: 30px;
    font-weight: 500;
}

.users-filter-option {
    cursor: pointer;
    padding: 4px 8px;
    transition: all 200ms;
    border-radius: 4px;
}

.users-filter-option.selected {
    background-color: #1567d2;
    color: #fff;
}

.admin-user {
    background-color: #242424;
    padding: 10px 16px;
    box-sizing: border-box;
    display: flex;
    width: 95%;
    max-width: 900px;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 200ms;
    position: relative;
    align-items: center;
    cursor: pointer;
}

.admin-user:hover {
    background-color: #323232;
}

.admin-users-list table {
    border-collapse: collapse;
}

.admin-users-list {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.admin-user-btn {
    cursor: pointer;
    margin-left: 16px;
}

div.admin-user-btn:hover {
    font-weight: 600;
}

.admin-user-email {
    margin-right: auto;
    min-width: 270px;
}

.admin-user-groups {
    display: flex;
    width: 100%;
    min-width: 200px;
    /* margin-top: 10px; */
}

.admin-user-group {
    padding: 6px 10px;
    background-color: #000;
    outline: 1px solid #ffffff17;
    margin: 0px 10px 10px 0px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
}

.admin-user-group.selected {
    background-color: #026000;
}

.admin-user-new {
    position: absolute;
    top: -5px;
    left: -4px;
    background-color: #1567d2;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 11px;
    opacity: 0;
}

.admin-user-new.shown {
    opacity: 1;
}

.admin-user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;
    margin-right: 10px;
    text-align: left;
}

.admin-user-group-name {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    white-space: wrap;
    font-size: 11px;
    font-weight: 500;
    text-overflow: ellipsis;
}

.admin-order-search.admin-user-search .admin-order-search-input,
.admin-order-search.admin-user-search .admin-order-search-input input {
    max-width: 800px;
}

.admin-order-search.admin-user-search .admin-order-search-container {
    width: auto;
}

.whatsapp-input {
    display: block;
    background-color: #181818;
    border: none;
    color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    margin-top: 10px;
    min-height: 100px;
}

.whatsapp-input:focus {
    outline: 1px solid #1567d2;
}

.admin-whatsapp {
    width: 100%;
}

.admin-confirm-modal.admin-whatsapp-modal {
    width: 500px;
}

.admin-whatsapp-send {
    margin-top: 14px;
    background-color: #1567d2;
    padding: 2px 8px;
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;
}

.add-aistem-addlink {
    margin-top: 14px;
    background-color: #2d3136;
    padding: 2px 8px;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
}

.admin-aistem-link label {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    /* outline: 1px solid #0f0; */
    width: 100%;
}

.admin-aistem-link label:first-of-type {
    margin-right: 10px;
    width: 190px;
}

.admin-aistem-link input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: 1px solid #1567d200;
    background-color: #181818;
    padding: 2px 6px;
    border-radius: 4px;
    color: #fff;
    transition: all 200ms;
}

.admin-aistem-link input:focus {
    outline: 1px solid #1567d2;
}

.admin-aistem-link {
    display: flex;
    width: 100%;
    /* outline: 1px solid #f00; */
    margin-top: 10px;
    padding: 6px 10px 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #252525;
    align-items: center;
}

.admin-aistem-link .trash img {
    width: 18px;
    display: flex;
    flex-direction: column;
}

.admin-aistem-link .trash {
    margin: 4px 2px 0px 10px;
    cursor: pointer;
}

.admin-whatsapp-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    top: 0px;
    left: 0px;
}

.admin-whatsapp-loading .loader {
    position: relative;
}

.admin-whatsapp-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-user-form {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

.admin-user-form input {
    background-color: #161616;
    border: none;
    color: #fff;
    transition: all 200ms;
    outline: 1px solid #1567d200;
    padding: 4px 10px;
    /* width: 100%; */
    border-radius: 4px;
    /* width: fit-content; */
    margin-top: 4px;
    min-width: 230px;
}

.admin-user-form input:focus {
    outline: 1px solid #1567d2;
}

.admin-user-form label {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-right: 10px;
}

.admin-user-section {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.admin-user-title {
    width: 100%;
    margin-bottom: 6px;
}

.admin-user-credits,
.admin-user-signed {
    background-color: #000;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    align-items: center;
    margin-left: 10px;
    padding: 1px 6px 3px;
    min-width: 70px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

.admin-user-signed.late {
    background-color: rgb(168, 7, 7);
}

.admin-signed-date {
    font-size: 11px;
    font-weight: 500;
}

.admin-user-credits-value {
    font-size: 14px;
    line-height: 10px;
}

.admin-user-credits-value::before {
    content: "R$";
    font-size: 11px;
    margin-right: 3px;
}

.admin-user-credits::after {
    content: "Alterar";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    opacity: 0;
    transition: all 200ms;
}

.admin-user-credits:hover::after {
    opacity: 1;
}

.admin-add-credits-title {
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    text-align: center;
}

.admin-add-credits-email {
    background-color: #2d2d2d;
    padding: 3px 10px;
    margin-top: 4px;
    border-radius: 6px;
    font-size: 13px;
}

.admin-credits-modal label {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
}

.admin-credits-modal input {
    background-color: #161616;
    border: none;
    color: #fff;
    transition: all 200ms;
    outline: 1px solid #1567d200;
    padding: 4px 10px;
    /* width: 100%; */
    border-radius: 4px;
    /* width: fit-content; */
    margin-top: 4px;
    min-width: 230px;
}

.admin-credits-modal input:focus {
    outline: 1px solid #1567d2;
}

.admin-credits-confirm {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: 1px solid #f00;
    top: 0px;
    left: 0px;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.admin-credits-confirm.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-credits-confirm-buttons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 30px;
}

.admin-credits-button {
    background-color: rgb(192, 17, 17);
    padding: 2px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.admin-credits-button.yes {
    background-color: #026000;
}

.admin-credits-button:hover {
    opacity: 0.8;
}

.admin-whitelist {
    display: flex;
}

.admin-whitelist-product {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.admin-whitelist-product img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
}

.admin-whitelist-sku {
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    background-color: #242424;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 4px;
}

.admin-whitelist-product::after {
    content: "Remover";
    color: #f00;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 700;
    transition: all 200ms;
    opacity: 0;
    cursor: pointer;
}

.admin-whitelist-product:hover::after {
    opacity: 1;
}

.admin-ban-btn {
    position: absolute;
    right: 20px;
    top: 16px;
    color: rgb(242, 35, 35);
    font-weight: 500;
}

.admin-user-photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    margin-left: auto;
    object-fit: cover;
}

.user-phone {
    margin-right: 10px;
    font-size: 12px;
    min-width: 80px;
    max-width: 80px;
    white-space: nowrap;
}
