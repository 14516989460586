.admin-dashboard {
    margin: 50px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
}

.dashboard-header {
    font-weight: 500;
    width: 100%;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}

.admin-dashboard-value {
    display: flex;
    align-items: flex-end;
    margin-top: 5px;
}

.admin-dashboard-sign {
    margin-right: 6px;
    font-size: 16px;
}

.admin-dashboard-loading {
    background-color: #1a1a1a;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    /* outline: 1px solid #f00; */
    width: calc(100% - 180px);
    height: 100%;
    right: 0px;
    top: 0px;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    flex-direction: column;
    white-space: pre-wrap;
    text-align: center;
    font-size: 18px;
}

.admin-dashboard-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-dashboard-loading .loader {
    position: relative;
    margin-bottom: 20px;
}

.admin-dashboard-block {
    text-align: center;
    width: fit-content;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #0f0f0f;
    padding: 10px;
    border-radius: 10px;
    min-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 200ms;
    cursor: pointer;
}

.admin-dashboard-block.selected {
    background-color: #1567d2;
    cursor: default;
}

.admin-dashboard-block.selected .admin-dashboard-block-title {
    color: #fff;
    font-weight: 500;
}

.admin-dashboard-block:last-of-type {
    margin-right: 0px;
}

.admin-dashboard-info {
    display: flex;
    margin-top: 10px;
}

.admin-dashboard-days {
    display: flex;
    margin-top: 20px;
    width: 100%;
    /* outline: 1px solid #f00; */
}

.admin-dashboard-day {
    padding: 2px 10px;
    background-color: #0f0f0f;
    margin-right: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 200ms;
}

.admin-dashboard-day.selected {
    background-color: #1567d2;
}

.admin-dashboard-block-title {
    margin-bottom: auto;
    color: #1567d2;
}

.react-datepicker.admin-calendar {
    background-color: #1a1a1a;
    border-color: #0f0f0f;
    border-width: 3px;
}

.react-datepicker.admin-calendar .react-datepicker__day {
    color: #fff;
    transition: all 200ms;
}

.react-datepicker.admin-calendar .react-datepicker__day--in-range {
    background-color: #1567d2;
}

.react-datepicker.admin-calendar .react-datepicker__day--range-start {
    background-color: #073c81;
}

.react-datepicker.admin-calendar .react-datepicker__header {
    background-color: #0f0f0f;
    border-color: #0f0f0f;
}

.react-datepicker.admin-calendar .react-datepicker__current-month,
.react-datepicker.admin-calendar .react-datepicker__day-name {
    color: #fff;
}

.react-datepicker.admin-calendar .react-datepicker__day:hover {
    color: #000;
}

.online-users {
    margin-top: 20px;
    background-color: #0f0f0f;
    /* max-width: 670px; */
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    max-height: 200px;
    min-height: 200px;
    border-radius: 10px;
}

.online-users-title {
    text-align: center;
    margin-bottom: 10px;
}

.online-user,
.dashboard-detail-item {
    background-color: #0a0a0a;
    padding: 3px 10px;
    border-radius: 4px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #ffffffd6;
}

.online-user-list {
    max-height: 220px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.admin-dashboard-details-list {
    max-height: 280px;
    overflow-y: auto;
}

.admin-dashboard-details {
    margin-top: 20px;
    background-color: #0f0f0f;
    /* max-width: 670px; */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    max-height: 320px;
    min-height: 180px;
    border-radius: 10px;
}

.dashboard-detail-value {
    outline: 1px solid #ffffff4b;
    border-radius: 4px;
    min-width: 55px;
    margin-right: 10px;
    text-align: right;
    box-sizing: border-box;
    padding: 2px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    transition: all 200ms;
}

.dashboard-detail-value.money {
    min-width: 80px;
}

.dashboard-detail-value .rssign {
    font-size: 11px;
    margin-left: 4px;
}

.dashboard-user-colocation {
    width: 32px;
    text-align: center;
    margin-right: 10px;
}

.dashboard-detail-item:nth-of-type(1) .dashboard-user-colocation {
    color: #1567d2;
    font-weight: 600;
}

.dashboard-detail-item:nth-of-type(2) .dashboard-user-colocation {
    color: #7a9dca;
    font-weight: 600;
}

.dashboard-detail-item:nth-of-type(3) .dashboard-user-colocation {
    color: #a1681e;
    font-weight: 600;
}

.eye * {
    box-sizing: border-box;
}

.eye {
    --box-size: 34px;
    --border-width: calc(var(--box-size) / 12);
    --hair-length: calc(var(--border-width) * 2);
    --iris-scale: 80%;
    --pupil-scale: 35%;
    --tansition-time: 0.15s;

    width: var(--box-size);
    height: var(--box-size);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
    opacity: 0.3;
    transition: all 200ms;
    cursor: pointer;
}

.eye:hover {
    opacity: 0.8;
}

.eye__base {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: solid var(--border-width) #fff;
    border-radius: 80% 0;
    transform: rotate(45deg);
    overflow: hidden;
}

.eye__base__view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(15%, 20%);
    transition: transform calc(var(--tansition-time) * 1.5) ease-out;
}

.eye.open .eye__base__view {
    transform: translate(3%, 3%);
}

.eye__base__view__iris {
    width: var(--iris-scale);
    height: var(--iris-scale);
    border: solid var(--border-width) #fff;
    border-radius: 100%;
    transform: rotate(-45deg);
}

.eye__base__view__pupil {
    position: absolute;
    width: var(--pupil-scale);
    height: var(--pupil-scale);
    border: solid var(--border-width) #fff;
    border-radius: 100%;
    transform: rotate(-45deg);
}

.eye__lid {
    position: absolute;
    width: 100%;
    height: 100%;
}

.eye__lid__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1a1a1a;
    border-radius: 80% 0;
    transform: rotate(45deg);
    transition: all var(--tansition-time) ease-out;
}

.eye.open .eye__lid__mask {
    background: transparent;
    transform: rotate(45deg) translate(-20%, -20%);
}

.eye__lid__lashes {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform var(--tansition-time) ease-out;
}

.eye.open .eye__lid__lashes {
    transform: rotateX(180deg);
}

.eye__lid__lashes__line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: solid var(--border-width) #fff;
    border-right: solid var(--border-width) #fff;
    border-radius: 80% 0;
    transform: rotate(45deg);
}

.eye__lid__lashes__hair {
    position: absolute;
    left: 50%;
    top: 105%;
    transform: translate(-50%, -50%);
    width: var(--border-width);
    height: var(--hair-length);
    background: #fff;
}

.eye__lid__lashes__hair:before {
    content: "";
    position: absolute;
    left: calc(var(--box-size) * -0.4);
    top: calc(var(--box-size) * -0.07);
    width: var(--border-width);
    height: var(--hair-length);
    background: #fff;
    transform: rotate(20deg);
}

.eye__lid__lashes__hair:after {
    content: "";
    position: absolute;
    left: calc(var(--box-size) * 0.4);
    top: calc(var(--box-size) * -0.07);
    width: var(--border-width);
    height: var(--hair-length);
    background: #fff;
    transform: rotate(-20deg);
}

.admin-dashboard-email {
    transition: all 200ms;
}

.admin-dashboard.hide-info .admin-dashboard-email {
    filter: blur(4px);
    opacity: 0.7;
}

.online-user-name {
    max-width: 200px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    white-space: nowrap;
}

.hide-info-btn {
    position: absolute;
    right: 50px;
}
