.entrance-payment-btn {
    text-align: center;
    font-size: 15px;
    padding: 6px 20px;
    /* width: 150px; */
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #1567d2;
    font-weight: 600;
    border-style: none;
    margin-top: 10px;
    cursor: pointer;
    color: #fff;
    transition: all 200ms;
    width: 100%;
}

.entrance-payment-btn:hover {
    opacity: 0.8;
}

.entrance-payment {
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.entrance-payment-leave {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
}

.entrance-payment-leave:hover {
    font-weight: 500;
}

.block-warning {
    background-color: #ee2c2c;
    padding: 10px;
    border-radius: 6px;
    font-weight: 600;
    text-align: justify;
}

.ban-warning {
    text-align: justify;
}

.payment-warning {
    text-align: justify;
}

.payment-warning strong {
    white-space: nowrap;
}

.ban-warning strong {
    color: #ee2c2c;
}
