.roulette {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    transition: all 1000ms;
}

.roulette-container {
    display: inline-block;
    overflow: hidden;
}
