.integration-container {
    margin: 30px;
    padding: 20px;
    background-color: #171717;
    min-height: calc(100% - 60px);
    border-radius: 20px;
    box-sizing: border-box;
}

.integration-container-inside {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
}

.add-integration {
    background-color: #1567d2;
    width: fit-content;
    padding: 8px 12px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.integrations-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.integrations-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.integrations-add-modal {
    max-height: 90dvh;
    width: 300px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    transform: translateX(-1000px) translateY(-300px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #0c0c0c;
}

.integrations-confirm-modal,
.integrations-waiting-modal,
.integrations-bling-modal,
.integrations-add-marketplace {
    width: 320px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    /* transform: translateX(-1000px); */
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #0c0c0c;
}

.integrations-modal-bg.shown > div {
    scale: 1;
    opacity: 1;
    transform: translateX(0px) translateY(0px);
}

.integration-add-modal-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.integration-add-btn {
    background-color: #1567d2;
    padding: 10px;
    width: 100%;
    text-align: center;
    margin: 0px 20px 20px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    transition: all 200ms;
    display: block;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.integration-add-btn:not(.disabled):hover {
    opacity: 0.8;
}

.integration-add-btn:last-of-type {
    margin-bottom: 0px;
}

.integration-add-btn.disabled {
    background-color: #2a2a2a;
    color: #5a5a5a;
    pointer-events: none;
}

.integration {
    background-color: #0f0f0f;
    width: fit-content;
    margin-top: 20px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    position: relative;
    min-width: 200px;
    margin-right: 20px;
}

.integration-btn {
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 18px;
}

.integration-configuration,
.integration-remove,
.integration-pending,
.pendences-update {
    display: block;
    color: #fff;
    background-color: #1567d2;
    padding: 4px 10px;
    margin-top: 8px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 200ms;
}

.integration-configuration:hover,
.integration-remove:hover,
.integration-pending:hover,
.pendences-update:hover {
    opacity: 0.8;
}

.integration-remove,
.integration-pending {
    background-color: #c93838;
}

.integration-pendence {
    background-color: #c93838;
    margin-top: 10px;
    padding: 8px;
    border-radius: 8px;
    text-align: justify;
}

.integration-pendence.resolved {
    margin: 0;
    background-color: green;
}

.integration-pendence a {
    color: #fff;
    text-decoration: underline;
    font-weight: 500;
}

.pendences-update {
    margin-top: 12px;
    margin-left: auto;
}

.integration-pending {
    margin: 12px 0px;
}

.integration-pending-remove {
    color: #c93838;
    /* margin-top: 10px; */
    cursor: pointer;
}

.integration-pending-remove:hover {
    font-weight: 600;
}

.integration-detail {
    font-size: 12px;
    margin: 16px 0px;
}

.integration-detail span {
    font-weight: 600;
}

.integration-modal-confirm-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.integration-modal-confirm-btn {
    background-color: rgb(162, 0, 0);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 6px;
    transition: all 200ms;
    cursor: pointer;
}

.integration-modal-confirm-btn:hover {
    opacity: 0.8;
}

.integration-modal-confirm-btn.btn-yes {
    background-color: rgb(0, 143, 0);
}

.integrations-waiting-modal .loader {
    position: relative;
    margin: 14px 0px 24px;
    scale: 0.8;
    z-index: 0;
}

.integration-syncing {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    background-color: #0f0f0feb;
    font-size: 16px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 200ms, opacity 200ms 5000ms;
    pointer-events: none;
    opacity: 0;
    flex-direction: column;
}

.integration-syncing.shown {
    opacity: 1;
    pointer-events: all;
    transition: all 200ms;
    /* pointer-events: all; */
}

.integration-syncing .loader {
    position: relative;
    scale: 0.6;
    z-index: 0;
}

.integration-syncing .syncing-title {
    margin-top: 0px;
    font-size: 15px;
}

.integration-syncing .syncing-bar {
    width: 150px;
    height: 14px;
    outline: 1px solid #fff;
    margin-top: 5px;
    border-radius: 4px;
    display: flex;
}

.integration-syncing .syncing-bar-inner {
    border-radius: 4px;
    display: inline-block;
    min-width: 20px;
    height: 100%;
    background-color: #fff;
    transition: all 3000ms;
}

.integration-syncing .syncing-qtty {
    margin-top: 6px;
    font-size: 13px;
}

.integrations-bling-modal {
    width: 95%;
    max-width: 635px;
}

.btn-add-marketplace {
    background-color: #1567d2;
    padding: 4px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.btn-add-marketplace:hover {
    opacity: 0.7;
}

.integrations-marketplace-list {
    margin-right: 20px;
}

.integrations-add-marketplace {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.integration-marketplace-btn img {
    height: 34px;
}

.integration-marketplace-btn {
    width: fit-content;
    padding: 0px 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: #030303;
    cursor: pointer;
    transition: all 200ms;
    outline: 1px solid #1567d200;
}

.integration-marketplace-btn:last-of-type {
    margin-bottom: 0px;
}

.integration-marketplace-btn.selected {
    outline: 1px solid #1567d2;
    background-color: #021227;
}

.integration-marketplace-btn:hover:not(.selected) {
    background-color: #021227;
}

.integrations-marketplace-data {
    /* outline: 1px solid #c93838; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.integrations-marketplace-data input {
    padding: 4px 10px;
    border-radius: 6px;
    border: none;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    background-color: #292929;
    color: #fff;
    font-size: 14px;
    /* width: 124px; */
    width: 100%;
    box-sizing: border-box;
    margin: 5px 0px;
}

.integrations-marketplace-data input:focus {
    outline: 1px solid #1567d2;
}

.integrations-marketplace-data label {
    margin-bottom: 20px;
}

.codigo-bling {
    font-size: 12px;
    color: #1567d2;
    cursor: pointer;
}

.codigo-bling:hover {
    font-weight: 600;
}

.integration-marketplace-save {
    position: absolute;
    bottom: 25px;
    right: 25px;
    background-color: #1567d2;
    padding: 4px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 100ms;
}

.integration-marketplace-save:hover {
    opacity: 0.7;
}

.integration-marketplace {
    background-color: #1c1c1c;
    padding: 0px 18px 10px;
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 16px;
    min-width: 100px;
}

.integration-marketplace:last-of-type {
    margin-right: 0px;
}

.integration-marketplace img {
    height: 40px;
    display: flex;
}

.integration-marketplaces {
    width: 100%;
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;
    margin-bottom: -16px;
}

.integration-place-code {
    background-color: #292929;
    color: rgba(255, 255, 255, 0.8);
    /* outline: 1px solid #ffffff1d; */
    display: flex;
    flex-direction: column;
    padding: 2px 8px;
    font-size: 9px;
    margin-top: 6px;
    border-radius: 4px;
    line-height: 16px;
}

.integration-place-code span {
    font-size: 13px;
    font-weight: 500;
}

.integration-place-remove {
    color: #c93838;
    font-weight: 500;
    font-size: 12px;
    margin-top: 6px;
    cursor: pointer;
}

.integration-place-remove:hover {
    font-weight: 600;
}

.em-breve {
    /* position: absolute; */
    color: #fff;
    background-color: #1567d2;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    padding: 1px 8px;
    border-radius: 4px;
    font-size: 12px;
    width: fit-content;
    margin-top: 5px;
}

.instegrations-einstein {
    scale: 0.38;
    margin: -40px -26px -20px -40px;
}

.integration-marketplace-explanation {
    font-size: 15px;
    display: flex;
    margin-bottom: 14px;
    width: 100%;
}

.integration-saying {
    margin-top: 10px;
}

.integrations-add-marketplace-loading {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.integrations-add-marketplace-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.integrations-add-marketplace-loading .loader {
    position: relative;
    z-index: 0;
}

.meli-warning-title {
    font-size: 18px;
    color: rgb(240, 13, 13);
    font-weight: 600;
}

.meli-warning-text {
    text-align: justify;
    margin: 10px 0px 20px;
    line-height: 22px;
}

.meli-warning-text strong {
    color: rgb(240, 13, 13);
    font-weight: 600;
}

.btn-yes-meli {
    background-color: #1567d2;
}

@media only screen and (max-width: 768px) {
    .add-integration {
        margin: 0px;
    }

    .integration-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - 60px);
        /* justify-content: center; */
        /* flex-wrap: wrap; */
        min-height: fit-content;
    }

    .integration-container-inside {
        justify-content: center;
        min-height: fit-content;
    }

    .integration {
        margin-right: 0px;
    }
}
