.entrance-payment-btn {
    text-align: center;
    font-size: 15px;
    padding: 6px 20px;
    /* width: 150px; */
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #1567d2;
    font-weight: 600;
    border-style: none;
    margin-top: 10px;
    cursor: pointer;
    color: #fff;
    transition: all 200ms;
    margin-top: 15px;
    width: 100%;
}

.entrance-payment-btn:hover {
    opacity: 0.8;
}

.entrance-payment {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.entrance-payment-leave {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
}

.entrance-payment-leave:hover {
    font-weight: 500;
}
