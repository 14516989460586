.bling-error {
    color: #c52b2b;
    font-weight: 500;
    margin: 10px 0px;
}

.login-setbling.error {
    text-align: justify;
    padding: 18px;
}

.login-setbling.error a {
    color: #1567d2;
    font-weight: 600;
}

.login-setbling.error a:hover {
    font-weight: 700;
}
