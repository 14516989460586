.einstein {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    /* outline: 1px solid #f00; */

    justify-content: center;
}

.einstein-inner {
    pointer-events: none;
    transition: all 200ms;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding: 10px 0px;
    transition: all 300ms 100ms;
}

.einstein .ball {
    background-color: #fff;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    /* position: absolute; */
    /* top: -10px;
    left: 7px; */
    animation: einstein-ball 800ms ease-in-out infinite, einstein-show-ball 200ms ease-in-out 1 forwards;
    margin-right: 20px;
    opacity: 0;
}

.einstein .ball:nth-of-type(2) {
    animation-delay: 100ms;
}

.einstein .ball:nth-of-type(3) {
    animation-delay: 200ms;
}

.einstein .ball:nth-of-type(4) {
    margin-right: 0px;
    animation-delay: 300ms;
}

@keyframes einstein-ball {
    0%,
    100% {
        transform: translateY(-3px);
    }
    50% {
        transform: translateY(3px);
    }
}

@keyframes einstein-show-ball {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.einstein.already-started .ball {
    /* animation: none !important; */
}

.einstein.already-started .ball:nth-of-type(2),
.einstein.already-started .ball:nth-of-type(3) {
    animation: einstein-ball-idle 800ms 0ms linear infinite !important;
}

.einstein.already-started .ball:nth-of-type(1),
.einstein.already-started .ball:nth-of-type(4) {
    transition: all 0ms !important;
    opacity: 0 !important;
}

.einstein.already-started .mouth-outer {
    opacity: 1 !important;
    transition: all 0ms !important;
    max-width: 80px !important;
    animation: einstein-mouth-idle 800ms 0ms ease-in-out infinite !important;
}

.einstein.already-started .mouth {
    min-width: 52px !important;
    transition: all 0ms !important;
    animation: einstein-mouth-inner 800ms 0ms ease-in-out infinite !important;
}

.einstein.already-started .einstein-inner {
    scale: 1.4 !important;
}

.einstein.already-started .tongue {
    opacity: 1 !important;
    bottom: 4px !important;
    transition: all 0ms !important;
}

.einstein.logo .ball:nth-of-type(2),
.einstein.logo .ball:nth-of-type(3) {
    animation: einstein-ball-2 300ms linear 1 forwards, einstein-ball-idle 800ms 400ms linear infinite;
}

.einstein.logo .ball:nth-of-type(3) {
    animation-delay: 80ms;
    opacity: 1;
}

.einstein.logo .ball:nth-of-type(1) {
    animation: einstein-ball-3 200ms 100ms ease-in-out 1 forwards;
    opacity: 1;
}
.einstein.logo .ball:nth-of-type(4) {
    animation: einstein-ball-4 200ms 100ms ease-in-out 1 forwards;
    opacity: 1;
}

.einstein.logo .einstein-inner {
    scale: 1.4;
}

@keyframes einstein-ball-2 {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    70% {
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(-18px);
    }
}

@keyframes einstein-ball-3 {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    80% {
        transform: translateX(18px);
    }
    100% {
        transform: translateX(14px);
        opacity: 0;
    }
}

@keyframes einstein-ball-4 {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    80% {
        transform: translateX(-18px);
    }
    100% {
        transform: translateX(-14px);
        opacity: 0;
    }
}

.einstein .mouth {
    display: block;
    min-width: 44px;
    min-height: 28px;
    border-radius: 50%;
    border: 20px solid white;
    transition: all 200ms;
}

.einstein .mouth-outer {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 60px;
    max-height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 200ms;
    /* outline: 1px solid #f00; */
    /* transform-origin: 0%; */
    /* animation: orizom-loading-mouth 900ms linear infinite; */
}

.einstein.logo .mouth-outer {
    opacity: 1;
    transition: all 200ms 300ms;
    max-width: 80px;
    animation: einstein-mouth-idle 800ms 300ms ease-in-out infinite;
}

.einstein.logo .mouth {
    min-width: 52px;
    transition: all 200ms 300ms;
    animation: einstein-mouth-inner 800ms 300ms ease-in-out infinite;
}

@keyframes einstein-ball-idle {
    0%,
    100% {
        opacity: 1;
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-18px);
    }
}

@keyframes einstein-mouth-idle {
    0%,
    100% {
        transform: translateX(-50%) translateY(1px);
    }
    50% {
        transform: translateX(-50%) translateY(0px);
    }
}

@keyframes einstein-mouth-inner {
    0%,
    100% {
        transform: translateY(1px);
    }
    50% {
        transform: translateY(0px);
    }
}

.einstein .tongue {
    position: absolute;
    outline: 4px solid #1a1a1a;
    background-color: #fff;

    left: 50%;
    width: 16px;
    height: 24px;
    transform: translateX(-50%);
    bottom: 12px;
    border-radius: 0px 0px 8px 8px;
    animation: einstein-mouth-idle 1000ms 300ms ease-in-out infinite;
    opacity: 0;
    transition: all 200ms;
}

.einstein .tongue::after {
    content: "";
    position: absolute;
    background-color: #1a1a1a;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 4px;
    height: 12px;
    border-radius: 0px 0px 2px 2px;
}

.einstein.logo .tongue {
    opacity: 1;
    bottom: 4px;
    transition: all 300ms 300ms;
}

.einstein .einstein-text {
    font-family: Arial;
    letter-spacing: 5px;
    font-size: 80px;
    margin-left: 0px;
    margin-top: 26px;
    font-weight: 600;
    max-width: 0px;
    transition: all 800ms;
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
}

.einstein.text .einstein-text {
    max-width: 500px;
    opacity: 1;
    margin-left: 30px;
}

.einstein img {
    display: block;
    height: 90px;
    margin-top: 10px;
    margin-left: -120px;
}

.einstein .logo {
    max-width: 0px;
    overflow: hidden;
    transition: all 600ms, margin 200ms 500ms, scale 400ms 800ms;
    display: flex;
    justify-content: flex-start;
}

.einstein.final .einstein-inner {
    opacity: 0;
    transition: all 200ms 600ms;
}

.einstein.final .logo {
    max-width: 700px;
    scale: 0.85;
    margin-left: -120px;
    /* outline: 1px solid #0f0; */
}

.einstein.final img {
    margin-left: 0px;
    transition: margin 200ms 500ms;
}
