.product-list {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    position: relative;
    margin-bottom: 100px;
    transition: all 400ms;
    justify-content: flex-start;
    /* border: 1px solid #f00; */
}

.product-card {
    background-color: #292929;
    width: 200px;
    height: 240px;
    border-radius: 10px;
    padding: 6px;
    color: #fff;
    position: relative;
    transition: all 400ms;
    transform: translateX(50px);
    opacity: 0;
    animation: product-card 300ms ease-in-out 10ms 1 normal forwards;
}

@keyframes product-card {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    50% {
        opacity: 0.5;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.product-card-container {
    display: inline-block;
    position: relative;
    height: min-content;
    width: min-content;
    margin: 8px;
    transition: all 400ms;
}

.product-card .product-photo {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    transition: transform 200ms, border 100ms, box-shadow 400ms, filter 200ms;
    box-sizing: border-box;
    z-index: 10;
    transform: translateX(0px);
    cursor: pointer;
    background-color: #fff;
}

.product-card .product-photo2 {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    transition: transform 200ms, border 100ms, box-shadow 400ms, scale 400ms, filter 200ms;
    box-sizing: border-box;
    z-index: 10;
    transform: translateX(0px);
    pointer-events: none;
    /* transform: rotateY(180deg); */
}

.product-card .product-price {
    float: right;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-right: 4px;
}

.product-card .product-price .product-price-value {
    font-size: 22px;
}

.product-card .product-sku,
.product-card .product-line {
    position: absolute;
    background-color: #151515;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    padding: 2px 4px;
    border-radius: 6px;
    border: 1px solid #ffffff57;
    cursor: pointer;
    transition: background-color 100ms, color 400ms, transform 200ms;
}

.product-card .product-line {
    background-color: #1567d2;
    opacity: 0;
    pointer-events: none;
}

.product-card .product-line.shown {
    opacity: 1;
}

.product-card .product-sku:hover,
.product-card .product-line:hover {
    background-color: #666666;
    color: #fff;
}

.product-card .product-sku {
    top: 10px;
    left: 10px;
    z-index: 11;
}

.product-card .product-sku::after {
    font-size: 9px;
}

.product-card .product-line {
    top: 10px;
    right: 10px;
}

.product-card-container.selected .product-card {
    background-color: #1567d2;
}

.product-card-container.selected:hover .product-card .product-photo,
.product-card-container.selected:hover .product-card .product-sku,
.product-card-container.selected:hover .product-card .product-line {
    transform: translateX(74px);
}

.product-card-container.selected:hover .product-card .product-photo {
    /* border: 2px inset #fff; */
    box-shadow: 5px 0px 8px 5px rgb(0, 0, 0, 0.3);
    /* filter: blur(1px); */
}

.product-card-container.selected:hover .product-card .product-photo2 {
    scale: 1.05;
    transform: translateX(55px) translateY(14px) rotateZ(-10deg);
    /* filter: drop-shadow(5px 8px 3px rgba(0,0,0,0.4)); */
    z-index: 12;
}

.product-card-container.selected:hover .product-card .product-photo2 {
    /* box-shadow: 5px 0px 8px 5px rgb(0, 0, 0, 0.3); */
}

.product-select-btn {
    opacity: 0;
    display: inline-block;
    background-color: #1567d2;
    padding: 4px 8px;
    border-radius: 6px;
    margin-top: 8px;
    font-size: 14px;
    transition: opacity 200ms, background-color 100ms, color 400ms;
    cursor: pointer;
    font-weight: 600;
    pointer-events: none;
}

.product-card-container.selected .product-select-btn {
    background-color: #fff;
    color: #000;
    opacity: 1;
}

.product-card-container:hover .product-select-btn {
    opacity: 1;
    pointer-events: all;
}

.product-select-btn:hover {
    background-color: #fff;
    color: #000;
}

.product-buttons {
    opacity: 0;
    position: absolute;
    top: 32px;
    left: 6px;
    height: 160px;
    /* image-rendering:pixelated; */
    z-index: 0;
    cursor: pointer;
    transition: opacity 10ms;
}

.product-card-container.selected .product-buttons {
    opacity: 1;
}

.product-center {
    width: calc(100% - 200px);
    display: flex;
    justify-content: center;
    left: 200px;
    position: absolute;
    transition: all 300ms;
}

.product-qtty-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    margin: 5px 0px;
    /* margin-left: 120px */
    /* background-color: #000; */
    animation: qtty-container 500ms linear 1;
    animation-fill-mode: forwards;
}

@keyframes qtty-container {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.product-qtty-container .trash {
    cursor: pointer;
}

.product-qtty-container .trash img {
    width: 14px;
    display: block;
    transition: all 200ms;
    transform-origin: bottom left;
}

.product-qtty-container .trash:hover img:first-of-type {
    transform: rotateZ(-20deg);
}

.product-qtty-container .trash img:first-of-type {
    margin-bottom: 1px;
}

.product-qtty {
    display: inline-block;
    width: 40px;
    border-radius: 6px;
    border: none;
    margin: 0px 6px;
    font-size: 16px;
    color: #000;
    text-align: center;
    font-weight: 500;
    padding: 1px 0px;
    margin-top: 2px;
    background-color: #fff;
}

.product-plus {
    height: 15px;
    cursor: pointer;
    /* background-color: #000; */
    margin-top: 2px;
    animation: rotateplus 500ms linear 1;
    animation-fill-mode: forwards;
    transform: rotateZ(90deg);
    /* transform-origin: center left; */
}

@keyframes rotateplus {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(90deg);
    }
}

.product-suggested-price {
    background-color: #fff;
    position: absolute;
    top: 100%;
    border-radius: 8px;
    right: 0px;
    width: 140px;
    /* height: 35px; */
    box-shadow: 1px 1px 5px 3px #00000031;
    opacity: 1;
    transition: all 200ms;
    color: #000;
    display: flex;
    justify-content: space-evenly;
    padding: 3px 6px 3px 0px;
    opacity: 0;
    /* pointer-events: none; */
    visibility: hidden;
}

.product-suggested-price .config {
    background-color: #292929;
    border: 2px solid #1b1b1b;
    height: 14px;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateX(5px) translateY(-50%);
    transition: all 500ms;
    cursor: pointer;
}

.product-price:hover ~ .product-select-btn {
    opacity: 0 !important;
}

.product-price:hover .config {
    transform: translateX(0) translateY(-50%) rotateZ(180deg);
}

.config:hover {
    transform: translateX(0) translateY(-50%) rotateZ(270deg) !important;
}

.product-price:hover .product-suggested-price {
    opacity: 1;
    visibility: visible;
}

.product-suggested-price:after {
    content: "";
    position: absolute;
    top: 0;
    left: 85%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -7px;
    margin-top: -7px;
}

.product-suggested-price .suggested-price img {
    height: 18px;
}

.product-suggested-price .suggested-price small {
    font-size: 8px;
}

.product-suggested-price .suggested-price {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-suggested-price * {
    color: #000;
}

.box-popup {
    width: 200px;
    height: 200px;
    background-color: #000;
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 6px;
    transition: transform 200ms, opacity 500ms 200ms;
    opacity: 0;
    color: #000;
    padding: 12px;
    /* padding-right: 60px; */
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    /* text-align: justify; */
    /* overflow: hidden; */
    /* box-shadow:0 0 0 2000px rgba(0,0,0,0.85); */
    z-index: 2;
    pointer-events: none;
}

.box-popup-text {
    color: #fff;
    transition: all 200ms 500ms;
    transform: translate(-30px);
    text-align: center;
}

.option:hover + .box-popup .box-popup-text {
    transform: translate(0px);
    transition: all 300ms 200ms;
}

.product-card-back .box-popup img {
    display: block;
    height: 110px;
    width: auto;
    transition: all 300ms 600ms;
    position: absolute;
    bottom: -5px;
    right: -30px;
    transform: rotateZ(50deg);
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
}

.option.gift + .box-popup img {
    height: 120px;
}

.option.gift:hover + .box-popup img {
    bottom: -8px;
}

.option.gift + .box-popup {
    overflow: hidden;
}

.option:hover + .box-popup img {
    bottom: -10px;
    right: 50%;
    transform: translateX(50%) rotateZ(0deg);
    transition: all 300ms 200ms;
}

.option:hover + .box-popup.burn-popup img {
    height: 130px;
    right: 0px;
    bottom: 0px;
    transform: translateX(0) rotateZ(0deg);
}

.option:hover + .box-popup {
    opacity: 1;
    pointer-events: all;
}

.product-card-container.selected:hover .product-card .box-popup {
    transform: translateX(74px);
}

.product-card-back {
    position: absolute;
    margin: 2px;
    padding: 4px;
    height: 195px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #1259b6;
    border-radius: 8px;
    transition: all 200ms 100ms;
    z-index: 0;
}

.product-card-back:hover {
    z-index: 100;
    transition: all 0ms 0ms;
}

.product-card:not(:hover) .product-card-back {
    transition: opacity 100ms, z-index 0ms 0ms !important;
    z-index: 0 !important;
    opacity: 0;
}

.product-card-back img {
    width: 38px;
    z-index: 0;
    transition: all 200ms;
}

.product-card-back .option:hover img,
.product-card-back .option.selected img {
    width: 30px;
}

.product-card-back img.img-custom,
.product-card-back img.img-custom {
    margin: auto;
}

.product-card-back .option:hover img.img-custom,
.product-card-back .option.selected img.img-custom {
    width: 28px;
}

.product-card-back .box-price {
    position: absolute;
    bottom: 0px;
    font-weight: 600;
    opacity: 0;
    transition: all 200ms;
    scale: 0;
}

.product-card-back .option:hover .box-price,
.product-card-back .option.selected .box-price {
    opacity: 1;
    scale: 1;
}

.product-card-back .box-price small {
    font-size: 10px;
}

.product-card-back .option {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 8px;
    /* border: 1px solid #fff; */
    width: 42px;
    height: 42px;
    /* background-color: #000; */
    margin: 5px 2px;
    padding: 5px;
    cursor: pointer;
    transition: all 200ms;
    position: relative;
    z-index: 0;
}

.product-card-back .option:hover {
    background-color: #104b98;
    z-index: 99;
}

.product-card-back .option.selected {
    background-color: #1567d2;
    animation: card-back-select 100ms 1;
    /* outline: 1px solid #ffffff74; */
}

@keyframes card-back-select {
    0% {
        scale: 1;
    }
    70% {
        scale: 1.1;
    }
    100% {
        scale: 1;
    }
}

.product-minus {
    font-size: 35px;
    font-weight: 300;
    line-height: 20px;
    margin-top: -5px;
    margin-right: 2px;
    cursor: pointer;
}

.option .option-config {
    display: flex;
    position: absolute;
    height: 16px;
    width: 16px !important;
    top: -4px;
    right: -4px;
    opacity: 0;
    transition: all 300ms;
    /* background-color: #000; */
    pointer-events: none;
}

.option.selected .option-config.show {
    opacity: 1;
    transform: rotateZ(180deg);
    pointer-events: all;
}

.option.selected .option-config:hover {
    transform: rotateZ(90deg);
    scale: 1.2;
}

img.img-custom {
    width: 34px;
}

.product-center .loader {
    position: absolute;
    left: 50%;
    top: 35vh;
    opacity: 0.7;
}

.product-photo-water {
    position: absolute;
    left: 7px;
    bottom: calc(100% - 206px);
    max-height: 50px;
    mix-blend-mode: darken;
    filter: saturate(2);
    pointer-events: none;
    transition: all 200ms, opacity 600ms, max-height 600ms !important;
    object-fit: fill;
    opacity: 0;
    box-shadow: none !important;
}

.product-card:hover .product-photo-water {
    max-height: 180px;
    opacity: 0.8;
    transition: all 200ms, max-height 600ms, opacity 200ms !important;
}

@media screen and (max-width: 505px) {
    .product-photo-water {
        position: absolute;
        left: 6px;
        bottom: calc(100% - 148px);
    }

    .product-card:hover .product-photo-water {
        max-height: 150px;
    }

    .product-card {
        width: 140px;
        height: 170px;
    }

    .box-popup,
    .product-card .product-photo2,
    .product-card .product-photo {
        width: 140px;
        height: 140px;
    }
    .product-select-btn {
        font-size: 12px;
        opacity: 1;
        padding: 2px 5px;
        margin-top: 4px;
        border-radius: 4px;
    }

    .product-card .product-price {
        font-size: 12px;
        margin-top: 2px;
    }

    .product-card .product-price .product-price-value {
        font-size: 18px;
    }

    .product-card .product-sku,
    .product-card .product-line {
        font-size: 9px;
        border-radius: 4px;
    }

    .box-popup {
        font-size: 8.5px;
    }

    .option:hover + .box-popup.burn-popup img {
        height: 80px;
    }

    .option.gift + .box-popup img {
        height: 80px;
    }

    .product-card-back .box-popup img {
        height: 66px;
    }

    .product-card-back {
        height: 140px;
        width: 50px;
    }

    .product-card-back img {
        width: 26px;
        z-index: 0;
        transition: all 200ms;
    }

    .product-card-back .option:hover img,
    .product-card-back .option.selected img {
        width: 20px;
    }

    .product-card-back .option {
        width: 28px;
        height: 28px;
        font-size: 12px;
        margin-top: 2px;
    }

    .product-card-container.selected:hover {
        z-index: 9999999 !important;
    }

    .product-card-container.selected:hover .product-card .product-photo,
    .product-card-container.selected:hover .product-card .product-sku,
    .product-card-container.selected:hover .product-card .product-line {
        transform: translateX(60px);
    }

    .product-card-container.selected:hover:nth-of-type(even) .product-card .product-photo,
    .product-card-container.selected:hover:nth-of-type(even) .product-card .product-sku,
    .product-card-container.selected:hover:nth-of-type(even) .product-card .product-line {
        transform: translateX(-60px);
    }

    .product-card-container.selected:hover .product-card .box-popup {
        transform: translateX(60px);
    }

    .product-card-container.selected:hover:nth-of-type(even) .product-card .box-popup {
        transform: translateX(-148px);
    }

    .product-card-container.selected:hover:nth-of-type(even) .product-card-back {
        margin-left: 90px;
    }

    .product-qtty-container {
        margin: 0px;
        padding: 2px;
    }

    .product-qtty {
        width: 32px;
        font-size: 14px;
        border-radius: 6px;
    }

    .product-list {
        padding: 10px;
    }
}
